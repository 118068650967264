import React, { useState } from "react";
import AppLayout from "./AppLayout";
import axios from "axios";
import "./css/styles4.css";
const Feedbackform = ({ business }) => {
  const [formData, setFormData] = useState({
    name: "",
    feedback: "",
    business_id: business.id,
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://admin.bizzata.in/api/business_feedback",
        formData
      );
      alert(response.data.message);
    } catch (error) {
      console.error("There was an error!", error);
    }
  };
  return (
    <AppLayout>
      <div className="  pt-[15px] ">
        <h2 className="text-[48px] font-bold text-[#9F7161] text-center mb-5 odor-mean-chey-regular">
          Feedback form
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="p-4 mx-auto sm:mx-20 w-[80%] bg-[#B89C85] rounded-xl ">
            <p className="text-white nunito-sans-bold">Name</p>
            <input
              type="text"
              placeholder="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              className=" w-[100%] rounded-md h-8 py-1 px-2 mt-1"
            />
            <p className="text-white mt-5 nunito-sans-bold">Feedback</p>
            <input
              type="text"
              name="feedback"
              value={formData.feedback}
              onChange={handleChange}
              required
              placeholder="Type your message..."
              className=" w-[100%]   rounded-md h-14 lg:h-24 px-2 mt-1 "
            />
            <br />
            <button
              type="submit"
              className="bg-[#D4B294] sm:w-[30%] nunito-sans-bold  text-white rounded-md py-2 px-3 mt-4 font-medium"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </AppLayout>
  );
};

export default Feedbackform;
//
