import React, { useState } from "react";
import axios from "axios";
import AppLayout from "./AppLayout";

import "./CssTemp/styles.css";

function ContactUs({ business }) {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    feedback: "",
    mobile: "",
    business_id: business.id,
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleClearForm = () => {
    setFormData({
      name: "",
      email: "",
      feedback: "",
      mobile: "",
      business_id: business.id,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://admin.bizzata.in/api/business_feedback",
        formData
      );
      alert(response.data.message);
      handleClearForm();
    } catch (error) {
      console.error("There was an error!", error);
      alert("There was an error submitting your feedback. Please try again.");
    }
  };
  return (
    <AppLayout>
      <section id="contact">
        <div className="bg-[#000] text-[#36C478] h-auto mb-5 rounded-md">
          <div className="text-center font-semibold leading-5 mx-auto pt-10">
            <div className="italic  text-[32px] poppins-bold">Enquiry</div>
          </div>

          <div className="mt-14">
            <form onSubmit={handleSubmit}>
              <AppLayout>
                <div className="mb-4 bg-[#151D09] w-[95%] mx-auto h-auto my-15 rounded-2xl sm:mx-5 sm:px-5">
                  <div className="py-2 ">
                    <label
                      htmlFor="name"
                      className="block poppins-bold  font-semibold text-[#36C478]  mx-10  lg:text-2xl text-lg"
                    >
                      Name:
                      <span className="text-red-500 text-2xl">&nbsp;*</span>
                    </label>
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                      placeholder="Type your Name :-"
                      className="mt-2 border-none rounded-md focus:outline-none focus:border-black-500 mx-10 bg-[#151D09] text-[#36C478]"
                    />
                    <hr className="lg:w-[400px]   mb-2 mx-10 h-[1px] lg:h-[2px] bg-[#D9D9D9]" />
                  </div>
                </div>
                <div className="mb-4 w-[95%] mx-auto bg-[#151D09] h-auto my-15 rounded-2xl sm:mx-5 sm:px-5">
                  <div className="py-2 ">
                    <label
                      htmlFor="Email"
                      className="block poppins-bold font-semibold text-[#36C478]  mx-10  lg:text-2xl text-lg"
                    >
                      Email:
                      <span className="text-red-500 text-2xl">&nbsp;*</span>
                    </label>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                      placeholder="Type your Email :-"
                      className=" mt-2 border-none rounded-md focus:outline-none focus:border-black-500 mx-10 bg-[#151D09] text-[#36C478]"
                    />
                    <hr className="lg:w-[400px]   mb-2 mx-10 h-[1px] lg:h-[2px] bg-[#D9D9D9]" />
                  </div>
                </div>
                <div className="mb-4 w-[95%] mx-auto bg-[#151D09] h-auto my-15 rounded-2xl sm:mx-5 sm:px-5">
                  <div className="py-2 ">
                    <label
                      htmlFor="MobilNo"
                      className="block  poppins-bold font-semibold text-[#36C478]  mx-10  lg:text-2xl text-lg"
                    >
                      Mobile:
                      <span className="text-red-500 text-2xl">&nbsp;*</span>
                    </label>
                    <input
                      type="tel"
                      id="mobile"
                      name="mobile"
                      value={formData.mobile}
                      onChange={handleChange}
                      className="  mt-2 border-none rounded-md focus:outline-none focus:border-black-500 mx-10 bg-[#151D09] text-[#36C478]"
                      placeholder="Enter your Mobile No."
                      required
                    />
                    <hr className="lg:w-[400px]   mb-2 mx-10 h-[1px] lg:h-[2px] bg-[#D9D9D9]" />
                  </div>
                </div>
                <div className="mb-4 w-[95%] mx-auto bg-[#151D09] h-auto my-15 rounded-2xl sm:mx-5 sm:px-5">
                  <div className="py-2 ">
                    <label
                      htmlFor="MobilNo"
                      className="block  poppins-bold font-semibold text-[#36C478]  mx-10  lg:text-2xl text-lg"
                    >
                      Message:
                      <span className="text-red-500 text-2xl">&nbsp;*</span>
                    </label>
                    <textarea
                      value={formData.feedback}
                      onChange={handleChange}
                      name="feedback"
                      required
                      placeholder="Any Message for Us :-"
                      className="  mt-2 border-none bg-[#151D09] rounded-md focus:outline-none focus:border-black-500 mx-10 text-[#36C478]"
                    >
                      {formData.feedback}
                    </textarea>
                    <hr className="lg:w-[400px]  mb-2 mx-10 h-[1px] lg:h-[2px] bg-[#D9D9D9]" />
                  </div>
                </div>
                <div className="flex items-center justify-center lg:justify-start lg:ml-[27px] mt-4">
                  <input
                    type="checkbox"
                    id="terms"
                    name="terms"
                    className="mr-2 w-[20px] mb-2 p-[5px] h-[20px] "
                  />
                  <label
                    htmlFor="terms"
                    className="text-[20px] mb-[8px] text-[#36C478] lg:text-[30px] lg:py-6  sm:py-4 sm:mx-4"
                  >
                    I agree to the{" "}
                    <a
                      href="/terms"
                      className="underline lg:w-[30px] lg:h-[30px]"
                    >
                      terms and conditions
                    </a>
                    .
                  </label>
                </div>
              </AppLayout>
              <div className=" bg-white">
                <AppLayout>
                  <div className="grid grid-cols-2 gap-2 sm:gap-1">
                    <div className=" p-4 rounded-md">
                      <div className="sm:ml-10">
                        <button
                          type="button"
                          onClick={handleSubmit}
                          className="bg-[#38CB89] text-white px-4 py-2 poppins-bold rounded-md hover:bg-[#38CB89] focus:outline-none focus:shadow-outline-blue w-28 h-10"
                          //   onClick={handleButtonClick} // Replace with your click handler function
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                    <div className=" p-4 rounded-md justify-items-end lg:ml-72 sm:ml-10">
                      <div className=" lg:mx-20 sm:mx-5">
                        <button
                          type="button"
                          onClick={handleClearForm}
                          className="  font-bold  px-4 py-2 rounded-md bg-red-500 text-white poppins-bold hover:bg-red-700 poppins-bold hover:text-white focus:outline-none focus:shadow-outline-blue"
                          //   onClick={handleButtonClick} // Replace with your click handler function
                        >
                          Clear form
                        </button>
                      </div>
                    </div>
                  </div>
                </AppLayout>
              </div>
            </form>
          </div>
        </div>
      </section>
    </AppLayout>
  );
}

export default ContactUs;
