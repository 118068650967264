import React, { useState } from "react";
import bgimg from "./assites/img_rectangle_313.png";
import AppLayout from "./AppLayout";
import axios from "axios";
const Business = ({ business }) => {
  function convertTo12HourFormat(time) {
    let [hours, minutes] = time.split(":");
    hours = parseInt(hours, 10);
    let period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert '0' hours to '12'
    return `${hours}:${minutes} ${period}`;
  }
  const [feedbackFormData, setFeedbackFormData] = useState({
    name: "",
    feedback: "",
    business_id: business.id,
  });
  const handleFeedbackChange = (e) => {
    setFeedbackFormData({
      ...feedbackFormData,
      [e.target.name]: e.target.value,
    });
  };
  const handleFeedbackSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://admin.bizzata.in/api/business_feedback",
        feedbackFormData
      );
      alert(response.data.message);
      setFeedbackFormData({
        name: "",
        feedback: "",
        business_id: business.id,
      });
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  return (
    <div className="my-10">
      <AppLayout>
        <div className="flex flex-col lg:flex-row justify-center items-center lg:items-start my-4 mx-4 lg:mx-[10%]">
          <div className="lg:mt-[50px] w-full lg:w-1/2">
            <h2 className="text-[30px] sm:text-[25px] font-bold text-[#326C77] text-center odor-mean-chey-regular">
              BUSINESS HOURS
            </h2>
            <div className="bg-[#B8DBE2]  text-white p-6 rounded-lg shadow-lg w-full sm:w-[400px] sm:h-[100%] gap-2 mx-auto">
              <table className="w-full">
                <tbody>
                  {(business.business_hours || []).map((dayHour, index) => (
                    <tr
                      key={index}
                      className="border-b border-[#fff] last:border-none"
                    >
                      <td className="py-1 sm:pb-2 text-[#326C77]">
                        {dayHour.day}
                      </td>
                      <td className="py-1 sm:pb-2 text-[#326C77]">
                        {convertTo12HourFormat(dayHour.open_time)}
                      </td>
                      <td className="py-1 sm:pb-2 text-[#326C77]">
                        {convertTo12HourFormat(dayHour.close_time)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="w-[350px] sm:w-[500px] lg:w-1/2 h-[400px]">
            <div className="  pt-[15px]  ">
              <h2 className="text-[40px] sm:text-[25px] font-bold text-[#326C77] text-center odor-mean-chey-regular ">
                Feedback form
              </h2>
              <form onSubmit={handleFeedbackSubmit}>
                <div className="p-4 mx-auto sm:mx-20 w-[100%] bg-[#B8DBE2]  text-white rounded-xl">
                  <p className="text-[#326C77]">Name</p>
                  <input
                    type="text"
                    placeholder="Name"
                    className="w-[100%] rounded-md h-8 py-1 px-2 mt-1 bg-white text-black"
                    value={feedbackFormData.name}
                    name="name"
                    onChange={handleFeedbackChange}
                    required
                  />
                  <p className="text-[#326C77] mt-5">Feedback</p>
                  <input
                    type="text"
                    placeholder="Type your message..."
                    className="w-[100%] rounded-md h-14 lg:h-24 px-2 mt-1 bg-white text-black"
                    value={feedbackFormData.feedback}
                    name="feedback"
                    onChange={handleFeedbackChange}
                    required
                  />
                  <br />
                  <div className="mx-[25%]">
                    <button
                      type="submit"
                      className="bg-[#326C77] text-[#FFF] sm:w-[60%] odor-mean rounded-md py-2 px-3 mt-4 font-medium"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </AppLayout>
    </div>
  );
};

export default Business;
