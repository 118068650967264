import React from "react";
import Home from "./Home";
import Navbar from "./Navbar";
import Footer from "./Footer";
import CompanyInfoBox from "./CompanyInfoBox";
import ServiceBox from "./ServiceBox";
import ProductCard from "./ProductCard";
import Blogs from "./Blogs";
import Gallery from "./Gallery";
import BussinessHours from "./BussinessHours";
import EnquiryForm from "./EnquiryForm";
import Appoinment from "./Appoinment";
import Service from "./Service";
import Feedbackform from "./Feedbackform";
import Review from "./Review";
import Certificates from "./Certificates";
import Offer from "./Offer";
import { Helmet } from "react-helmet";

import { BusinessProvider } from "../../context/BusinessContext";

function Fredany({ business }) {
  if (!business) {
    return <div>Loading...</div>;
  }
  return (
    <BusinessProvider business={business}>
      <Helmet>
        <title>{business.meta_title}</title>
        <meta name="robots" content="index, follow"></meta>
        <link rel="icon" href="./logo.ico" />
        <meta name="description" content={business.meta_keyword} />
        <meta name="keywords" content={business.meta_description} />
      </Helmet>
      <Navbar business={business} />
      <Home business={business} />
      <CompanyInfoBox business={business} />
      <ServiceBox business={business} />
      <ProductCard business={business} />
      <Service business={business} />
      <Offer business={business} />
      <Blogs business={business} />

      <Gallery business={business} />
      <BussinessHours business={business} />
      <Certificates business={business} />

      <Feedbackform business={business} />
      <Review business={business} />

      <Appoinment business={business} />
      <EnquiryForm business={business} />
      <Footer business={business} />
    </BusinessProvider>
  );
}

export default Fredany;
