import React from 'react';

const TruncateString = ({ inputString, maxLength }) => {
  const truncateString = (str, len) => {
    if (!str) return str;
    if (str.length <= len) {
      return str;
    } else {
      return str.substring(0, len) + '...';
    }
  };

  return <span>{truncateString(inputString, maxLength)}</span>;
};

export default TruncateString;
