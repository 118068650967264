import React from "react";
import AppLayout from "./AppLayout";
import "./style3.css";
const Hero = ({ business }) => {
  return (
    <AppLayout>
      <div className="h-full my-[5%] bg-[#920C26] rounded-xl drop-shadow-xl">
         <h1 className="hidden">{business.meta_title}</h1>
        <div class="grid  sm:grid-cols-3  gap-2   " id="about">
          <div class=" p-1 ">
            <div className="flex justify-center items-center sm:mt-[8%]">
              <img
                src={`https://admin.bizzata.in/storage/${business.profile}`}
                className="rounded-full h-[120px] w-[120px] sm:h-52 sm:w-52 object-cover"
                alt="Description of image"
              />
            </div>
          </div>

          <div class="  sm:col-span-2 p-4  sm:mt-[30px] lg:ml-[-100px] text-[#FFF]">
            <h2 className=" font-bold text-[24px] sm:text-[38px] text-center  sm:text-start pb-2 md:ml-6 ">
              {business.business_name}
            </h2>
            <p className=" font-semibold text-[22px] text-center sm:text-start pb-2 sm:text-[30px]  md:ml-6">
              {business.name}
            </p>
            <p className="h-auto text-start md:ml-4 sm:text-[20px] text-[16px] font-normal piazzolla-uniquifier">
              " {business.description}"
            </p>
          </div>
        </div>

        <div className="mx-[2%]  mt-[2%] sm:mt-[10%]  lg:mt-[3%] rounded-full flex flex-wrap px-4 py-4 gap-4 justify-center pb-4">
          {(business.business_social_media_links || []).map(
            (linkData, index) => (
              <a
                key={index}
                href={linkData.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={`https://admin.bizzata.in/storage/${linkData.social_media_platform.logo}`}
                  alt={`${linkData.social_media_platform.platform_name} logo`}
                  className="h-[34px] w-[34px]"
                />
              </a>
            )
          )}
        </div>
      </div>
    </AppLayout>
  );
};

export default Hero;
