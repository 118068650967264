import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay } from "swiper/modules";
import AppLayout from "./AppLayout";
import { Link, useParams } from "react-router-dom";
import createSlug from "../../Components/createSlug";
import TruncateString from "../../Components/TruncateString ";
const Bloge = ({ business }) => {
  const { city, slug } = useParams();
  const citySlug = createSlug(city);

  return (
    <div className="">
      <AppLayout>
        <h2
          className="  text-[30px] text-center text-white piazzolla-uniquifier font-semibold"
          id="blogs"
        >
          Blogs
        </h2>
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 7500,
            disableOnInteraction: false,
          }}
          breakpoints={{
            340: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            500: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            900: {
              slidesPerView: 3,
              spaceBetween: 15,
            },
            
          }}
          Pagination={true}
          // navigation={true}
          modules={[Autoplay, Pagination]}
          className="mySwiper"
        >
          {(business.blogs || []).map((blog, index) => (
            <SwiperSlide key={index}>
              <div
                className={`w-[90%] sm:w-[325px]h-[100%] ] sm:h-[350px] flex-col mb-10 pb-16 mx-auto justify-between rounded-md overflow-hidden ${
                  index % 2 === 0 ? "sm:mt-0" : " sm:mt-[50px]"
                }`}
              >
                <div className="relative">
                  <Link to={`/${citySlug}/${slug}/blogpg3/${blog.id}`}>
                    <img
                      src={`https://admin.bizzata.in/storage/${blog.image}`}
                      alt={blog.title}
                      className="h-[190px] w-full cursor-pointer object-cover object-fit border-b-2 border-black bg-[#F2F2F2] rounded-2xl"
                    />
                  </Link>
                </div>

                <h2 className="font-bold text-[20px] text-white text-center px-[2%] cursor-pointer mt-4 piazzolla-uniquifier">
                  <Link to={`/${citySlug}/${slug}/blogpg3/${blog.id}`}>
                    <TruncateString inputString={blog.title} maxLength={20} />
                  </Link>
                </h2>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </AppLayout>
    </div>
  );
};

export default Bloge;
