import React from "react";
import AppLayout from "./AppLayout";
import home from "./assites/Location.png";
import mal from "./assites/Email.png";
import cal from "./assites/Phone.png";
import ctg from "./assites/categoryicon5.png";

import "./style1.css";
const ContactUs = ({ business }) => {
  return (
    <AppLayout>
      <div className="  mt-20 mb-20 " id="aboutUs">
        <h1 className="text-center font-semibold  text-[30px] text-[#FAFF03] mt-4 mb-4 philosopher-regular">
          CONTACT US
        </h1>
        <div className="flex flex-col items-center mt-10">
          <div className="border-2 sm:border-4 border-zinc-800 rounded-[10px]  bg-gradient-to-r from-[#AFB131] w-8/12 sm:w-8/12">
            <div className="py-4 px-3 sm:py-1 ml-2 mt-0 sm:mt-2 lg:ml-4 gap-4">
              <ul className="flex gap-4 justify-start">
                <img
                  src={home}
                  alt=""
                  className="w-[30px] h-[35px]  lg:h-[50px] lg:w-[50px] sm:mt-2 mt-1"
                />

                <div className="flex-col">
                  <h1 className="text-[18px] lg:text-[32px] md:text-[28px] font-semibold text-white .philosopher-regular">
                    VISIT US :
                  </h1>
                  <p className="text-[12px]  lg:text-[28px] leading-7 md:text-[28px] text-white .philosopher-regular">
                    {business.address}
                  </p>
                </div>
              </ul>
            </div>
          </div>
          <div className="border-1 sm:border-4 border-zinc-800 rounded-[10px]  bg-gradient-to-r from-[#AFB131] w-8/12 sm:w-8/12 ml-30 sm:ml-48 mb-10 mt-10 text-end">
            <div className="py-4 px-3 sm:py-1 ml-2 mt-0 sm:mt-2 lg:ml-4 gap-4">
              <ul className="flex gap-4 justify-end">
                <div className="flex-col">
                  <h1 className="text-[18px] lg:text-[32px] md:text-[28px] font-semibold text-white .philosopher-regular">
                    Contact :
                  </h1>
                  <p className="text-[12px] lg:text-[32px] md:text-[28px]  text-white .philosopher-regular">
                    Call: {business.mobile}
                  </p>
                </div>
                <img
                  src={cal}
                  alt="alter"
                  className="w-[30px] h-[35px]  lg:h-[50px] lg:w-[50px] sm:mt-2 mt-1"
                />
              </ul>
            </div>
          </div>
          <div className="border-1 sm:border-4 border-zinc-800 rounded-[10px]  bg-gradient-to-r from-[#AFB131] w-8/12 sm:w-8/12">
            <div className="py-4 px-3 sm:py-1 ml-2 mt-0 sm:mt-2 lg:ml-4 gap-4">
              <ul className="flex gap-4 justify-start">
                <img
                  src={mal}
                  alt=""
                  className="w-[30px] h-[35px]  lg:h-[50px] lg:w-[50px] sm:mt-2 mt-1"
                />

                <div className="flex-col">
                  <h1 className="text-[18px] lg:text-[32px] md:text-[28px] font-semibold text-white .philosopher-regular">
                    Mail :
                  </h1>
                  <p className="text-[12px] lg:text-[32px] md:text-[28px]  text-white .philosopher-regular">
                    {business.email}
                  </p>
                </div>
              </ul>
            </div>
          </div>
          <div className="border-1 sm:border-4 border-zinc-800 rounded-[10px]  bg-gradient-to-r from-[#AFB131] w-8/12 sm:w-8/12 ml-30 sm:ml-48 mb-10 mt-10 text-end">
            <div className="py-4 px-3 sm:py-1 ml-2 mt-0 sm:mt-2 lg:ml-4 gap-4">
              <ul className="flex gap-4 justify-end">
                <div className="flex-col">
                  <h1 className="text-[18px] lg:text-[32px] md:text-[28px] font-semibold text-white .philosopher-regular">
                    Category :
                  </h1>
                  <p className="text-[12px] lg:text-[32px] md:text-[28px]  text-white .philosopher-regular">
                    <a href="">
                      {" "}
                      {(business.categories || []).map(
                        (category, index) => category.category_name + ", "
                      )}
                    </a>
                  </p>
                </div>
                <img
                  src={ctg}
                  alt="alter"
                  className="w-[30px] h-[35px]  lg:h-[50px] lg:w-[50px] sm:mt-2 mt-1"
                />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
};
export default ContactUs;
