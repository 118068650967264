import React from "react";
// import Card from "./Card";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "./TempCss/Style.css";
import { Pagination } from "swiper/modules";

import { Link, useParams } from "react-router-dom";
import AppLayout from "./AppLayou";
import createSlug from "../../Components/createSlug";
import TruncateString from "../../Components/TruncateString ";

const Service = ({ business }) => {
  const { city, slug } = useParams();
  const citySlug = createSlug(city);
  return (
    <div className=" w-full bg-[#000]" id="service">
      <AppLayout>
        <div className="ml-[10%]">
          <h2 className="font-[500] text-[20px] pt-4 abhaya-libre-semibold lg:text-[48px] text-[rgb(255,255,255)] ">
            Our Service
          </h2>
        </div>
        <div className=" py-8 hidden sm:block">
          <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {(business.services || []).map((service, index) => (
              <div
                key={index}
                className="bg-white text-center py-4 px-6  abhaya-libre-semibold rounded-full shadow-md text-gray-800 font-semibold"
              >
                <Link to={`/${citySlug}/${slug}/servicepage9/${service.id}`}>
                  <TruncateString
                    inputString={service.service_name}
                    maxLength={40}
                  />
                </Link>
              </div>
            ))}
          </div>
        </div>
        <div className="sm:hidden block">
          <Swiper
            breakpoints={{
              340: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              540: {
                slidesPerView: 2,
                spaceBetween: 5,
              },
              700: {
                slidesPerView: 2,
                spaceBetween: 15,
              },
              900: {
                slidesPerView: 3,
                spaceBetween: 5,
              },
              1000: {
                slidesPerView: 4,
                spaceBetween: 5,
              },
            }}
            modules={[Pagination]}
            Pagination={true}
            className="mySwiper"
          >
            <div className="flex flex-wrap gap-4 justify-center pb-4">
              {(business.services || []).map((service, index) => (
                <SwiperSlide key={index} className="flex justify-center">
                  <div
                    key={index}
                    className="bg-white w-[90%] text-center py-4 px-4  abhaya-libre-semibold rounded-full shadow-md text-gray-800 font-semibold h-[60px]"
                  >
                    <Link
                      to={`/${citySlug}/${slug}/servicepage9/${service.id}`}
                    >
                      <TruncateString
                        inputString={service.service_name}
                        maxLength={30}
                      />
                    </Link>
                  </div>
                </SwiperSlide>
              ))}
            </div>
          </Swiper>
        </div>
      </AppLayout>
    </div>
  );
};
export default Service;
