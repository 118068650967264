import React from "react";
import AppLayout from "./AppLayout";
import "./CSSTemp6/styles.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation } from "swiper/modules";
import createSlug from "../../Components/createSlug";
import { useParams, Link } from "react-router-dom";
import TruncateString from "../../Components/TruncateString ";

const services = [
  {
    name: "Nordic Chair",
    image: "path-to-image-1.jpg",
  },
  {
    name: "Kurozo Aeo Chair",
    image: "path-to-image-2.jpg",
  },
  {
    name: "Ergonomic Chair",
    image: "path-to-image-3.jpg",
  },
  {
    name: "Kurozo Aeo Chair",
    image: "path-to-image-2.jpg",
  },
  {
    name: "Ergonomic Chair",
    image: "path-to-image-3.jpg",
  },
];

const Services = ({ business }) => {
  const { city, slug } = useParams();
  const citySlug = createSlug(city);
  return (
    <AppLayout>
      <div className="container mx-auto p-4">
        <div className="text-center mb-8">
          <h2 className="text-[40px] font-bold text-[#326C77] text-center odor-mean-chey-regular mb-3">
            Services
          </h2>
          {/* <p className="text-xl mt-2">Crafted with excellent material</p>
        <p className="text-md mt-1">Aenean vitea maximum nunc. Fusce rutrum, quam eu fringilla sodales, tellus felis blandit metus, sed pharetra</p>
        <button className="mt-4 px-6 py-2 bg-teal-600 text-white rounded-full">Explore</button> */}
        </div>
        <Swiper
          breakpoints={{
            340: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            540: {
              slidesPerView: 2,
              spaceBetween: 5,
            },
            700: {
              slidesPerView: 2,
              spaceBetween: 15,
            },
            900: {
              slidesPerView: 5,
              spaceBetween: 5,
            },
          }}
          loop={true}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          className="mySwiper"
        >
          {(business.services || []).map((service, index) => (
            <SwiperSlide key={index}>
              <div className="mx-2 h-[250px]">
                <div className="rounded-[12px] shadow-lg overflow-hidden p-4 h-[200px]">
                  <Link to={`/${citySlug}/${slug}/servicepg6/${service.id}`}>
                    <img
                      src={"https://admin.bizzata.in/storage/" + service.image}
                      alt={service.service_name}
                      className="w-[90%] h-[150px] object-cover reflect"
                    />
                  </Link>
                </div>
                <div className="p-4">
                  <h3 className="text-[16px] font-bold text-center text-[#326C77] nunito-sans-uniquifier">
                    <Link to={`/${citySlug}/${slug}/servicepg6/${service.id}`}>
                      <TruncateString
                        inputString={service.service_name}
                        maxLength={14}
                      />
                    </Link>
                  </h3>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </AppLayout>
  );
};

export default Services;
