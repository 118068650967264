import React from "react";

import AppLayout from "./AppLayout";

const Hader = ({ business }) => {
  return (
    <header>
      <div className=" " id="home">
        <img
          src={`https://admin.bizzata.in/storage/${business.banner}`}
          alt=""
          srcset=""
          className="logine"
        />
      </div>
      <AppLayout>
        <div className="flex md:gap-5 justify-between flex-wrap-reverse p-2 md:p-0">
          <div className="md:w-[700px] w-full md:mt-[150px] mt-4">
            <h2 className="font-semibold poppins-medium text-[16px] sm:text-[18px] text-[#224F34] mb-4">
              “ {business.description} “
            </h2>
            <button className="border enriqueta-sembold border-black text-black py-2 px-4 font-semibold rounded hover:bg-[#546D62] hover:text-white transition duration-300">
              <a href="#contact">Let's Talk</a>
            </button>
          </div>

          <div className="flex flex-col items-center w-full md:w-auto relative mt-6 lg:mt-16 mx-auto">
            <div className="outline outline-4 p-2 text-[#328B56]">
              <img
                src={`https://admin.bizzata.in/storage/${business.profile}`}
                alt="beautique"
                className="h-[180px] w-[150px] border-2 border-[#328B56]"
              />
            </div>
            <div className="mt-[20px]  mb-4">
              <h1 className="my-2 text-center text-[24px] font-bold text-black enriqueta-semibold">
                {business.business_name}
              </h1>
              <h2 className="text-[20px] text-center enriqueta-sembold font-bold text-black pb-2">
                {business.name}
              </h2>
            </div>
          </div>
        </div>

        <div className=" rounded-full flex flex-wrap px-4 py-4 gap-4 justify-center  mt-2">
          {/* <div className="flex gap-2"> */}
          {(business.business_social_media_links || []).map(
            (linkData, index) => (
              <a
                key={index}
                href={linkData.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={`https://admin.bizzata.in/storage/${linkData.social_media_platform.logo}`}
                  alt={`${linkData.social_media_platform.platform_name} logo`}
                  className="h-[34px] w-[34px] "
                />
              </a>
            )
          )}
        </div>
      </AppLayout>
    </header>
  );
};

export default Hader;
