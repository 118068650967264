import React from "react";
import Home from "./Home";
import Hero from "./Hero";
import Product from "./Product";
import Galleary from "./Gallery";
import Certificates from "./Certificates";
import Offer from "./Offer";
import Bloge from "./Bloge";
import Service from "./Service";
import ContactUs from "./ContactUs";
import Appoinment from "./Appoinment";
import Review from "./Review";
import Feedbackform from "./Feedbackform";
import BussinessHours from "./BussinessHours";
import { BusinessProvider } from "../../context/BusinessContext";
import { Helmet } from "react-helmet";
import Navbar from "./Navbar";
import Footer from "./Footer";
const Tamp7 = ({ business }) => {
  if (!business) {
    return <div>Loading...</div>;
  } // Added missing semicolon here

  return (
    <BusinessProvider business={business}>
      <Helmet>
        <title>{business.meta_title}</title>
           <meta name="robots" content="index, follow"></meta>
           <link rel="icon" href="./logo.ico" />
        <meta name="description" content={business.meta_description} />{" "}
        {/* Corrected */}
        <meta name="keywords" content={business.meta_keyword} />{" "}
     
      </Helmet>
      <div className="bg-[#282019]">
        <Navbar business={business} />
        <Home business={business} />
        <Hero business={business} />
        <ContactUs business={business} />
        <Product business={business} />
        <Service business={business} />
        <Bloge business={business} />
        <Galleary business={business} />
        <Offer business={business} />
        <BussinessHours business={business} />
        <Certificates business={business} />
        <Feedbackform business={business} />
        <Review business={business} />

        <Appoinment business={business} />

        <Footer business={business} />
      </div>
    </BusinessProvider>
  );
};

export default Tamp7;
