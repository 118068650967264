// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./TempCss/styles8.css";
// import required modules
import { Autoplay, FreeMode, Navigation, Pagination } from "swiper/modules";
import AppLayout from "./AppLayout";
import createSlug from "../../Components/createSlug";
import { useParams, Link } from "react-router-dom";

const Product = ({ business }) => {
  const { city, slug } = useParams();
  const citySlug = createSlug(city);
  return (
    <AppLayout>
      <div className="" id="product">
        <div className="mt-10  " id="services">
          <h1 className="text-center enriqueta-semibold font-bold text-[40px]">
            Our Products
          </h1>
          <Swiper
            breakpoints={{
              340: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              700: {
                slidesPerView: 3,
                spaceBetween: 15,
              },
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            freeMode={true}
            pagination={{
              clickable: true,
            }}
            modules={[FreeMode, Autoplay, Pagination, Navigation]}
            className="max-w-[90%] lg:max-w-[80%]"
          >
            <div>
              {(business.products || []).map((product, index) => (
                <SwiperSlide key={index}>
                  <div className="flex justify-center items-center my-4 pb-6">
                    <div className="relative h-[270px] sm:h-[300px] lg:h-[350px] w-full max-w-[320px] sm:max-w-[480px] lg:max-w-[600px] mx-2 object-cover">
                      <Link to={`/${city}/${slug}/productpg8/${product.id}`}>
                        <img
                          src={`https://admin.bizzata.in/storage/${product.image}`}
                          alt={product.product_name}
                          className="h-[90%] w-full object-cover rounded-lg"
                        />
                      </Link>
                      <Link to={`/${city}/${slug}/productpg8/${product.id}`}>
                        <button className="absolute enriqueta-semibold top-[260px] sm:top-[280px] lg:top-[350px] left-1/2 transform -translate-x-1/2 bg-[#6BC785] hover:bg-[#6BC785] text-white font-bold py-2 px-4 rounded h-[40px] sm:h-[40px] lg:h-[48px] w-[150px] sm:w-[150px] lg:w-[200px]">
                          Shop now
                        </button>
                      </Link>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </div>
          </Swiper>
        </div>
      </div>
    </AppLayout>
  );
};

export default Product;
