import React from "react";
import AppLayout from "./AppLayout";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation } from "swiper/modules";
import { Link, useParams } from "react-router-dom";
// import required modules
import TruncateString from "../../Components/TruncateString ";
import createSlug from "../../Components/createSlug";
import "./CSSTemp6/styles.css";
const Blogs = ({ business }) => {
  const { city, slug } = useParams();
  const citySlug = createSlug(city);
  return (
    <div className="" id="blogs">
      <AppLayout>
        <div className="sm:my-10 my-5 ">
          <h2 className="text-[40px] font-bold text-[#326C77] text-center odor-mean-chey-regular mb-1">
            Blogs
          </h2>
          <Swiper
            breakpoints={{
              340: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
              650: {
                slidesPerView: 2,
                spaceBetween: 15,
              },
              850: {
                slidesPerView: 3,
                spaceBetween: 15,
              },
            }}
            loop={true}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Pagination, Navigation]}
            className="mySwiper"
          >
            {(business.blogs || []).map((blog, index) => (
              <SwiperSlide key={index}>
                <div className="w-[95%] sm:w-[300px] shadow-xs h-[450px] lg:gap-1 mx-auto bg-[#72909E] rounded-[40px] flex flex-col items-center p-2 mb-4 overflow-hidden">
                  <div className="w-full">
                    <Link to={`/${citySlug}/${slug}/blogpg6/${blog.id}`}>
                      <img
                        src={`https://admin.bizzata.in/storage/${blog.image}`}
                        alt={blog.title}
                        className="h-[200px] w-full rounded-tr-[40px] rounded-tl-[40px] object-cover border-2 border-[#FFF]"
                      />
                    </Link>
                  </div>
                  <h1 className="pt-1 inline-flex text-xl sm:text-2xl text-white font-bold odor-mean-chey-regular text-start hover:underline">
                    <Link to={`/${citySlug}/${slug}/blogpgs6/${blog.id}`}>
                      <TruncateString inputString={blog.title} maxLength={30} />
                    </Link>
                  </h1>
                  <h2 className="p-1 text-base text-start font-bold text-[#325d65] odor-mean">
                    <Link to={`/${citySlug}/${slug}/blogpgs6/${blog.id}`}>
                      <TruncateString
                        inputString={blog.content}
                        maxLength={160}
                      />
                    </Link>
                  </h2>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </AppLayout>
    </div>
  );
};

export default Blogs;
