import React from "react";
import "./CssFold/style5.css";
function Footer({ business }) {
  const year = new Date().getFullYear();
  return (
    <div className="w-full h-[100%]  sm:h-[430px] lg:h-[410px]  bg-[#000] text-[#FFF] flex flex-col ">
      <div class="grid grid-cols-1 sm:grid-cols-2  gap-4">
        <div className="lg:mb-0 mt-10 lg:mt-10 mx-5 sm:mx-5 lg:ml-[25%] ">
          <p className="mb-6 text-xl font-bold ">Contaact Details :-</p>
          <ul className="flex flex-col space-y-2   ">
            <p className=" font-bold text-xl">Address</p>
            <li className=" hover:text-black text-lg cursor-pointer roboto">
              {business.address}
            </li>
            <p className=" font-bold text-xl">phone</p>
            <li className="hover:text-black cursor-pointer text-lg roboto">
              +91{business.mobile}
            </li>
            <p className=" font-bold text-xl">Email.</p>
            <li className=" hover:text-black text-lg cursor-pointer roboto">
              {business.email}
            </li>
          </ul>
        </div>

        <div className=" mb-2 sm:mb-8 lg:mb-0  lg:mt-10 mx-5 sm:mx-0 lg:ml-[10%]">
          <p className="mb-4 text-[20px] font-bold  pt-12 ">Like & Follow</p>
          <div className=" rounded-full flex flex-wrap px-4 py-4 gap-4 justify-center  sm:ml-[-50%] lg:ml-[-73%] ">
            {(business.business_social_media_links || []).map(
              (linkData, index) => (
                <div className="">
                  <a
                    key={index}
                    href={linkData.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={`https://admin.bizzata.in/storage/${linkData.social_media_platform.logo}`}
                      alt={`${linkData.social_media_platform.platform_name} logo`}
                      className="h-[34px] w-[34px] "
                    />
                  </a>
                </div>
              )
            )}
          </div>
          <ul>
            <li className="text-2xl font-bold">PAY NOW</li>
            <li>
              <img
                src={`https://admin.bizzata.in/storage/${business.qr_code}`}
                alt="Loding..."
                className="m-2 h-[120px] w-[#130px] pb-5 cursor-pointer"
              />
            </li>
          </ul>
        </div>
      </div>
      <hr className="my-1 bg-black text-black" />
      <div className="mx-auto max-w-6xl items-center justify-between px-4 md:flex lg:px-0 mb-1">
        <div className="mt-4 md:mt-0">
          <p className="text-sm font-medium ">
            © {year} {business.business_name}. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
