import { Swiper, SwiperSlide } from "swiper/react";
import { Link, useParams } from "react-router-dom";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "./TempCss/styles8.css";
// import required modules
import { Autoplay, FreeMode, Navigation, Pagination } from "swiper/modules";
import AppLayout from "./AppLayout";
import createSlug from "../../Components/createSlug";
import TruncateString from "../../Components/TruncateString ";

const Blogs = ({ business }) => {
  const { city, slug } = useParams();
  const citySlug = createSlug(city);
  return (
    <div className="mt-10" id="blogs">
      <AppLayout>
        <h1 className="text-center enriqueta-semibold font-bold text-[40px] mb-6">
          Our Blogs
        </h1>

        <Swiper
          breakpoints={{
            340: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            700: {
              slidesPerView: 3,
              spaceBetween: 15,
            },
          }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          freeMode={true}
          pagination={{
            clickable: true,
          }}
          modules={[FreeMode, Autoplay, Pagination, Navigation]}
          className="max-w-[90%] lg:max-w-[80%]"
        >
          <div>
            {(business.blogs || []).map((blog, index) => (
              <SwiperSlide key={index}>
                <div className="w-[95%] p-1  h-[460px]   rounded-md border bg-[#224f34] gap-4 mx-auto overflow-hidden">
                  <Link to={`/${citySlug}/${slug}/blogpg8/${blog.id}`}>
                    <img
                      src={`https://admin.bizzata.in/storage/${blog.image}`}
                      alt="images"
                      className="h-[200px] w-full rounded-t-md object-cover"
                    />
                  </Link>
                  <div className=" p-1 flex flex-col items-center">
                    <h1 className="mt-2 enriqueta-semibold text-xl sm:text-xl lg:text-2xl font-bold text-center text-white hover:underline">
                      <Link to={`/${citySlug}/${slug}/blogpg8/${blog.id}`}>
                        <TruncateString
                          inputString={blog.title}
                          maxLength={15}
                        />
                      </Link>
                    </h1>
                    <p className="mt-2 sm:mt-2 enriqueta-semibold lg:mt-5 text-[15px]  text-[#759181] text-start">
                      <TruncateString
                        inputString={blog.content}
                        maxLength={200}
                      />
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </div>
        </Swiper>
      </AppLayout>
    </div>
  );
};

export default Blogs;
