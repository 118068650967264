import React from "react";
import AppLayout from "./AppLayout";
import "./CssFold/style5.css";
const Hero = ({ business }) => {
  return (
    <AppLayout>
      <div className="mx-auto">
        <h1 className="hidden">{business.meta_title}</h1>
        <div className="mx-[33%] sm:mx-[37%] lg:mx-[42%] pt-4 ">
          <img
            src={`https://admin.bizzata.in/storage/${business.profile}`}
            className="rounded-full h-[120px] w-[120px] sm:h-48 sm:w-48 object-cover mt-[1%]"
            alt="Description of image"
          />
        </div>
        <div className="">
          <h2 className=" my-4 text-center text-[30px] font-semibold text-white roboto-serif-unique-class-name">
            {business.business_name}
          </h2>
          <p className="text-center text-[22px] font-semibold text-[#AA6141] pb-2">
            {" "}
            {business.name}
          </p>
          <p className=" text-start sm:text-center text-[12px] sm:text-[16px] font-medium text-white pb-10 mx-2  .roboto-serif-unique ">
            {business.description}
          </p>
        </div>
        <p className="text-center text-[22px] sm:text-[32px]  text-white roboto-serif-unique-class-name">
          You can also search us on
        </p>
      </div>
      <div className="flex items-center justify-between">
        <div className="flex-1">
          <hr className="border-t border-white " />
        </div>
        <div className="mx-[2%] mt-[2%] sm:mt-[10%] lg:mt-[3%] rounded-full flex flex-wrap px-4 py-4 gap-4 justify-center ">
          {(business.business_social_media_links || []).map(
            (linkData, index) => (
              <a
                key={index}
                href={linkData.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={`https://admin.bizzata.in/storage/${linkData.social_media_platform.logo}`}
                  alt={`${linkData.social_media_platform.platform_name} logo`}
                  className="h-[34px] w-[34px]"
                />
              </a>
            )
          )}
        </div>
        <div className="flex-1">
          <hr className="border-t border-white" />
        </div>
      </div>
    </AppLayout>
  );
};

export default Hero;
