import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "./style1.css";
import "swiper/css";
import "swiper/css/navigation";

import { Navigation } from "swiper/modules";
import AppLayout from "./AppLayout";
import TruncateString from "../../Components/TruncateString ";
const Review = ({ business }) => {
  return (
    <div className="py-10">
      <AppLayout>
        <h2 className="text-center text-[30px] font-bold text-[#FAFF03] philosopher-regular ">
          Our Clients Says
        </h2>
        <div>
          {" "}
          <Swiper
            breakpoints={{
              340: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
              540: {
                slidesPerView: 2,
                spaceBetween: 5,
              },
              750: {
                slidesPerView: 2,
                spaceBetween: 15,
              },
              1000: {
                slidesPerView: 3,
                spaceBetween: 15,
              },
            }}
            modules={[Navigation]}
            navigation
            className="mySwiper"
          >
            {(business.testimonials || []).map((testimonial, index) => (
              <SwiperSlide key={index}>
                <div className="max-w-md w-[95%] mx-auto bg-[#F1F1F1] text-black rounded-lg overflow-hidden shadow-lg ">
                  <div className="flex items-center  p-2 gap-10  h-[70px]">
                    <img
                      className="w-12 h-12 rounded-full mr-4 bg-black border-2 border-yellow-500"
                      src="https://beauty-parlour-three.vercel.app/static/media/lady.3b99d4cf6d66b78659b7.png"
                      alt="Loding"
                    />
                    <div>
                      <p className="text-black font-bold">
                        {" "}
                        - {testimonial.name}
                      </p>
                    </div>
                  </div>
                  <div className="p-2  md:pt-1">
                    <p className="text-black philosopher-regular text-[14px]">
                      "
                      <TruncateString
                        inputString={testimonial.content}
                        maxLength={100}
                      />
                      "
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </AppLayout>
    </div>
  );
};

export default Review;
