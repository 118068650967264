import React, { useState } from "react";
import AppLayout from "./AppLayout";
import axios from "axios";
 import "./style3.css"
const EnqForm = ({ business }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    feedback: "",
    business_id: business.id,
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://admin.bizzata.in/api/business_feedback",
        formData
      );
      alert(response.data.message);
    } catch (error) {
      console.error("There was an error!", error);
    }
    setFormData({
      name: "",
      email: "",
      mobile: "",
      feedback: "",
      business_id: business.id,
    });
  };

  return (
    <AppLayout>
      <section id="contact">
        <div className="text-[#FFF] h-auto mb-5 rounded-md mx-[2%] sm:mx-[20%]">
          <div className="text-start font-semibold leading-5 mx-auto pt-10">
            <div className="italic  text-[30px]  text-center piazzolla-uniquifier font-semibold">Enquiry</div>
          </div>

          <div className="mt-14 ">
            <form onSubmit={handleSubmit}>
              <AppLayout>
                <div className="mb-4 bg-[#DD9Ab3] w-[95%] mx-auto h-auto my-15 rounded-2xl sm:mx-5 sm:px-5">
                  <div className="py-2 ">
                    <label
                      htmlFor="name"
                      className="block  font-normal text-[#000]  mx-10  text-[30px] roboto-serif-header"
                    >
                      Name:
                      <span className="text-red-500 text-2xl">&nbsp;*</span>
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      className="mt-2 border-none rounded-md focus:outline-none focus:border-black-500 mx-10 bg-[#DD9Ab3] text-[#FFF]"
                      placeholder="Enter your Name"
                      required
                    />
                    <hr className="lg:w-[400px]   mb-2 mx-10 h-[1px] lg:h-[2px] bg-[#D9D9D9]" />
                  </div>
                </div>
                <div className="mb-4 w-[95%] mx-auto bg-[#DD9Ab3] h-auto my-15 rounded-2xl sm:mx-5 sm:px-5">
                  <div className="py-2 ">
                    <label
                      htmlFor="email"
                      className="block  font-normal text-[#000]  mx-10  text-[30px] roboto-serif-header"
                    >
                      Email:
                      <span className="text-red-500 text-2xl">&nbsp;*</span>
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      className=" mt-2 border-none rounded-md focus:outline-none focus:border-black-500 mx-10 bg-[#DD9Ab3] text-[#F0F0F0]"
                      placeholder="Enter your Email"
                      required
                    />
                    <hr className="lg:w-[400px]   mb-2 mx-10 h-[1px] lg:h-[2px] bg-[#D9D9D9]" />
                  </div>
                </div>
                <div className="mb-4 w-[95%] mx-auto bg-[#DD9Ab3] h-auto my-15 rounded-2xl sm:mx-5 sm:px-5">
                  <div className="py-2 ">
                    <label
                      htmlFor="mobile"
                      className="block  font-normal text-[#000]  mx-10 text-[30px] roboto-serif-header"
                    >
                      Mobile:
                      <span className="text-red-500 text-2xl">&nbsp;*</span>
                    </label>
                    <input
                      type="tel"
                      id="mobile"
                      name="mobile"
                      value={formData.mobile}
                      onChange={handleChange}
                      className="  mt-2 border-none rounded-md focus:outline-none focus:border-black-500 mx-10 bg-[#DD9Ab3] text[#F0F0F0]"
                      placeholder="Enter your Mobile No."
                      required
                    />
                    <hr className="lg:w-[400px]   mb-2 mx-10 h-[1px] lg:h-[2px] bg-[#D9D9D9]" />
                  </div>
                </div>
                <div className="mb-4 w-[95%] mx-auto bg-[#DD9Ab3] h-auto my-15 rounded-2xl sm:mx-5 sm:px-5">
                  <div className="py-2 ">
                    <label
                      htmlFor="feedback"
                      className="block  font-normal text-[#000]  mx-10  text-[30px] roboto-serif-header"
                    >
                      Message:
                      <span className="text-red-500 text-2xl">&nbsp;*</span>
                    </label>
                    <textarea
                      id="feedback"
                      name="feedback"
                      value={formData.feedback}
                      onChange={handleChange}
                      className="  mt-2 border-none bg-[#DD9Ab3] rounded-md focus:outline-none focus:border-black-500 mx-10 text-[#F0F0F0]"
                      placeholder="Some Text"
                      required
                    >
                      {formData.feedback}
                    </textarea>
                    <hr className="lg:w-[400px]  mb-2 mx-10 h-[1px] lg:h-[2px] bg-[#D9D9D9]" />
                  </div>
                </div>
              </AppLayout>
              <div className="mx-[27%] sm:mx-[20%] lg:mx-[40%]">
                <AppLayout>
                  <div className="grid grid-cols-2 gap-2 sm:gap-1">
                    <div className=" p-4 rounded-md">
                      <div className="sm:ml-10">
                        <button
                          type="submit"
                          className="bg-[#FFF] text-black px-4 py-2 rounded-md hover:bg-[#000] hover:text-white focus:outline-none focus:shadow-outline-blue w-28 h-10"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </AppLayout>
              </div>
            </form>
          </div>
        </div>
      </section>
    </AppLayout>
  );
};

export default EnqForm;
