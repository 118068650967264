import React, { useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import "./CssFold/style5.css"
// import required modules
import { Autoplay, Pagination } from "swiper/modules";
import AppLayout from "./AppLayout";

const Offer = ({ business }) => {
  const [isPopOpen, setIsPopupOpen] = useState(false);
  const [image, setImage] = useState("");

  const openPopup = (imageSrc) => {
    setIsPopupOpen(true);
    setImage(imageSrc);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setImage("");
  };

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + "</span>";
    },
  };

  return (
    <div>
      <AppLayout>
        <h6 className="text-center text-[40px] font-bold text-white roboto ">
          Offers
        </h6>
<div className="mt-4">
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          loop={true}
          pagination={pagination}
          autoplay={{
            delay: 4500,
            disableOnInteraction: false,
          }}
          breakpoints={{
            340: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            500: {
              slidesPerView: 3,
              spaceBetween: 15,
            },
          }}
          modules={[Autoplay, Pagination]}
          className="mySwiper"
        >
          {(business.offers || []).map((offer, index) => (
            <SwiperSlide key={index}>
              <div className="pb-10 text-white">
                <img
                  src={`https://admin.bizzata.in/storage/${offer.image}`}
                  className="w-[250px] transition-transform transform duration-100 hover:scale-110 h-[168px] mx-auto rounded-[12px] lg:h-[191px] lg:w-[318px] lg:rounded-[12px] sm:w-[400px]"
                  alt={`offer ${index + 1}`}
                  onClick={() =>
                    openPopup("https://admin.bizzata.in/storage/" + offer.image)
                  }
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
</div>
        {isPopOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
            <div className="relative">
              <img src={image} alt="Popup" className="sm:h-[400px] h-[100%]" />
              <button
                className="absolute top-2 right-2 text-white bg-black rounded-full py-2 px-4"
                onClick={closePopup}
              >
                &times;
              </button>
            </div>
          </div>
        )}
      </AppLayout>
    </div>
  );
};

export default Offer;
