import React from "react";
import AppLayout from "./AppLayout";
import "./css/styles4.css"
// Soceal

const Herro = ({ business }) => {
  return (
    <div className=" !scroll-smooth">
       <h1 className="hidden">{business.meta_title}</h1>
      {/* <div className="sm:h-[215px] h-[130px] w-full bg-[#B89C85]" id="about">
        <div className="flex justify-center items-center">
          <div className="  ">
            <img
              src={`https://admin.bizzata.in/storage/${business.profile}`}
              alt="iconparksolid"
              className="h-[150px] w-[150px] border-4 rounded-full  mt-[-40%] "
            />
          </div>
        </div>
      </div> */}
      <AppLayout>
        <div className="  sm:mt-[58px]  mt-4 py-[40px]  bg-[#E3D1C3] shadow-xs rounded-[45px] mx-2">
          <div class="grid  sm:grid-cols-3  gap-2   " id="about">
            <div class=" p-1 ">
              <div className="flex justify-center items-center sm:mt-[8%] mr-10">
                <img
                  src={`https://admin.bizzata.in/storage/${business.profile}`}
                  className="rounded-full h-[120px] w-[120px] sm:h-52 sm:w-52 object-cover"
                  alt="Description of image"
                />
              </div>
            </div>

            <div class="  sm:col-span-2 p-4  sm:mt-[30px] lg:ml-[-100px] text-[#9F7161] ">
              <h2 className=" font-bold text-[28px] md:text-[35px] text-center sm:text-start pb-2 nunito-sans-uniquifier ">
                {business.business_name}
              </h2>
              <p className=" font-normal text-[20px] md:text-[28px] text-center sm:text-start pb-2 text-[#9F7161] nunito-sans-uniquifier">
                {business.name}
              </p>
              <p className="h-auto text-start  text-[18px] font-normal text-[#9F7161] nunito-sans-bold leading-6">
                {business.description}
              </p>
            </div>
          </div>

          <div className="mx-[2%]  mt-[2%] sm:mt-[10%]  lg:mt-[3%] rounded-full flex flex-wrap px-4 py-4 gap-4 justify-center bg-[#FFF] ">
            {(business.business_social_media_links || []).map(
              (linkData, index) => (
                <a
                  key={index}
                  href={linkData.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={`https://admin.bizzata.in/storage/${linkData.social_media_platform.logo}`}
                    alt={`${linkData.social_media_platform.platform_name} logo`}
                    className="h-[34px] w-[34px]"
                  />
                </a>
              )
            )}
          </div>
        </div>
      </AppLayout>
    </div>
  );
};

export default Herro;
