import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";

import { Navigation } from "swiper/modules";
import AppLayout from "./AppLayout";
import TruncateString from "../../Components/TruncateString ";
 import "./style3.css"
const Review = ({ business }) => {
  return (
    <div className=" py-10">
      <AppLayout>
        <h2 className="text-[30px] text-center pb-6  text-white piazzolla-uniquifier font-semibold ">
          Our Clients Says
        </h2>
        <div>
          {" "}
          <Swiper
            breakpoints={{
              340: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
              540: {
                slidesPerView: 2,
                spaceBetween: 5,
              },
              750: {
                slidesPerView: 2,
                spaceBetween: 15,
              },
              1000: {
                slidesPerView: 3,
                spaceBetween: 15,
              },
            }}
            modules={[Navigation]}
            navigation
            className="mySwiper"
          >
            {(business.testimonials || []).map((testimonial, index) => (
              <SwiperSlide key={index}>
                <div className="mt-2 my-2 h-[100%] w-[300px]   text-[#FFF]  rounded-xl mx-auto">
                  <p className="font-extrabold text-center text-[40px] my-1">'' </p>
                  <h1 className=" px-6 text-[20px] font-sans roboto-serif-custom ">
                    {}
                    <TruncateString
                      inputString={testimonial.content}
                      maxLength={100}
                    />
                  </h1>
                  <div className="sm:pt-4 pt-[-8px] sm:pl-[170px] pl-[120px]">
                    <h1 className="text-[20px] font-semibold sm:pl-10 pl-6 pb-2 roboto-serif-custom">
                      -{testimonial.name}
                    </h1>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </AppLayout>
    </div>
  );
};

export default Review;
