import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "./CssTemp/styles.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Navigation } from "swiper/modules";
import AppLayout from "./AppLayout";
import { Link, useParams } from "react-router-dom";

import TruncateString from "../../Components/TruncateString ";
import createSlug from "../../Components/createSlug";
const Articles = ({ business }) => {
  const { city, slug } = useParams();
  const citySlug = createSlug(city);
  return (
    <div>
      <AppLayout>
        <h2
          className=" font-bold text-[32px] poppins-bold text-center"
          id="blogs"
        >
          Blogs
        </h2>
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          breakpoints={{
            340: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            500: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            900: {
              slidesPerView: 3,
              spaceBetween: 15,
            },
          }}
          navigation={true}
          modules={[Autoplay, Navigation]}
          className="mySwiper"
        >
          {(business.blogs || []).map((blog, index) => (
            <SwiperSlide key={index}>
              <div className="w-[90%] sm:w-[300px]  h-[300px] lg:h-[400px] bg-[#F1F1F1] rounded-md border shadow-xl gap-2 mx-2 my-4 overflow-hidden">
                <div className="relative">
                  <Link to={`/${citySlug}/${slug}/blogpg10/${blog.id}`}>
                    <img
                      src={"https://admin.bizzata.in/storage/" + blog.image}
                      alt={blog.title}
                      className="w-full h-full object-cover"
                    />
                  </Link>
                </div>
                <p className="pt-2 text-start text-[22px] font-bold mx-2  poppins-bold">
                  <Link to={`/${citySlug}/${slug}/blogpg10/${blog.id}`}>
                    <TruncateString inputString={blog.title} maxLength={40} />{" "}
                  </Link>
                </p>
                <h2 className="text-[14px] mx-2  poppins-bold">
                  {" "}
                  <Link>
                    <TruncateString
                      inputString={blog.content}
                      maxLength={160}
                    />{" "}
                  </Link>
                </h2>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </AppLayout>
    </div>
  );
};

export default Articles;
