import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import Feedbackmock from "./MockData/Feedbackmock";
import { Navigation } from "swiper/modules";
import AppLayout from "./AppLayout";
import TruncateString from "../../Components/TruncateString ";
const Reviews = ({ business }) => {
  return (
    <div className="py-10">
      <AppLayout>
        <h2 className="text-[40px] font-bold text-[#326C77] text-center odor-mean-chey-regular ">
          Our Clients Says
        </h2>
        <div>
          {" "}
          <Swiper
            breakpoints={{
              340: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              540: {
                slidesPerView: 2,
                spaceBetween: 15,
              },
              1000: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
            }}
            modules={[Navigation]}
            navigation
            className="mySwiper"
          >
            {(business.testimonials || []).map((testimonial, index) => (
              <SwiperSlide key={index}>
                <div className="mt-2 my-2 h-auto w-[95%] max-w-[350px] sm:max-w-[345px] lg:max-w-[380px] bg-[#B8DBE1] text-[#000] rounded-xl mx-auto p-4">
                  <h1 className="text-[15px] font-sans">
                    <TruncateString
                      inputString={testimonial.content}
                      maxLength={160}
                    />
                  </h1>
                  <div className="pt-4 text-right">
                    <h1 className="text-[16px] font-semibold">
                      - {testimonial.name}
                    </h1>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </AppLayout>
    </div>
  );
};

export default Reviews;
