import React, { useState } from "react";
import AppLayout from "./AppLayout";
import axios from "axios";
import "./TempCss/styles8.css";
const Feedbackform = ({ business }) => {
  const [formData, setFormData] = useState({
    name: "",
    feedback: "",
    business_id: business.id,
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://admin.bizzata.in/api/business_feedback",
        formData
      );
      alert(response.data.message);
    } catch (error) {
      console.error("There was an error!", error);
    }
  };
  return (
    <AppLayout>
      <h2 className="text-center enriqueta-semibold font-bold text-[40px]  text-black mt-8 ">
        Feedback form
      </h2>
      <div className="p-5 mx-0 auto sm:mx-20  w-[100%] sm:w-[80%]  bg-[#224F34] rounded mt-2 lg:mx-32 ">
        <p className="text-white enriqueta-semibold">Name</p>
        <input
          type="text"
          placeholder="Name"
          className=" w-[100%] sm:w-[100%] lg:w-[70%] rounded-md h-8 py-1 px-2 mt-1 "
          value={formData.name}
          onChange={handleChange}
          required
        />
        <p className="text-white mt-5 enriqueta-semibold">Feedback</p>
        <input
          type="text"
          placeholder="Type your message..."
          className=" w-[100%] sm:w-[100%] bg-gradient-to-tl  lg:w-[70%] rounded-md h-8 py-1 px-2 mt-1  "
        />
        <br />
        <button
          type="submit"
          className="bg-[#6BC785] sm:w-[30%] enriqueta-semibold text-white rounded-md py-2 px-3 mt-4 font-medium"
        >
          Submit
        </button>
      </div>
    </AppLayout>
  );
};

export default Feedbackform;
