import React, { useState } from "react";
import AppLayout from "./AppLayout";
import axios from "axios";
  import "./FredCss/styles.css";
const Feedbackform = ({ business }) => {
  const [formData, setFormData] = useState({
    name: "",
    feedback: "",
    business_id: business.id,
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://admin.bizzata.in/api/business_feedback",
        formData
      );
      alert(response.data.message);
    } catch (error) {
      console.error("There was an error!", error);
    }
  };
  return (
    <div className=" bg-[#D6763B] pt-[15px] ">
      <AppLayout>
        <h2 className="font-bold text-[32px] bellota-text-bold  text-center pb-6  text-white ">
          Feedback form
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="p-4 mx-auto sm:mx-20 w-[80%] bg-[#7D3C13] rounded-xl ">
            <p className="text-white bellota">Name</p>
            <input
              type="text"
              placeholder="Name"
              className=" w-[100%] rounded-md h-8 py-1 px-2 mt-1 bg-gradient-to-tl from-[#7D3C13] to-[#be8a5c]"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <p className="text-white mt-5 bellota">Feedback</p>
            <input
              type="text"
              placeholder="Type your message..."
              className=" w-[100%] bg-gradient-to-tl from-[#7D3C13] rounded-md h-14 lg:h-24 px-2 mt-1 to-[#be8a5c]"
              name="feedback"
              value={formData.feedback}
              onChange={handleChange}
              required
            />
            <br />
            <button
              type="submit"
              className="bg-[#D4B294] bellota sm:w-[30%]  text-[#be8a5c] rounded-md py-2 px-3 mt-4 font-medium"
            >
              Submit
            </button>
          </div>{" "}
        </form>
      </AppLayout>
    </div>
  );
};

export default Feedbackform;
