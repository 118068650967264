import React from "react";
import Home from "./Home";

import Hero from "./Hero";

import Categories from "./Categories";
import Articles from "./Articles";
import Galleary from "./Gallery";
import Reviews from "./Reviews";
import Feedbackform from "./Feedbackform";
import Appoinment from "./Appoinment";
import BussinessHours from "./BussinessHours";
import ContactUs from "./ContactUs";
import Certificates from "./Certificates";
import Offers from "./Offers";
import Product from "./Product";
import Contact from "./Contact";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { BusinessProvider } from "../../context/BusinessContext";
import { Helmet } from "react-helmet";
const Temp10 = ({ business }) => {
  if (!business) {
    return <div>Loading...</div>;
  }
  return (
    <BusinessProvider business={business}>
      <Helmet>
        <title>{business.meta_title}</title>
        <meta name="robots" content="index, follow"></meta>
        <link rel="icon" href="./logo.ico" />
        <meta name="description" content={business.meta_keyword} />
        <meta name="keywords" content={business.meta_description} />
        
      </Helmet>
      <div>
        <Navbar business={business} />
        <Home business={business} />
        <Hero business={business} />
        <Contact business={business} />
        <Product business={business} />
        <Categories business={business} />
        <Offers business={business} />
        <Articles business={business} />
        <Galleary business={business} />
        <BussinessHours business={business} />
        <Certificates business={business} />
        <Feedbackform business={business} />
        <Reviews business={business} />
        <Appoinment business={business} />
        <ContactUs business={business} />
        <Footer business={business} />
      </div>
    </BusinessProvider>
  );
};

export default Temp10;
