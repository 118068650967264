import React from "react";
import AppLayout from "./AppLayout";
import eml from "./assites/img_dashicons_email.svg";
import mb from "./assites/img_4.png";
import loc from "./assites/img_group.svg";
import bun from "./assites/img_vector.svg";
import "./CSSTemp6/styles.css";
const Contact = ({ business }) => {
  return (
    <div className="w-auto min-h-[368px] lg:h-[500px] bg-white  mt-2 mb-0.5">
      <AppLayout>
        <div className="font-[700] text-[48px] text-[#326C77]  pt-[20px] lg:pt-[50px] text-start odor-mean">
          Contact <span className="font-[900]">Us</span>
        </div>

        <div className="w-[90%] mx-auto mt-[30px] lg:mt-[70px] flex flex-col lg:flex-row flex-wrap gap-y-[12px] lg:gap-x-[40px]">
          <div className="flex flex-col lg:flex-row w-full lg:w-[46%] rounded-[24px] lg:rounded-[70px] bg-[#72909D] min-h-[52px] lg:min-h-[111px]">
            <div className="flex items-center p-4 lg:p-6">
              <img
                src={loc}
                className="w-[30px] lg:w-[50px] h-[30px] lg:h-[50px] rounded-full"
                alt="Location Icon"
              />
              <div className="ml-4 lg:ml-6">
                <p className="text-[#FFFFFF] font-bold text-[14px] lg:text-[24px] odor-mean">
                  Location
                </p>
                <p className="text-[#FFF] text-[14px] lg:text-[16px] leading-6 odor-mean">
                  {business.address}
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col lg:flex-row w-full lg:w-[46%] rounded-[24px] lg:rounded-[70px] bg-[#72909D] min-h-[52px] lg:min-h-[111px]">
            <div className="flex items-center p-4 lg:p-6">
              <img
                src={mb}
                className="w-[30px] lg:w-[75px] h-[30px] lg:h-[75px] rounded-full"
                alt="Mobile Icon"
              />
              <div className="ml-4 lg:ml-6">
                <p className="text-[#FFFFFF] font-bold text-[14px] lg:text-[24px] odor-mean">
                  Mobile Number
                </p>
                <p className="text-[#FFF] text-[14px] lg:text-[16px] leading-6 odor-mean">
                  {business.mobile}
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col lg:flex-row w-full lg:w-[46%] rounded-[24px] lg:rounded-[70px] bg-[#72909D] min-h-[52px] lg:min-h-[111px]">
            <div className="flex items-center p-4 lg:p-6">
              <img
                src={eml}
                className="w-[30px] lg:w-[75px] h-[30px] lg:h-[75px] rounded-full"
                alt="Email Icon"
              />
              <div className="ml-4 lg:ml-6">
                <p className="text-[#FFFFFF] font-bold text-[14px] lg:text-[24px] odor-mean">
                  E-mail Address
                </p>
                <p className="text-[#FFF] text-[14px] lg:text-[16px] leading-6 odor-mean">
                  {business.email}
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col lg:flex-row w-full lg:w-[46%] rounded-[24px] lg:rounded-[70px] bg-[#72909D] min-h-[52px] lg:min-h-[111px] text-white">
            <div className="flex items-center p-4 lg:p-6">
              <img
                src={bun}
                className="w-[30px] lg:w-[75px] h-[30px] lg:h-[75px] rounded-full"
                alt="Category Icon"
              />
              <div className="ml-4 lg:ml-6">
                <p className="text-[#FFFFFF] font-bold text-[14px] lg:text-[24px] odor-mean">
                  CATEGORY :
                </p>
                <p className="text-[#FFF] text-[14px] lg:text-[16px] leading-6 odor-mean">
                  <a href="">
                    {(business.categories || []).map(
                      (category, index) => category.category_name + ", "
                    )}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </AppLayout>
    </div>
  );
};

export default Contact;
