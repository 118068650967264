import React, { useState } from "react";
import AppLayout from "./AppLayout";
import axios from "axios";
import "./style1.css";
const Appoinment = ({ business }) => {
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    date: "",
    time: "",
    business_id: business.id,
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://admin.bizzata.in/api/business_appointment",
        formData
      );
      alert(response.data.message);
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  return (
    <div className=" mb-4">
      <AppLayout>
        <p className="text-center text-[30px] font-bold text-[#FAFF03] philosopher-regular pt-2 pb-6">
          Appointment
        </p>
        <div className=" pb-4 rounded  bg-[#FFF] pt-4" id="appoinment">
          <form onSubmit={handleSubmit}>
            <div className="w-[90%] sm:w-[85%] lg:w-[80%]   sm:h-[100%]  items-center rounded-[10px]  lg:flex gap-2 justify-around mx-auto mt-[30px]  pb-20">
              <div className="">
                <p className="font-bold text-[10px]  sm:text-[20px]  text-[#000] ">
                  Name
                </p>
                <input
                  type="text"
                  placeholder="name"
                  className="border-2 border-yellow-500 font-bold text-[13px]  sm:text-[18px] w-[100%]  h-[30px]  sm:h-[40px] lg:h-[40px] bg-[#EAB308] rounded-[5px] px-3 text-black"
                />
              </div>
              <div className=" pt-4 lg:pt-0">
                <p className="font-bold text-[10px] sm:text-[20px]  text-[#000] ">
                  Contact No.
                </p>
                <input
                  type="Text"
                  placeholder="Mobile no."
                  className=" border-2 border-yellow-500 font-bold text-[13px]  sm:text-[18px]  w-[100%]  h-[30px] sm:h-[40px] lg:h-[40px] bg-[#EAB308] rounded-[5px] px-3 text-black"
                />
              </div>
              <div className=" pt-4 lg:pt-0">
                <p className="font-bold text-[10px] sm:text-[20px]  text-[#000] ">
                  Date
                </p>
                <input
                  type="date"
                  placeholder="Date"
                  className="border-2 border-yellow-500 font-bold text-[13px]  sm:text-[18px]  w-[100%]  h-[30px] sm:h-[40px] lg:h-[40px] bg-[#EAB308] rounded-[5px] px-3 text-black"
                />
              </div>
              <div className="pt-4 lg:pt-0">
                <p className="font-bold text-[10px] sm:text-[20px] text-black">
                  Time
                </p>
                <input
                  type="time"
                  className="border-2 border-yellow-500 font-bold text-[13px] sm:text-[18px] w-full h-[30px] sm:h-[40px] lg:h-[40px] bg-[#EAB308] rounded-[5px] px-3 text-black"
                  name="time"
                  value={formData.time}
                  onChange={handleChange}
                  required
                />
              </div>
              <button
                type="submit"
                className="bg-yellow-500 text-black font-bold sm:w-[30%]   rounded-md py-2 px-3 mt-7"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </AppLayout>
    </div>
  );
};

export default Appoinment;
