import React, { useState } from "react";
import AppLayout from "./AppLayout";
import axios from "axios";
import "./style3.css"
const Feedbackform = ({ business }) => {
  const [feedbackFormData, setFeedbackFormData] = useState({
    name: "",
    feedback: "",
    business_id: business.id,
  });

  const [appointmentFormData, setAppointmentFormData] = useState({
    name: "",
    mobile: "",
    date: "",
     time: "",
    business_id: business.id,
  });

  const handleFeedbackChange = (e) => {
    setFeedbackFormData({
      ...feedbackFormData,
      [e.target.name]: e.target.value,
    });
  };

  const handleAppointmentChange = (e) => {
    setAppointmentFormData({
      ...appointmentFormData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFeedbackSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://admin.bizzata.in/api/business_feedback",
        feedbackFormData
      );
      alert(response.data.message);
      setFeedbackFormData({
        name: "",
        feedback: "",
        business_id: business.id,
      });
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  const handleAppointmentSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://admin.bizzata.in/api/business_appointment",
        appointmentFormData
      );
      alert(response.data.message);
      setAppointmentFormData({
        name: "",
        date: "",
        mobile: "",
         time: "",
        business_id: business.id,
      });
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  return (
    <AppLayout>
    <div className="grid grid-cols-1 sm:grid-cols-2  p-4">
      <div className="py-[15px] my-8">
        <AppLayout>
          <h2 className=" text-[30px] pb-6 text-white text-center piazzolla-uniquifier font-semibold">
            Feedback form
          </h2>
          <form onSubmit={handleFeedbackSubmit}>
            <div className="p-4 mx-auto sm:mx-20 w-[80%] bg-[#5E1523] rounded-xl">
              <p className="text-[#FFF]">Name</p>
              <input
                type="text"
                placeholder="Name"
                className="w-[100%] rounded-md h-8 py-1 px-2 mt-1 bg-black text-white"
                value={feedbackFormData.name}
                name="name"
                onChange={handleFeedbackChange}
                required
              />
              <p className="text-[#FFF] mt-5">Feedback</p>
              <input
                type="text"
                placeholder="Type your message..."
                className="w-[100%] rounded-md h-14 lg:h-24 px-2 mt-1 bg-black text-white"
                value={feedbackFormData.feedback}
                name="feedback"
                onChange={handleFeedbackChange}
                required
              />
              <br />
              <div className="mx-[35%]">
                <button
                  type="submit"
                  className="bg-[#FFF] sm:w-[60%] text-black rounded-md py-2 px-3 mt-4 font-medium"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </AppLayout>
      </div>
      
      <div className="mb-4">
        <AppLayout>
          <p className="text-center  text-[35px] text-[#FFF] pt-2 pb-6 piazzolla-uniquifier font-semibold">
            Appointment
          </p>
          <form onSubmit={handleAppointmentSubmit}>
            <div className="pb-10 rounded-xl bg-[#920C26] pt-6">
              <div className="w-[90%] sm:w-[85%] lg:w-[80%] sm:h-[100%] items-center rounded-[10px] lg:flex gap-2 justify-around mx-auto mt-[30px] flex-wrap">
                <div>
                  <p className="font-bold text-[10px] sm:text-[20px] text-[#FFF]">
                    Name
                  </p>
                  <input
                    type="text"
                    placeholder="name"
                    className="font-bold text-[13px] sm:text-[18px] w-[100%] h-[30px] sm:h-[40px] lg:h-[40px] bg-[#000] rounded-[5px] px-3 text-white"
                    name="name"
                    value={appointmentFormData.name}
                    onChange={handleAppointmentChange}
                    required
                  />
                </div>
                <div className="pt-4 lg:pt-0">
                  <p className="font-bold text-[10px] sm:text-[20px] text-[#FFF]">
                    Contact No.
                  </p>
                  <input
                    type="text"
                    placeholder="Mobile no."
                    className="font-bold text-[13px] sm:text-[18px] w-[100%] h-[30px] sm:h-[40px] lg:h-[40px] bg-[#000] rounded-[5px] px-3 text-white"
                    name="mobile"
                    value={appointmentFormData.mobile}
                    onChange={handleAppointmentChange}
                    required
                  />
                </div>
                <div className="pt-4 lg:pt-0">
                  <p className="font-bold text-[10px] sm:text-[20px] text-[#FFF]">
                    Date
                  </p>
                  <input
                    type="date"
                    placeholder="Date"
                    className="font-bold text-[13px] sm:text-[18px] w-[100%] h-[30px] sm:h-[40px] lg:h-[40px] bg-[#000] rounded-[5px] px-3 text-white"
                    name="date"
                    value={appointmentFormData.date}
                    onChange={handleAppointmentChange}
                    required
                  />
                </div>
                <div className="pt-4 lg:pt-0">
                <p className="font-bold text-[10px] sm:text-[20px] text-white">
                  Time
                </p>
                <input
                  type="time"
                  className="font-bold text-[13px] sm:text-[18px] w-full h-[30px] sm:h-[40px] lg:h-[40px] bg-black rounded-[5px] px-3 text-white"
                  name="time"
                  // value={formData.time}
                  onChange={handleAppointmentChange}
                  required
                />
              </div>
                <button
                  type="submit"
                  className="bg-[#FFF] sm:w-[30%] text-black rounded-md py-2 px-3 mt-7 font-medium"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </AppLayout>
      </div>
    </div>
    </AppLayout>
  );
};

export default Feedbackform;
