import React from "react";
import loc from "./assets/1.png";
import mb from "./assets/4.png";
import emil from "./assets/5.png";
import AppLayout from "./AppLayout";
import bb from "./assets/Category.svg";
import "./css/styles4.css";
const Contact = ({ business }) => {
  return (
    <div
      className="w-auto min-h-[368px] lg:h-[500px] bg-[#D3BDAB] my-10"
      id="Contact"
    >
      <AppLayout>
        <div className="font-[700] text-[48px] text-[#9F7161] ml-8 lg:ml-24 pt-[20px] lg:pt-[50px] odor-mean-chey-regular">
          Contact <span className="font-[900]">Us</span>
        </div>

        <div className="w-[85%] mx-auto my-[30px] lg:mt-[70px] flex flex-col lg:flex-row flex-wrap gap-y-[12px] lg:gap-x-[40px]">
          <div className="w-[300px] rounded-[24px] lg:rounded-[70px] bg-[#B89C85] min-h-[52px] lg:min-h-[111px] lg:w-[46%] ">
            <div className="min-h-[52px] lg:min-h-[111px]  flex lg:items-center">
              <img
                src={loc}
                alt="lodinge..."
                className="w-[30px] lg:w-[50px] h-[30px] lg:h-[50px] rounded-[50%] ml-[15px] my-auto cursor-pointer"
              />
              <div className="ml-[11px] lg:ml-[25px] mt-[2px] ">
                <p className="text-[#FFFFFF] font-[700] text-[20px] lg:text-[23px] p-0 nunito-sans-uniquifier">
                  Location
                </p>
                <p className="font-[400] text-[16px]  text-white p-0 mx-1 lg:leading-5 leading-4 pb-2 nunito-sans-bold">
                  <a href="">{business.address}</a>
                </p>
              </div>
            </div>
          </div>

          <div className="w-320px rounded-[24px] lg:rounded-[70px] bg-[#B89C85] min-h-[52px] lg:min-h-[111px] lg:w-[46%]">
            <div className="min-h-[52px] lg:min-h-[111px]  flex lg:items-center">
              <img
                src={mb}
                className="w-[30px] lg:w-[50px] h-[30px] lg:h-[50px] rounded-[50%] ml-[15px] my-auto cursor-pointer"
              />
              <div className="ml-[11px] lg:ml-[25px] mt-[2px] ">
                <p className="text-[#FFFFFF] font-[700] text-[20px] lg:text-[23px] p-0 nunito-sans-uniquifier">
                  Mobile Number
                </p>
                <p className="font-[400] text-[16px]  text-white p-0 nunito-sans-bold">
                  {business.mobile}
                </p>
              </div>
            </div>
          </div>

          <div className="w-320px rounded-[24px] lg:rounded-[70px] bg-[#B89C85] min-h-[52px] lg:min-h-[111px] lg:w-[46%]">
            <div className="min-h-[52px] lg:min-h-[111px]  flex lg:items-center">
              <img
                src={emil}
                className="w-[30px] lg:w-[50px] h-[30px] lg:h-[50px] rounded-[50%] ml-[15px] my-auto cursor-pointer"
              />
              <div className="ml-[11px] lg:ml-[25px] mt-[2px] ">
                <p className="text-[#FFFFFF] font-[700] text-[20px] lg:text-[23px] p-0 nunito-sans-uniquifier">
                  E-mail address
                </p>
                <p className="font-[400] text-[16px]  text-white p-0 nunito-sans-bold">
                  {" "}
                  <a href="mailto:anyintech@anyintech.com?subject = Feedback&body = Message ">
                    {business.email}
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div className="w-320px rounded-[24px] lg:rounded-[70px] bg-[#B89C85] min-h-[52px] lg:min-h-[111px] lg:w-[46%] mb-2 sm:mb-0">
            <div className="min-h-[52px] lg:min-h-[111px]  flex lg:items-center">
              <img
                src={bb}
                className="w-[30px] lg:w-[50px] h-[30px] lg:h-[50px] rounded-[50%] ml-[15px] bg-[#FFF] my-auto cursor-pointer"
              />
              <div className="ml-[11px] lg:ml-[25px] mt-[2px] ">
                <p className="text-[#FFFFFF] font-[700] text-[20px] lg:text-[23px] p-0 nunito-sans-uniquifier">
                  Category
                </p>
                <p className="font-[400] text-[16px]  text-[#FFF] p-0 nunito-sans-bold">
                  <a href="">
                    <a href="">
                      {" "}
                      {(business.categories || []).map(
                        (category, index) => category.category_name + ", "
                      )}
                    </a>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </AppLayout>
    </div>
  );
};

export default Contact;
