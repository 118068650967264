import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "./TempCss/styles8.css";
import { Navigation } from "swiper/modules";
import AppLayout from "./AppLayout";
import createSlug from "../../Components/createSlug";
import { useParams, Link } from "react-router-dom";
import TruncateString from "../../Components/TruncateString ";
function Service({ business }) {
  const { city, slug } = useParams();
  const citySlug = createSlug(city);
  return (
    <div className="mt-10 mx-auto" id="services">
      <AppLayout>
        <h1 className="text-center enriqueta-semibold font-bold text-[40px] mb-6">
          Services
        </h1>
        <Swiper
          breakpoints={{
            340: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            540: {
              slidesPerView: 3,
              spaceBetween: 5,
            },
            700: {
              slidesPerView: 3,
              spaceBetween: 15,
            },
            900: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
          }}
          modules={[Navigation]}
          navigation
          className="mySwiper"
        >
          <div className="mx-auto ">
            {(business.services || []).map((service, index) => (
              <SwiperSlide key={index}>
                <div className="w-[95%] max-w-[320px] sm:max-w-[220px] lg:max-w-[350px] h-[500px]   rounded-md border bg-[#224f34] gap-6 mx-4 p-2 overflow-hidden">
                  <Link to={`/${citySlug}/${slug}/servicepage8/${service.id}`}>
                    <img
                      src={"https://admin.bizzata.in/storage/" + service.image}
                      alt={service.service_name}
                      className="h-[220px]  w-full rounded-t-md object-cover"
                    />
                  </Link>
                  <div className="p-1 flex flex-col items-center">
                    <h1 className="mt-4 enriqueta-semibold text-[20px] sm:text-xl lg:text-2xl font-bold text-start text-white hover:underline">
                      <Link
                        to={`/${citySlug}/${slug}/servicepage8/${service.id}`}
                      >
                        <TruncateString
                          inputString={service.service_name}
                          maxLength={30}
                        />
                      </Link>
                    </h1>
                    <p className="mt-2 sm:mt-2 enriqueta-semibold lg:mt-5 text-[16px]  text-[#759181] text-center sm:text-start">
                      <TruncateString
                        inputString={service.description}
                        maxLength={170}
                      />
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </div>
        </Swiper>
      </AppLayout>
    </div>
  );
}

export default Service;
