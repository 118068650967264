import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import AppLayout from "./AppLayout";
import TruncateString from "../../Components/TruncateString ";
   import "./FredCss/styles.css";
const Review = ({ business }) => {
  return (
    <div className="bg-[#D6763B] py-10 mx-auto">
      <AppLayout>
        <h2 className="font-bold bellota-text-bold text-[32px] text-center pb-6 text-white">
          Our Clients Say
        </h2>
        <Swiper
          breakpoints={{
            340: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            540: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            750: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1000: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
          }}
          modules={[Navigation]}
          navigation
          className="mySwiper"
        >
          {(business.testimonials || []).map((testimonial, index) => (
            <SwiperSlide key={index}>
              <div className="max-w-sm mx-auto mt-2 my-2 p-4 h-[180px] bg-[#7D3C13] text-[#A49FAF] rounded-xl shadow-lg flex flex-col justify-between">
                <p className="text-[14px] font-sans bellota">
                  "
                  <TruncateString
                    inputString={testimonial.content}
                    maxLength={150}
                  />
                  "
                </p>
                <div className="text-right">
                  <h1 className="text-[18px] font-bold bellota">
                    - {testimonial.name}
                  </h1>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </AppLayout>
    </div>
  );
};

export default Review;
