import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
 import "./cssFold/style7.css";
import { Navigation } from "swiper/modules";
import AppLayout from "./AppLayout";
import TruncateString from "../../Components/TruncateString ";
const Review = ({ business }) => {
  return (
    <div className="py-10">
      <AppLayout>
        <h2 className="font-bold text-[30px] enriqueta-medium text-center pb-6  text-white ">
          Our Clients Says
        </h2>
        <div>
          {" "}
          <Swiper
            breakpoints={{
              340: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              540: {
                slidesPerView: 2,
                spaceBetween: 5,
              },
              750: {
                slidesPerView: 2,
                spaceBetween: 15,
              },
              1000: {
                slidesPerView: 3,
                spaceBetween: 15,
              },
            }}
            modules={[Navigation]}
            navigation
            className="mySwiper"
          >
            {(business.testimonials || []).map((testimonial, index) => (
              <SwiperSlide key={index}>
                <div className="max-w-md w-[95%] mx-auto bg-gray-800 rounded-lg overflow-hidden shadow-lg h-[210px] ">
                  <div className="p-2 h-[140px] md:pt-4">
                    <p className="text-gray-300 italic text-[18px] enriqueta-medium">
                      "
                      <TruncateString
                        inputString={testimonial.content}
                        maxLength={100}
                      />
                      "
                    </p>
                  </div>
                  <div className="flex items-center bg-yellow-600 p-4 gap-10 pb-2 h-[70px]">
                    <img
                      className="w-12 h-12 rounded-full mr-4"
                      src="https://beauty-parlour-three.vercel.app/static/media/lady.3b99d4cf6d66b78659b7.png"
                      alt="Loding"
                    />
                    <div>
                      <p className="text-white font-bold enriqueta-medium text-[22px]">
                        {" "}
                        - {testimonial.name}
                      </p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </AppLayout>
    </div>
  );
};

export default Review;
