import React, { useState } from "react";
import AppLayout from "./AppLayout";
import axios from "axios";
import "./CSSTemp6/styles.css";
const Enquiry = ({ business }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    feedback: "",
    business_id: business.id,
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://admin.bizzata.in/api/business_feedback",
        formData
      );
      alert(response.data.message);
    } catch (error) {
      console.error("There was an error!", error);
    }
    setFormData({
      name: "",
      email: "",
      mobile: "",
      feedback: "",
      business_id: business.id,
    });
  };

  const handleClearForm = () => {
    setFormData({
      name: "",
      email: "",
      mobile: "",
      feedback: "",
      business_id: business.id,
    });
  };

  return (
    <div className="h-auto my-8 " id="enquiry">
      <AppLayout>
        <p className="text-[40px] font-bold text-[#326C77] text-center odor-mean-chey-regular  py-2">
          Enquiry form
        </p>
        <div className="mx-4 md:mx-[5%] border-2 border-[#FFF] rounded-[20px] drop-shadow-xl">
          <div className=" text-white bg-[#B8DBE2] rounded-t-[20px] flex flex-col md:flex-row justify-evenly">
            <div className="py-4 w-full md:w-[450px]"></div>
            <div className="py-4 w-full md:w-[500px]">
              <h1 className=" text-center font-semibold text-2xl md:text-[28px] text-[#326C77]">
                GET IN TOUCH
              </h1>
              <p className=" text-center text-[14px] font-light text-[#326C77]">
                We would like to hear from you and always available to answer
                any question or address any concerns you may have
              </p>
            </div>
          </div>
          <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-[450px]">
              <img
                src={`https://admin.bizzata.in/storage/${business.banner}`}
                alt=""
                className="md::w-[450px] h-[0px]  md:h-[380px]"
              />
            </div>
            <div className="bg-[#FFF] text-black w-full md:w-[630px] h-auto md:h-[378px] p-4">
              <div className="mt-4">
                <form onSubmit={handleSubmit}>
                  <div className="mb-2  w-[95%] mx-auto h-auto   ">
                    <div className="py-2 text-[#326C77]">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                        className="w-[70%] mt-2 border-none focus:outline-none focus:border-black-500  text-[#326C77] odor-mean"
                        placeholder=" Name"
                      />
                      <hr className="lg:w-[400px] w-[90%] mb-2 mx-2 h-[1px] lg:h-[2px] bg-[#D9D9D9]" />
                    </div>
                  </div>

                  <div className="mb-2 w-[95%] mx-auto  h-auto ">
                    <div className="py-2">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="w-[70%] mt-2 border-none  focus:outline-none focus:border-black-500 text-[#326C77] odor-mean"
                        placeholder="Enter your Email"
                        required
                      />
                      <hr className="lg:w-[400px] w-[90%] mb-2 mx-2 h-[1px] lg:h-[2px] bg-[#D9D9D9]" />
                    </div>
                  </div>

                  <div className="mb-2 w-[95%] mx-auto  h-auto ">
                    <div className="py-2">
                      <input
                        type="tel"
                        id="mobile"
                        name="mobile"
                        value={formData.mobile}
                        onChange={handleChange}
                        className="w-[70%] mt-2 border-none  focus:outline-none focus:border-black-500 text-[#326C77] odor-mean"
                        placeholder="Enter your Mobile No."
                        required
                      />
                      <hr className="lg:w-[400px] w-[90%] mb-2 mx-2 h-[1px] lg:h-[2px] bg-[#D9D9D9]" />
                    </div>
                  </div>

                  <div className="mb-4 w-[95%] mx-auto   my-15  h-[80px] ">
                    <div className="py-2">
                      <textarea
                        id="feedback"
                        name="feedback"
                        value={formData.feedback}
                        onChange={handleChange}
                        className="w-[90%] mt-2 border-none  focus:outline-none focus:border-black-500 h-[60px]  text-[#326C77] odor-mean"
                        placeholder="Enter your Message"
                        required
                        rows="4"
                      />
                      <hr className="lg:w-[400px] w-[90%] mb-2 mx-2 h-[1px] lg:h-[2px] bg-[#D9D9D9]" />
                    </div>
                  </div>

                  <div className="flex justify-between items-center mx-2 sm:mx-10 mt-[10px]">
                    <button
                      type="submit"
                      className="bg-[#326C77] text-white px-4 py-2 rounded-md focus:outline-none focus:shadow-outline-blue w-28 h-10"
                    >
                      Submit
                    </button>
                    <button
                      type="button"
                      onClick={handleClearForm}
                      className="font-bold px-4 py-2 rounded-md text-[#326C77] hover:text-black focus:outline-none focus:shadow-outline-blue"
                    >
                      Clear form
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </AppLayout>
    </div>
  );
};

export default Enquiry;
