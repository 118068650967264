import React from "react";
import "./cssFold/style7.css";
function Footer({ business }) {
  const year = new Date().getFullYear();
  return (
    <div className="w-full     bg-[#5F4614] text-[#FFF] p-2 sm:p-0   lg:pb-4">
      <div class="flex justify-evenly flex-wrap">
        <div className="  my-10">
          <p className="mb-6 text-xl font-bold enriqueta-medium">Contaact Details :-</p>
          <ul className="flex flex-col space-y-2   ">
            <h1 className=" font-bold text-xl enriqueta-medium">Address</h1>
            <li className=" hover:text-black text-lg cursor-pointer enriqueta-medium">
              {business.address}
            </li>
            <h1 className=" font-bold text-xl enriqueta-medium">phone</h1>
            <li className="hover:text-black cursor-pointer text-lg enriqueta-medium">
              +91{business.mobile}
            </li>
            <h1 className=" font-bold text-xl enriqueta-medium">Email.</h1>
            <li className=" hover:text-black text-lg cursor-pointer enriqueta-medium">
              {business.email}
            </li>
          </ul>
        </div>

        <div className=" mb-2 sm:mb-8 lg:mb-0  lg:mt-10 mx-5 sm:mx-0 lg:ml-[10%]">
          <h2 className="mb-4 text-[20px] font-bold  pt-12  enriqueta-medium">Like & Follow</h2>
          <div className=" rounded-full flex flex-wrap px-4 py-4 gap-4 justify-center  sm:ml-[-50%] lg:ml-[-73%] ">
            {(business.business_social_media_links || []).map(
              (linkData, index) => (
                <div className="">
                  <a
                    key={index}
                    href={linkData.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={`https://admin.bizzata.in/storage/${linkData.social_media_platform.logo}`}
                      alt={`${linkData.social_media_platform.platform_name} logo`}
                      className="h-[34px] w-[34px] "
                    />
                  </a>
                </div>
              )
            )}
          </div>
          <ul>
            <li className="text-2xl font-bold enriqueta-medium">PAY NOW</li>
            <li>
              <img
                src={`https://admin.bizzata.in/storage/${business.qr_code}`}
                alt="Loding..."
                className="m-2 h-[120px] w-[#130px] pb-5 cursor-pointer"
              />
            </li>
          </ul>
        </div>
      </div>
      <hr className="my-1 " />
      <div className="mx-auto mt-2 ">
        <div className=" text-center">
          <p className="text-sm font-medium text-center ">
            © {year} {business.business_name}. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
