import React from "react";
import AppLayout from "./AppLayout";
import { useParams, Link } from "react-router-dom";
import createSlug from "../../Components/createSlug";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "./CSSTemp6/styles.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation } from "swiper/modules";
import TruncateString from "../../Components/TruncateString ";

const Product = ({ business }) => {
  const { city, slug } = useParams();
  const citySlug = createSlug(city);
  return (
    <div className="mt-4" id="products">
      <div className="h-[650px] bg-white Pb-[2px] rounded-lg shadow-md">
        <AppLayout>
          <h2 className=" text-[40px] font-bold text-[#326C77] text-center odor-mean-chey-regular mb-3">
            Products
          </h2>
          <Swiper
            breakpoints={{
              340: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              650: {
                slidesPerView: 2,
                spaceBetween: 15,
              },
              960: {
                slidesPerView: 3,
                spaceBetween: 15,
              },
            }}
            loop={true}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Pagination, Navigation]}
            className="mySwiper"
          >
            <div className="flex px-2 mx-2 sm:mx-[2px]">
              {(business.products || []).map((product, index) => (
                <SwiperSlide key={index}>
                  <div className="w-[95%] sm:w-[300px] shadow-lg h-[500px] mx-auto bg-[#CCE7F2] rounded-[20px] flex flex-col items-center p-4 mb-6 overflow-hidden">
                    <Link
                      to={`/${citySlug}/${slug}/productpg6/${product.id}`}
                      className="w-full"
                    >
                      <img
                        src={`https://admin.bizzata.in/storage/${product.image}`}
                        alt={product.product_name}
                        className="h-[200px] w-full rounded-t-[20px] object-cover bg-white"
                      />
                    </Link>
                    <div className="text-center mt-4 w-full p-2">
                      <Link
                        to={`/${citySlug}/${slug}/productpg6/${product.id}`}
                      >
                        <h2
                          className="text-xl sm:text-2xl text-white font-bold odor-mean-chey-regular text-start"
                        >
                          <TruncateString
                            inputString={product.product_name}
                            maxLength={50}
                          />
                        </h2>
                      </Link>
                      <p
                        className="mt-2 text-base text-start font-bold text-[#326C77] odor-mean "
                       
                      >
                        <TruncateString
                          inputString={product.description}
                          maxLength={150}
                        />
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </div>
          </Swiper>
        </AppLayout>
      </div>
    </div>
  );
};

export default Product;
