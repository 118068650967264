import AppLayout from "./AppLayout";
import "./style3.css"
import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Keyboard, Navigation, Pagination } from "swiper/modules";
import { useParams, Link } from "react-router-dom";
import createSlug from "../../Components/createSlug";
import TruncateString from "../../Components/TruncateString ";
 import "./style3.css"
const Product = ({ business }) => {
  const { city, slug } = useParams();
  const citySlug = createSlug(city);
  return (
    <div className="my-2 mx-[5%]" id="products">
      <AppLayout>
        <h2 className=" text-[30px] text-center text-white piazzolla-uniquifier font-semibold">
          Products
        </h2>
        <div className="">
          <Swiper
            slidesPerView={1}
            spaceBetween={30}
            keyboard={{
              enabled: true,
            }}
            breakpoints={{
              340: {
                slidesPerView: 1,
                spaceBetween: 30,
              },
              500: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              700: {
                slidesPerView: 2,
                spaceBetween: 15,
              },
              1100: {
                slidesPerView: 4,
                spaceBetween: 10,
              },
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Keyboard, Pagination, Navigation]}
            className="mySwiper"
          >
            {(business.products || []).map((product, index) => (
              <SwiperSlide key={index}>
                <div className="px-2">
                  <div className="w-[100%] sm:w-[100%] md:w-[100%] lg:w-[100%] xl:w-[100%] h-[400px] relative flex-col mt-4 mx-auto gap-2 sm:mb-8 group  sm:pb-4 mb-5 bg-[#5E1523] border-2 justify-between border-black rounded-2xl overflow-hidden ">
                    <div className="">
                      <div className="relative">
                        <Link
                          to={`/${citySlug}/${slug}/productpg3/${product.id}`}
                        >
                          <img
                            src={
                              "https://admin.bizzata.in/storage/" +
                              product.image
                            }
                            alt={product.product_name}
                            className="h-[190px] p-6 w-full  cursor-pointer  object-cover object-fit  rounded-2xl border-black "
                          />
                        </Link>
                      </div>
                      <p className="pt-4 text-center font-serif leading-5 text-[16px]  font-thin text-white px-4 ">
                        <Link
                          to={`/${citySlug}/${slug}/productpg3/${product.id}`}
                        >
                          {product.product_name}
                        </Link>
                      </p>

                      <p className="pt-2 text-justify px-[5%] text-[15px] tracking-0.5 leading-5 text-[#C7B8B3] piazzolla-uniquifier ">
                        <Link
                          to={`/${citySlug}/${slug}/productpg3/${product.id}`}
                        >
                          <TruncateString
                            inputString={product.description}
                            maxLength={135}
                          />
                        </Link>
                      </p>
                    </div>
                    <div className="absolute inset-x-[15%] sm:inset-x-[20%] bottom-3">
                      <Link
                        to={`/${citySlug}/${slug}/productpg3/${product.id}`}
                      >
                        <button className="bg-white  text-black font-bold py-2 px-4  rounded  w-[150px] ">
                          Shop Now
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </AppLayout>
    </div>
  );
};

export default Product;
