import React from "react";
import { useParams, Link } from "react-router-dom";
import AppLayout from "./AppLayout";
import createSlug from "../../Components/createSlug";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./FredCss/styles.css";
// import required modules
import { Autoplay } from "swiper/modules";
import TruncateString from "../../Components/TruncateString ";

const Blogs = ({ business }) => {
  const { city, slug } = useParams();
  const citySlug = createSlug(city);

  return (
    <div className="bg-[#d6763b] mx-auto" id="blogs">
      <AppLayout>
        <div className="mx-auto">
          <h2 className="bellota-text-bold font-bold text-[32px] text-center  pt-[8px] pb-[15px] text-white">
            Blogs
          </h2>

          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 4500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              340: {
                slidesPerView: 1,
                spaceBetween: 50,
              },
              500: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              900: {
                slidesPerView: 3,
                spaceBetween: 15,
              },
              1110: {
                slidesPerView: 3,
                spaceBetween: 15,
              },
            }}
            Pagination={true}
            // navigation={true}
            modules={[Autoplay, Pagination]}
            className="mySwiper"
          >
            <div className="flex flex-wrap justify-center mx-auto">
              {(business.blogs || []).map((blog, index) => (
                <SwiperSlide key={index} className="flex justify-center">
                  <div className="w-[90%] h-[450px] bg-[#D4B294] rounded-md border shadow-xl gap-2 mx-2 my-4 overflow-hidden">
                    <Link to={`/${citySlug}/${slug}/blogespage/${blog.id}`}>
                      <img
                        src={"https://admin.bizzata.in/storage/" + blog.image}
                        alt={blog.title}
                        className="h-48 w-full object-cover cursor-pointer"
                      />
                    </Link>
                    <div className="py-2 p-1">
                      <h1 className="mt-4 text-[20px] bellota  font-bold text-center text-black hover:underline">
                        <Link to={`/${citySlug}/${slug}/blogespage/${blog.id}`}>
                          <TruncateString
                            inputString={blog.title}
                            maxLength={30}
                          />
                        </Link>
                      </h1>
                      <h1 className="mt-2 bellota text-[#7D3C13] text-[14px] font-medium tracking-0.5 cursor-pointer leading-5 text-start">
                        <Link>
                          <TruncateString
                            inputString={blog.content}
                            maxLength={170}
                          />
                        </Link>
                      </h1>
                      <div className="pt-4 text-center">
                        <Link
                          to={`/${citySlug}/${slug}/blogespage/${blog.id}`}
                          className="text-orange-600 bellota font-semibold hover:text-orange-800 transition-colors duration-300"
                        >
                          Read more &rarr;
                        </Link>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </div>
          </Swiper>
        </div>
      </AppLayout>
    </div>
  );
};

export default Blogs;
