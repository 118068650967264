import React from "react";

const Whitebox = ({ business }) => {
  return (
    <div id="home" className=" bg-[#b1aeae]  ">
      <img
        src={`https://admin.bizzata.in/storage/${business.banner}`}
        alt=""
        srcset=""
        className="logine"
      />
    </div>
  );
};

export default Whitebox;
