import React from "react";
import AppLayout from "./AppLayout";
import createSlug from "../../Components/createSlug";
import { Link, useParams } from "react-router-dom";
import TruncateString from "../../Components/TruncateString ";
import "./cssFold/style7.css";
const Service = ({ business }) => {
  const { city, slug } = useParams();
  const citySlug = createSlug(city);

  return (
    <AppLayout>
      <div className="mt-2 p-2 md:p-0">
        <h2
          className="font-semibold text-[30px] text-center text-white mt-10 enriqueta-medium"
          id="services"
        >
          OUR SERVICES
        </h2>
        {(business.services || []).map((service, index) => (
          <div
            key={index}
            className={`flex justify-between mb-10 mt-10 flex-wrap ${
              index % 2 === 0 ? "flex-row-reverse" : ""
            }`}
          >
            <div>
              {/* <Link to={`/${citySlug}/${slug}/servicePage7/${service.id}`}> */}
              <img
                src={"https://admin.bizzata.in/storage/" + service.image}
                alt={service.service_name}
                className="rounded-3xl w-[550px] h-[300px]"
              />
              {/* </Link> */}
            </div>
            <div className="self-center text-center mt-3 text-block sm:mt-0 mx-auto sm:mx-0">
              <h4 className="text-[12px] lg:text-[22px] md:text-[15px] text-white mb-10 enriqueta-medium">
                <TruncateString
                  inputString={service.service_name}
                  maxLength={60}
                />{" "}
              </h4>

              <button className="border border-[#FBBB17] enriqueta-medium text-white py-2 px-4 rounded hover:bg-[#282620] hover:text-[#FBBB17] transition duration-300 mt-5">
                <a href="#appoinment">BOOK AN APPOINTMENT</a>
              </button>
            </div>
          </div>
        ))}
      </div>
    </AppLayout>
  );
};

export default Service;
