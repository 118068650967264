import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./style3.css"

// import required modules
import { Pagination } from "swiper/modules";

import AppLayout from "./AppLayout";
const Certificates = ({ business }) => {
  const [isPopOpen, setIsPopupOpen] = useState(false);
  const [image, setImage] = useState("");

  const openPopup = (imageSrc) => {
    setIsPopupOpen(true);
    setImage(imageSrc);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setImage("");
  };

  return (
    <>
      <div className="">
        <AppLayout>
          <h2 className="text-center font-bold text-[30px] items-center  pt-[10px] piazzolla-uniquifier font-semibold text-[#FFF]">
            Certificates
          </h2>
        </AppLayout>

        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          loop={true}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            340: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            700: {
              slidesPerView: 3,
              spaceBetween: 15,
            },
          }}
        
          modules={[Pagination]}
          className="mySwiper"
        >
          {(business.certificates || []).map((certificate, index) => (
            <SwiperSlide>
              <div key={index} className="flex items-center justify-center">
                <img
                  src={"https://admin.bizzata.in/storage/" + certificate.image}
                  className="w-[281px] transition-transform transform duration-100 hover:scale-110 h-[168px] mx-auto rounded-[12px] lg:h-[191px] lg:w-[318px] lg:rounded-[12px]"
                  alt={`offer ${index + 1}`}
                  onClick={() =>
                    openPopup(
                      "https://admin.bizzata.in/storage/" + certificate.image
                    )
                  }
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        {isPopOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
            <div className="relative">
              <img src={image} alt="Popup" className="h-[100%] sm:h-[400px]" />
              <button
                className="absolute top-2 right-2 bg-white rounded-full p-2"
                onClick={closePopup}
              >
                &times;
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Certificates;
