import React from 'react'


const Home = ({business}) => {
  return (
    <div className=' ' id='home'>
        <img src={`https://admin.bizzata.in/storage/${business.banner}`} alt="" srcset=""  className='logine'/>
    </div>
    
  )
}

export default Home