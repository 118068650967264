import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "./TempCss/styles8.css";
import { Navigation } from "swiper/modules";
import AppLayout from "./AppLayout";
import TruncateString from "../../Components/TruncateString ";
const Reviews = ({ business }) => {
  return (
    <div className="py-6">
      <AppLayout>
        <div>
          <h2 className="text-center enriqueta-semibold font-bold text-[40px]  text-black mt-8 ">
            Our Clintes Says
          </h2>
          <Swiper
            breakpoints={{
              340: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              540: {
                slidesPerView: 2,
                spaceBetween: 5,
              },
              750: {
                slidesPerView: 2,
                spaceBetween: 15,
              },
              770: {
                slidesPerView: 3,
                spaceBetween: 15,
              },
            }}
            modules={[Navigation]}
            navigation
            className="mySwiper"
          >
            <div className="px-10">
              {(business.testimonials || []).map((testimonial, index) => (
                <SwiperSlide key={index}>
                  <div className="max-w-sm mx-auto mt-2 my-2 p-4 h-[180px] bg-[#224F34] text-[#A49FAF] rounded-xl shadow-lg flex flex-col justify-between gap-4">
                    <p className="text-sm sm:text-base font-sans">
                      "
                      <TruncateString
                        inputString={testimonial.content}
                        maxLength={150}
                      />
                      "
                    </p>
                    <div className="text-right">
                      <h1 className="text-sm sm:text-base font-semibold">
                        - {testimonial.name}
                      </h1>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </div>
          </Swiper>
        </div>
      </AppLayout>
    </div>
  );
};

export default Reviews;
