import { Swiper, SwiperSlide } from "swiper/react";
import { Link, useParams } from "react-router-dom";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import BlogeMock from "./MockData/BlogeMock";
// import required modules
import { Autoplay, FreeMode, Navigation, Pagination } from "swiper/modules";
import AppLayout from "./AppLayout";
import TruncateString from "../../Components/TruncateString ";
import createSlug from "../../Components/createSlug";
 import "./cssFold/style7.css";
const Bloge = ({ business }) => {
  const { city, slug } = useParams();
  const citySlug = createSlug(city);
  return (
    <div className="mt-16 mb-16" id="blogs">
      <AppLayout>
        <h1 className="text-center enriqueta-medium font-semibold text-[30px] mb-6 text-white">
          Our Blogs
        </h1>

        <Swiper
          breakpoints={{
            340: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            700: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
          }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          freeMode={true}
          pagination={{
            clickable: true,
          }}
          modules={[FreeMode, Autoplay, Pagination, Navigation]}
          className="max-w-[90%] lg:max-w-[80%]"
        >
          <div>
            {(business.blogs || []).map((blog, index) => (
              <SwiperSlide key={index}>
                <div className="lg:w-[300px] w-[320px] sm:w-[220px] lg:h-[500px] h-[450px] sm:h-[400px] rounded-md border-2 border-[#FBBB17] bg-[#35322C]  lg:ml-0 sm:ml-0 gap-4 overflow-hidden">
                  <Link to={`/${citySlug}/${slug}/blogpg7/${blog.id}`}>
                    <img
                      src={"https://admin.bizzata.in/storage/" + blog.image}
                      alt={blog.title}
                      className="h-[200px]  lg:h-[210px] sm:h-[200px] w-full rounded-t-md object-cover"
                    />
                  </Link>
                  <div className="p-2">
                    <h1 className="pt-1 inline-flex enriqueta-medium lg:text-2xl text-2xl sm:text-xl font-bold  text-start text-white hover:underline">
                      <Link to={`/${citySlug}/${slug}/blogpg7/${blog.id}`}>
                        <TruncateString
                          inputString={blog.title}
                          maxLength={50}
                        />
                      </Link>
                    </h1>

                    <p className="mt-1 lg:text-[16px] text-[16px] enriqueta-medium sm:text-[12px] text-[#FFF] h-[100px] sm:h-[0px] text-start">
                      <TruncateString
                        inputString={blog.content}
                        maxLength={160}
                      />
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </div>
        </Swiper>
      </AppLayout>
    </div>
  );
};

export default Bloge;
