import React, { useState } from "react";
import AppLayout from "./AppLayout";
import axios from "axios";
import "./CSSTemp6/styles.css";
const Appoinment = ({ business }) => {
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    date: "",
    time: "",
    business_id: business.id,
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://admin.bizzata.in/api/business_appointment",
        formData
      );
      alert(response.data.message);
    } catch (error) {
      console.error("There was an error!", error);
    }
  };
  return (
    <AppLayout>
      <div className=" my-8 ">
        <div className=" ">
          <p className="text-[40px] font-bold text-[#326C77] text-center odor-mean-chey-regular ">
            Appointment
          </p>
          <form onSubmit={handleSubmit}>
            <div className=" pb-10  bg-[#B8DBE1] pt-6 rounded-lg">
              <div className="w-[90%] sm:w-[85%] lg:w-[80%]   sm:h-[100%]  items-center rounded-[10px]  lg:flex gap-2 justify-around mx-auto mt-[30px]  pb-20">
                <div className="">
                  <p className="font-bold text-[10px]  sm:text-[20px]  text-[#326C77] ">
                    Name
                  </p>
                  <input
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    placeholder="Enter your Name"
                    className="font-bold text-[13px]  sm:text-[18px] w-[100%]  h-[30px]  sm:h-[40px] lg:h-[40px] bg-[#fff] rounded-[5px] px-3 text-black"
                  />
                </div>
                <div className=" pt-4 lg:pt-0">
                  <p className="font-bold text-[10px] sm:text-[20px]  text-[#326C77] ">
                    Contact No.
                  </p>
                  <input
                    value={formData.mobile}
                    onChange={handleChange}
                    required
                    placeholder="Mobile no."
                    className="font-bold text-[13px]  sm:text-[18px]  w-[100%]  h-[30px] sm:h-[40px] lg:h-[40px] bg-[#fff] rounded-[5px] px-3 text-black"
                  />
                </div>
                <div className=" pt-4 lg:pt-0">
                  <p className="font-bold text-[10px] sm:text-[20px]  text-[#326C77]  ">
                    Date
                  </p>
                  <input
                    type="date"
                    placeholder="Date"
                    className="font-bold text-[13px]  sm:text-[18px]  w-[100%]  h-[30px] sm:h-[40px] lg:h-[40px] bg-[#fff] rounded-[5px] px-3 text-black"
                  />
                </div>
                <div className="pt-4 lg:pt-0">
                  <p className="font-bold text-[10px] sm:text-[20px] text-[#326C77] odor-mean">
                    Time
                  </p>
                  <input
                    type="time"
                    className="font-bold text-[13px] sm:text-[18px] w-full h-[30px] sm:h-[40px] lg:h-[40px] bg-white rounded-[5px] px-3 text-black"
                    name="time"
                    value={formData.time}
                    onChange={handleChange}
                    required
                  />
                </div>
                <button
                  type="submit"
                  className="bg-[#326C77] sm:w-[30%]  text-white rounded-md py-2 px-3 mt-7 font-medium"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </AppLayout>
  );
};

export default Appoinment;
