import React from "react";
import AppLayout from "./AppLayout";

import "./cssFold/style7.css";

const Hero = ({ business }) => {
  return (
    <AppLayout>
       <h1 className="hidden">{business.meta_title}</h1>
      <div className="flex md:gap-5 justify-between flex-wrap-reverse p-2 md:p-0">
        <div className="w-[700px]  md:mt-[150px] mt-4">
          <h2 className="enriqueta-medium text-[12px] sm:text-[16px] md:text-[20px]  text-white mb-4 ">
            “ {business.description} “
          </h2>
          <button className="border enriqueta-medium  border-white text-white py-2 px-4 rounded hover:bg-white hover:text-black transition duration-300">
            <a href="#enq">Let's Talk</a>
          </button>
        </div>

        <div className="mx-0 ">
          <div className="relative">
            <div className="absolute top-[-80px]  ">
              <div className="outline outline-4 p-2 text-white  ml-[20%] sm:ml-[25%] md:mx-[10%]">
                <img
                  src={`https://admin.bizzata.in/storage/${business.profile}`}
                  alt="beautique"
                  className=" h-[150px] w-[200px]"
                />
              </div>
            </div>
          </div>
          <div className="mt-[150px] md:mt-[140px] md:mb-4">
            <h1 className=" my-2 text-start ml-[10%] sm:ml-[20%] lg:ml-0 text-[26px] font-bold text-white enriqueta-medium ">
              {business.business_name}
            </h1>
            <h2 className="text-[20px] text-center ml-[8%] font-bold text-white pb-2 enriqueta-medium ">
              {business.name}
            </h2>
          </div>

          <div className="mx-[2%]  mt-[2%]    rounded-full flex flex-wrap px-4 py-2 gap-2 justify-center ">
            {(business.business_social_media_links || []).map(
              (linkData, index) => (
                <a
                  key={index}
                  href={linkData.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={`https://admin.bizzata.in/storage/${linkData.social_media_platform.logo}`}
                    alt={`${linkData.social_media_platform.platform_name} logo`}
                    className="h-[34px] w-[34px] border-8 border-[#FBBB17] rounded-full bg-[#FBBB17]"
                  />
                </a>
              )
            )}
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

export default Hero;
