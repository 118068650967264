import AppLayout from "./AppLayout";
import "./CSSTemp6/styles.css";
const Hero = ({ business }) => {
  return (
    <div className=" mt-6 bg-[#DDF1F9] rounded-xl ">
       <h1 className="hidden">{business.meta_title}</h1>
      <AppLayout>
        <div className="py-4 ">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mx-2 mb-8 ">
            <div className="col-span-1 mx-auto flex justify-center items-center">
              <img
                src={`https://admin.bizzata.in/storage/${business.profile}`}
                alt="circleimage"
                className="h-[180px] w-[180px] sm:h-[243px] sm:w-[243px] rounded-full mt-4"
              />
            </div>
            <div className="col-span-1 sm:col-span-2 lg:col-span-3 flex flex-col justify-center text-center sm:text-left">
              <h1 className="tracking-[1.44px] text-center sm:text-start font-bold text-[#326C77]  text-[35px] odor-mean">
                {business.business_name}
              </h1>
              <h2 className="text-[#326C77] text-center sm:text-start mt-4 text-[28px] font-bold odor-mean-chey-regular">
                {business.name}
              </h2>
              <h2 className="mt-1 text-[#326C77] text-start font-extrabold text-[14px] sm:text-[18px] mx-2 sm:mx-0 odor-mean">
                {business.description}
              </h2>
            </div>
          </div>
          <div className="mx-4 mt-6 sm:mt-10 lg:mt-8 rounded-full flex flex-wrap px-4 py-4 gap-4 bg-[#FFF] justify-center">
            {(business.business_social_media_links || []).map(
              (linkData, index) => (
                <a
                  key={index}
                  href={linkData.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={`https://admin.bizzata.in/storage/${linkData.social_media_platform.logo}`}
                    alt={`${linkData.social_media_platform.platform_name} logo`}
                    className="h-[34px] w-[34px]"
                  />
                </a>
              )
            )}
          </div>
        </div>
      </AppLayout>
    </div>
  );
};

export default Hero;
