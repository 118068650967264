import React, { useState } from "react";
import axios from "axios";
import AppLayout from "./AppLayout";

const Enqform = ({ business }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    feedback: "",
    mobile: "",
    business_id: business.id,
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://admin.bizzata.in/api/business_feedback",
        formData
      );
      alert(response.data.message);
      setFormData({
        name: "",
        email: "",
        mobile: "",
        feedback: "",
        business_id: business.id,
      });
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  const handleClearForm = () => {
    setFormData({
      name: "",
      email: "",
      feedback: "",
      business_id: business.id,
    });
  };

  return (
    <section id="inquryform " className="my-10">
      <div className="bg-[#B89C85] text-white h-auto mb-5 " id="enquiry">
        <div className="text-center font-semibold leading-5 mx-auto pt-10">
          <div className=" text-[40px] font-bold text-[#FFF] text-center mb-5">
            Enquiry <span className="not-italic">form</span>
          </div>
        </div>

        <div className="mt-14">
          <form onSubmit={handleSubmit}>
            <AppLayout>
              <div className="mb-4 bg-white w-[95%] mx-auto h-auto my-15 rounded-2xl sm:mx-5 sm:px-5">
                <div className="py-2 ">
                  <label
                    htmlFor="name"
                    className="block  font-semibold text-black  mx-10  lg:text-2xl text-lg nunito-sans-bold"
                  >
                    Name:<span className="text-red-500 text-2xl">&nbsp;*</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    className="w-[#400px]  mt-2 border-none rounded-md focus:outline-none focus:border-black-500 mx-10 text-black"
                    placeholder="Enter your Name"
                  />
                  <hr className="lg:w-[400px] w-[#200px]  mb-2 mx-10 h-[1px] lg:h-[2px] bg-[#D9D9D9]" />
                </div>
              </div>
              <div className="mb-4 w-[95%] mx-auto bg-white h-auto my-15 rounded-2xl sm:mx-5 sm:px-5">
                <div className="py-2 ">
                  <label
                    htmlFor="Email"
                    className="block  font-semibold text-black  mx-10  lg:text-2xl nunito-sans-bold text-lg"
                  >
                    Email:<span className="text-red-500 text-2xl">&nbsp;*</span>
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    className="w-[#400px]  mt-2 border-none rounded-md focus:outline-none focus:border-black-500 mx-10 text-black"
                    placeholder="Enter your Email."
                  />
                  <hr className="lg:w-[400px] w-[#200px]  mb-2 mx-10 h-[1px] lg:h-[2px] bg-[#D9D9D9]" />
                </div>
              </div>
              <div className="mb-4 w-[95%] mx-auto bg-white h-auto my-15 rounded-2xl sm:mx-5 sm:px-5">
                <div className="py-2 ">
                  <label
                    htmlFor="MobileNo"
                    className="block  font-semibold text-black  mx-10  lg:text-2xl text-lg  nunito-sans-bold"
                  >
                    Mobile:
                    <span className="text-red-500 text-2xl">&nbsp;*</span>
                  </label>
                  <input
                    type="tel"
                    id="mobile"
                    name="mobile"
                    value={formData.mobile}
                    onChange={handleChange}
                    className="w-[#400px]  mt-2 border-none rounded-md focus:outline-none focus:border-black-500 mx-10 text-black"
                    placeholder="Enter your Mobile No."
                    required
                  />
                  <hr className="lg:w-[400px] w-[#200px]  mb-2 mx-10 h-[1px] lg:h-[2px] bg-[#D9D9D9]" />
                </div>
              </div>
              <div className="mb-4 w-[95%] mx-auto bg-white h-auto my-15 rounded-2xl sm:mx-5 sm:px-5">
                <div className="py-2 ">
                  <label
                    htmlFor="Message"
                    className="block  font-semibold text-black  mx-10  lg:text-2xl text-lg nunito-sans-bold"
                  >
                    Message:
                    <span className="text-red-500 text-2xl">&nbsp;*</span>
                  </label>
                  <textarea
                    value={formData.feedback}
                    onChange={handleChange}
                    name="feedback"
                    className="w-[#400px]  mt-2 border-none rounded-md focus:outline-none focus:border-black-500 mx-10 text-black"
                    placeholder="Enter your Message."
                    required
                  />
                  <hr className="lg:w-[400px] w-[#200px]  mb-2 mx-10 h-[1px] lg:h-[2px] bg-[#D9D9D9]" />
                </div>
              </div>
            </AppLayout>
            <div className="">
              <AppLayout>
                <div className="grid grid-cols-2 gap-2 sm:gap-1">
                  <div className=" p-4 rounded-md">
                    <div className="sm:ml-10">
                      <button
                        type="submit"
                        className="bg-[#A98C74] nunito-sans-bold text-white px-4 py-2 rounded-md  focus:outline-none focus:shadow-outline-blue w-28 h-10"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                  <div className=" p-4 rounded-md justify-items-end lg:ml-72 sm:ml-10">
                    <div className=" lg:mx-20 sm:mx-5">
                      <button
                        type="button"
                        onClick={handleClearForm}
                        className="  font-bold  px-4 py-2 rounded-md nunito-sans-bold text-white  hover:text-white focus:outline-none focus:shadow-outline-blue"
                      >
                        Clear form
                      </button>
                    </div>
                  </div>
                </div>
              </AppLayout>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Enqform;
