import { Swiper, SwiperSlide } from "swiper/react";
import { Link, useParams } from "react-router-dom";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { FaHeart, FaEllipsisH } from "react-icons/fa";
// import required modules
import { Autoplay, FreeMode, Navigation, Pagination } from "swiper/modules";
import AppLayout from "./AppLayout";
import TruncateString from "../../Components/TruncateString ";
import createSlug from "../../Components/createSlug";

const Bloge = ({ business }) => {
  const { city, slug } = useParams();
  const citySlug = createSlug(city);
  return (
    <div className="my-10" id="blogs">
      <AppLayout>
        <h1 className="text-center text-[30px] font-bold text-[#FAFF03] philosopher-regular mb-6 ">
          Our Blogs
        </h1>

        <Swiper
          breakpoints={{
            340: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            700: {
              slidesPerView: 1,
              spaceBetween: 30,
            },
          }}
          autoplay={{
            delay: 5500,
            disableOnInteraction: false,
          }}
          freeMode={true}
          pagination={{
            clickable: true,
          }}
          modules={[FreeMode, Autoplay, Pagination, Navigation]}
          className="max-w-[90%] lg:max-w-[80%]"
        >
          <div>
            {(business.blogs || []).map((blog, index) => (
              <SwiperSlide key={index}>
                <div className="flex flex-row justify-center items-center text-white">
                  <div className="bg-black rounded-lg overflow-hidden shadow-lg flex flex-col sm:flex-row w-[95%] max-w-4xl p-1 sm:p-4 h-[450px] sm:h-auto ">
                    <Link to={`/${citySlug}/${slug}/blogpg1/${blog.id}`}>
                    <img
                      src={"https://admin.bizzata.in/storage/" + blog.image}
                      alt={blog.title}
                      className="w-full sm:w-[100px] md:w-[200px] h-48 sm:h-auto md:h-auto object-cover rounded-lg border-2 border-yellow-500"
                    />
                    </Link>
                    <div className="flex flex-col justify-between p-4 md:w-2/3">
                      <div>
                        <h2 className="text-xl font-bold mb-2">
                          <Link
                            to={`/${citySlug}/${slug}/blogpg1/${blog.id}`}
                          >
                            <TruncateString
                              inputString={blog.title}
                              maxLength={40}
                            />{" "}
                            ...
                          </Link>
                        </h2>
                        <p className="text-gray-400 mb-4">
                          <Link>
                            <TruncateString
                              inputString={blog.content}
                              maxLength={150}
                            />{" "}
                            ...
                          </Link>
                        </p>
                      </div>
                      <div className="flex justify-end space-x-4 mt-2  md:mt-0">
                        <FaEllipsisH className="text-white cursor-pointer" />
                        <FaHeart className="text-white cursor-pointer" />
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </div>
        </Swiper>
      </AppLayout>
    </div>
  );
};

export default Bloge;
