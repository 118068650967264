import React from "react";
import AppLayout from "./AppLayout";
import { Link, useParams } from "react-router-dom";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper/modules";
import createSlug from "../../Components/createSlug";
import TruncateString from "../../Components/TruncateString ";
 import "./CssTemp/styles.css";
const Categories = ({ business }) => {
  const { city, slug } = useParams();
  const citySlug = createSlug(city);

  return (
    <div className=" w-full" id="service">
      <AppLayout>
        <h2 className="font-bold text-[32px] poppins-bold py-4 text-center  text-black ">
          Service
        </h2>
        <Swiper
          breakpoints={{
            340: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            540: {
              slidesPerView: 2,
              spaceBetween: 5,
            },
            700: {
              slidesPerView: 2,
              spaceBetween: 15,
            },
            900: {
              slidesPerView: 3,
              spaceBetween: 5,
            },
            1000: {
              slidesPerView: 4,
              spaceBetween: 5,
            },
          }}
          modules={[Navigation]}
          navigation
          className="mySwiper"
        >
          <div className="flex flex-wrap justify-center gap-4 mx-auto">
            {(business.services || []).map((service, index) => (
              <SwiperSlide key={index} className="flex justify-center">
                <div className="w-[90%] sm:w-[100%] md:w-[100%] lg:w-[100%] xl:w-[100%] h-[430px] bg-[#F1F1F1] rounded-md border shadow-xl gap-2 mx-2 my-4 overflow-hidden">
                  <Link to={`/${citySlug}/${slug}/servicepage10/${service.id}`}>
                    <img
                      src={"https://admin.bizzata.in/storage/" + service.image}
                      alt={service.service_name}
                      className="h-48 w-full object-cover cursor-pointer"
                    />
                  </Link>
                  <div className="py-4 px-2">
                    <h1 className="text-[22px] poppins-regular font-bold tracking-0.5 cursor-pointer  text-black text-start">
                      <Link
                        to={`/${citySlug}/${slug}/servicepage10/${service.id}`}
                      >
                        <TruncateString
                          inputString={service.service_name}
                          maxLength={70}
                        />
                        ..
                      </Link>
                    </h1>
                    <p className="pt-4 text-[#010101] text-[16px] poppins-regular  tracking-0.5  text-start">
                      <TruncateString
                        inputString={service.description}
                        maxLength={150}
                      />
                      ..
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </div>
        </Swiper>
      </AppLayout>
    </div>
  );
};

export default Categories;
