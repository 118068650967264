import React, { useState } from "react";
import AppLayout from "./AppLayout";
import bus from "./assets/busnes.png"
import "./style3.css"
const BussinessHours = ({ business }) => {
  function convertTo12HourFormat(time) {
    let [hours, minutes] = time.split(":");
    hours = parseInt(hours, 10);
    let period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert '0' hours to '12'
    return `${hours}:${minutes} ${period}`;
  }
  return (
    <AppLayout>
      <div className="text-white p-10">
        <h1 className="text-center text-4xl mb-10 piazzolla-uniquifier font-semibold">Business Hours</h1>
        <div className="flex py-4 sm:gap-2 lg:gap-6 justify-center md:justify-space flex-wrap md:mx-[10%] ">
          {(business.business_hours || []).map((dayHour, index) => (
            <div
              key={index}
              className={`bg-[#920C26] rounded-3xl p-6 flex items-center space-x-4 my-2 md:my-0 border-2 border-[#FFF] h-[80px] w-[350px] ${
                index % 2*2 === 0 ? "w-[500px]" : "w-[300px]"
              }`}
            >
              <div className="flex-shrink-0">
                <img src={bus} alt="" className="h-6 w-6 text-white" />
              </div>
              <div>
                <div className="font-medium"> {dayHour.day}</div>
                <div className="text-lg font-bold flex">
                  {convertTo12HourFormat(dayHour.open_time)}{" "}
                  {convertTo12HourFormat(dayHour.close_time)}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </AppLayout>
  );
};

export default BussinessHours;
