import React, { useState } from "react";
import AppLayout from "./AppLayout";
import axios from "axios";
import "./TempCss/styles8.css";
const Appoinment = ({ business }) => {
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    date: "",
    time: "",
    business_id: business.id,
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://admin.bizzata.in/api/business_appointment",
        formData
      );
      alert(response.data.message);
    } catch (error) {
      console.error("There was an error!", error);
    }
  };
  return (
    <AppLayout>
      <p className="text-center enriqueta-semibold font-bold text-[40px] text-[#010101] mt-2">
        Appointment
      </p>
      <div className="bg-[#224F34] mb-10 rounded pt-4">
        <form onSubmit={handleSubmit}>
          <div className="w-[90%] sm:w-[85%] lg:w-[80%]   sm:h-[100px]  items-center rounded-[10px]  lg:flex gap-2 justify-around mx-auto mt-[30px]  pb-20">
            <div className="">
              <p className="font-bold text-[10px] enriqueta-semibold sm:text-[20px]  text-[#fff] ">
                Name
              </p>
              <input
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                type="text"
                placeholder="name"
                className="font-bold text-[13px]  sm:text-[18px] w-[100%]  h-[30px]  sm:h-[40px] lg:h-[40px] bg-[#fff] rounded-[5px] px-3 text-black"
              />
            </div>
            <div className=" pt-4 lg:pt-0">
              <p className="font-bold text-[10px] sm:text-[20px] enriqueta-semibold text-[#fff] ">
                Contact No.
              </p>
              <input
                name="mobile"
                value={formData.mobile}
                onChange={handleChange}
                required
                type="Text"
                placeholder="Mobile no."
                className="font-bold text-[13px]  sm:text-[18px]  w-[100%]  h-[30px] sm:h-[40px] lg:h-[40px] bg-[#fff] rounded-[5px] px-3 text-black"
              />
            </div>
            <div className=" pt-4 lg:pt-0">
              <p className="font-bold text-[10px] sm:text-[20px] enriqueta-semibold text-[#fff] ">
                Date
              </p>
              <input
                type="date"
                placeholder="Date"
                name="date"
                value={formData.date}
                onChange={handleChange}
                required
                className="font-bold text-[13px]  sm:text-[18px]  w-[100%]  h-[30px] sm:h-[40px] lg:h-[40px] bg-[#fff] rounded-[5px] px-3 text-black"
              />
            </div>
            <div className="pt-4 lg:pt-0">
              <p className="font-bold text-[10px] sm:text-[20px] enriqueta-semibold text-white">
                Time
              </p>
              <input
                type="time"
                className="font-bold text-[13px] sm:text-[18px] w-full h-[30px] sm:h-[40px] lg:h-[40px] bg-white rounded-[5px] px-3 text-black"
                name="time"
                value={formData.time}
                onChange={handleChange}
                required
              />
            </div>
            <button
              type="submit"
              className="bg-[#6BC785] sm:w-[30%]  text-white enriqueta-semibold rounded-md py-2 px-3 mt-7 font-medium"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </AppLayout>
  );
};

export default Appoinment;
