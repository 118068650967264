import React from "react";
import "./TempCss/Style.css";
import AppLayout from "./AppLayou";
const IntroCard = ({ business }) => {
  return (
    <div className="">
       <h1 className="hidden">{business.meta_title}</h1>
      <div className="h-full w-auto  bg-[#14151B] py-4">
        <AppLayout>
          <h1 className="font-[800] text-[#FFFFFF] text-[32px] md:text-[50px] pt-8  ml-8 lg:ml-24 nunito-sans-uniquifier">
            {business.business_name}
          </h1>
          <p className="font-[500]  lg:ml-24 text-[16px] lg:text-[32px] text-[#FFFFFF] ml-8 mt-[12px] nunito-sans-uniquifier">
            {business.name}
          </p>
          <p className="font-[400]  text-[14px]  lg:text-[20px] text-[#FFFFFF] ml-8 mt-[12px] pr-6 lg:ml-24 nunito-sans-uniquifier">
            “ {business.description} “
          </p>
          <a
            href="#inquiryform"
            className="font-[600] cursor-pointer text-[14px] lg:text-[24px] text-[#FFFFFF] nunito-sans-uniquifier w-[95px] lg:w-[181px] h-[40px] lg:h-[68px] rounded-[40px] bg-[#27282A] mt-2 lg:mt-[40px] ml-[24px] flex items-center justify-center lg:ml-24"
          >
            Let’s Talk
          </a>
        </AppLayout>
      </div>
      <div className="py-8 bg-[#353956]">
        <AppLayout>
          <h2 className="font-[500] text-[20px] abhaya-libre-semibold lg:text-[48px] text-[rgb(255,255,255)] ">
            Our Social
          </h2>
          <div className=" rounded-full flex flex-wrap px-4 py-4 gap-4 md:gap-8 justify-center md:justify-start  mt-2 md:ml-[10%]">
            {/* <div className="flex gap-2"> */}
            {(business.business_social_media_links || []).map(
              (linkData, index) => (
                <a
                  key={index}
                  href={linkData.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={`https://admin.bizzata.in/storage/${linkData.social_media_platform.logo}`}
                    alt={`${linkData.social_media_platform.platform_name} logo`}
                    className="md:h-[50px] md:w-[50px] h-[34px] w-[34px]"
                  />
                </a>
              )
            )}
          </div>
        </AppLayout>
      </div>
    </div>
  );
};

export default IntroCard;
