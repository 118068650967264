import React from "react";
import AppLayout from "./AppLayout";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "./css/styles4.css"
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination } from "swiper/modules";
import { Link } from "react-router-dom";
import TruncateString from "../../Components/TruncateString ";
import createSlug from "../../Components/createSlug";
import { useParams } from "react-router-dom";
const Services = ({ business }) => {
  const { city, slug } = useParams();
  const citySlug = createSlug(city);
  return (
    <div className="mt-8" id="services">
      <hr className="h-1 w-full self-stretch bg-[#EBE2D9] shadow-xs drop-shadow-2xl" />
      <AppLayout>
        <div className="sm:my-10 my-5">
          <h2 className="text-[48px] font-bold text-[#9F7161] text-center mb-5 odor-mean-chey-regular">
            Services
          </h2>
          <Swiper
            breakpoints={{
              340: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              540: {
                slidesPerView: 2,
                spaceBetween: 15,
              },
              700: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              900: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
            }}
            loop={true}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            className="mySwiper"
          >
            {(business.services || []).map((service, index) => (
              <SwiperSlide key={index}>
                <div className="w-[90%] sm:w-[100%] lg:w-[100%] mx-auto shadow-xs h-[450px] bg-[#E3D1C3] rounded-[40px] flex flex-col items-center p-2 sm:p-2 mb-4 overflow-hidden">
                  <div>
                    <Link to={`/${citySlug}/${slug}/servicepg4/${service.id}`}>
                      <img
                        src={
                          "https://admin.bizzata.in/storage/" + service.image
                        }
                        alt={service.service_name}
                        className="h-[180px] w-full rounded-tr-[40px] rounded-tl-[40px] object-cover"
                      />
                    </Link>
                    <h1 className="text-lg mt-3 font-bold  tracking-[0.96px] text-[14px] sm:text-[24px] text-white text-start odor-mean-chey-regular">
                     <Link to={`/${citySlug}/${slug}/servicepg4/${service.id}`}>
                        <TruncateString
                          inputString={service.service_name}
                          maxLength={70}
                        />
                      </Link>
                    </h1>
                    <p className="text-[16px]  text-[#9F7161] nunito-sans-bold tracking-tight pb-6">
                     <Link to={`/${citySlug}/${slug}/servicepg4/${service.id}`}>
                        <TruncateString
                          inputString={service.description}
                          maxLength={135}
                        />
                      </Link>
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </AppLayout>
    </div>
  );
};

export default Services;
