import React from "react";

const Header = ({ business }) => {
  return (
    <div className=" " id="home">
      <img
        src={`https://admin.bizzata.in/storage/${business.banner}`}
        alt="banner3"
        srcset=""
        className="logine"
      />
    </div>
  );
};

export default Header;
