import React from "react";
import AppLayout from "./AppLayout";
 import "./CssTemp/styles.css";
const BussinessHours = ({ business }) => {
   function convertTo12HourFormat(time) {
    let [hours, minutes] = time.split(":");
    hours = parseInt(hours, 10);
    let period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert '0' hours to '12'
    return `${hours}:${minutes} ${period}`;
  }
  return (
    <div className="bg-[#F3F5F7]">
      <AppLayout>
        <h1 className="lg:my-4 my-4 sm:my-5 poppins-bold lg:font-bold text-[30px] font-bold lg:text-[28px] sm:text-[30px] text-black text-center">
          Business Hours
        </h1>
      </AppLayout>
       <div className="flex justify-center w-full lg:w-auto mx-[2%]">
            <div className="border-2 border-[#38CB89] rounded-2xl bg-[#000000] w-full max-w-[600px] px-4 sm:px-8 py-5 mb-2">
              <table className="text-white w-full">
                <thead>
                  <tr className="py-2 border-b border-white pb-2">
                    <th className="capitalize text-lg sm:text-xl font-bold poppins-bold text-left text-[#38CB89]">
                      Day
                    </th>
                    <th className="capitalize text-lg sm:text-xl font-bold poppins-bold text-left text-[#38CB89]">
                      Opening 
                    </th>
                    <th className="capitalize text-lg sm:text-xl font-bold poppins-bold text-left text-[#38CB89]">
                      Close 
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {(business.business_hours || []).map((dayHour, index) => (
                    <tr key={index} className="py-2 mt-1">
                      <td className="text-lg sm:text-xl font-normal capitalize poppins-bold text-[#38CB89]">
                        {dayHour.day}
                      </td>
                      <td className="text-lg sm:text-xl font-normal capitalize poppins-bold pt-1 text-[#38CB89]">
                        &nbsp; {convertTo12HourFormat(dayHour.open_time)}
                      </td>
                      <td className="text-lg sm:text-xl font-normal capitalize poppins-bold text-[#38CB89]">
                        {convertTo12HourFormat(dayHour.close_time)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
    </div>
  );
};

export default BussinessHours;
