import React from "react";
import { useSwiper } from "swiper/react";

const SwiperButon = () => {
  const swiper = useSwiper();
  return (
    <div className="btn-nav-btns">
      <button onClick={() => swiper.slidePrev()}>Prev</button>
      <button onClick={() => swiper.slideNext()}>Next</button>
    </div>
  );
};

export default SwiperButon;
