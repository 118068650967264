import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Whitebox from "./Whitebox";
import IntroCard from "./IntroCard";
import ContactUs from "./ContactUs";
import Galleary from "./Galleary";
import Offers from "./Offers";
import Certificates from "./Certificates";
import Business from "./Business";
import Blogs from "./Blogs";
import Appoinment from "./Appoinment";
import Form from "./Form";
import ProductCard from "./ProductCard";
import Service from "./Service";
import Review from "./Review";
import Feedbackform from "./Feedbackform";
import { Helmet } from "react-helmet";

import { BusinessProvider } from "../../context/BusinessContext";
const Temp9 = ({ business }) => {
  if (!business) {
    return <div>Loading...</div>;
  }
  return (
    <BusinessProvider business={business}>
      <Helmet>
        <title>{business.meta_title}</title>
        <link rel="icon" href="./logo.ico" />
         <meta name="robots" content="index, follow"></meta>
        <meta name="description" content={business.meta_keyword} />
        <meta name="keywords" content={business.meta_description} />
        
      </Helmet>
      <div>
        <Navbar business={business} />
        <Whitebox business={business} />
        <IntroCard business={business} />
        <ContactUs business={business} />
        <Service business={business} />
        <ProductCard business={business} />

        <Offers business={business} />
        <Blogs business={business} />
        <Galleary business={business} />
        <Business business={business} />
        <Certificates business={business} />
        <Feedbackform business={business} />

        <Review business={business} />
        <Appoinment business={business} />
        <Form business={business} />
        <Footer business={business} />
      </div>
    </BusinessProvider>
  );
};

export default Temp9;
