import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "./CssFold/style5.css" 
import { Navigation } from "swiper/modules";
import AppLayout from "./AppLayout";
import TruncateString from "../../Components/TruncateString ";
const Review = ({ business }) => {
  return (
    <div className=" py-10">
      <AppLayout>
        <h6 className="text-center text-[40px] font-bold text-white roboto p-6">
          Our Clients Says
        </h6>
        <div>
          {" "}
          <Swiper
            breakpoints={{
              340: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
              540: {
                slidesPerView: 2,
                spaceBetween: 5,
              },
              750: {
                slidesPerView: 2,
                spaceBetween: 15,
              },
              1000: {
                slidesPerView: 3,
                spaceBetween: 15,
              },
            }}
            modules={[Navigation]}
            navigation
            className="mySwiper"
          >
            {(business.testimonials || []).map((testimonial, index) => (
              <SwiperSlide key={index}>
                <div className="w-[90%] sm:max-w-md lg:max-w-lg mx-auto mt-2 mb-4 p-4 sm:p-6 lg:p-8 bg-[#031A25] text-[#A49FAF] rounded-xl shadow-lg flex flex-col justify-between h-auto">
                  <h6 className="text-[16px] font-sans roboto">
                    "
                    <TruncateString
                      inputString={testimonial.content}
                      maxLength={150}
                    />
                    "
                  </h6>
                  <div className="text-right mt-4">
                    <h6 className="text-sm sm:text-base lg:text-lg font-semibold">
                      - {testimonial.name}
                    </h6>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </AppLayout>
    </div>
  );
};

export default Review;
