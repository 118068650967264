import React from "react";

const Home = ({ business }) => {
  return (
    <>
      <div className="backgroundimage drop-shadow-2xl hidden lg:block mt-[-25%] sm:mt-[-10%]">
        <img
          src={`https://admin.bizzata.in/storage/${business.banner}`}
          alt="banner3"
          srcset=""
          className="logine"
        />
      </div>
      <div className="bgForMobile drop-shadow-2xl block lg:hidden mt-[-25%] sm:mt-[-10%]">
        <img
          src={`https://admin.bizzata.in/storage/${business.banner}`}
          alt="banner3"
          srcset=""
          className="logine"
        />
      </div>
    </>
  );
};

export default Home;
