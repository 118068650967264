import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Link, useParams } from "react-router-dom";
import AppLayout from "./AppLayou"; // Correct the path if needed
import createSlug from "../../Components/createSlug";
import TruncateString from "../../Components/TruncateString ";
import "./TempCss/Style.css";
const ProductCard = ({ business }) => {
  const { city, slug } = useParams();
  const citySlug = createSlug(city);
  return (
    <div className="w-full bg-[#353956] " id="product">
      <AppLayout>
        <h2 className="font-bold text-[35px] py-10 text-center nunito-sans-uniquifier  text-[#F1F1F1]">
          Products
        </h2>
        <Swiper
          breakpoints={{
            340: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            540: {
              slidesPerView: 2,
              spaceBetween: 5,
            },
            700: {
              slidesPerView: 2,
              spaceBetween: 15,
            },
            900: {
              slidesPerView: 3,
              spaceBetween: 5,
            },
            1000: {
              slidesPerView: 4,
              spaceBetween: 5,
            },
          }}
          modules={[Navigation]}
          navigation
          className="mySwiper"
        >
          <div className="flex flex-wrap gap-4 justify-center  ">
            {(business.products || []).map((product, index) => (
              <SwiperSlide key={index} className="flex justify-center">
                <div className="w-[98%] sm:w-[90%] h-[420px] bg-white rounded-md border shadow-xl gap-4 mx-4 sm:mx-auto   overflow-hidden ">
                  <Link to={`/${citySlug}/${slug}/productpg9/${product.id}`}>
                    <img
                      src={"https://admin.bizzata.in/storage/" + product.image}
                      alt={product.product_name}
                      className="h-48 w-full sm:h-[200px] lg:h-[240px] p-[5px] rounded-xl object-cover mx-auto border-black border-2 bg-[#555454]"
                    />
                  </Link>
                  <div className="p-2 ">
                    <h1 className=" font-bold nunito-sans-uniquifier cursor-pointer text-[20px]  text-start text-black mx-auto">
                      <Link
                        to={`/${citySlug}/${slug}/productpg9/${product.id}`}
                      >
                        <TruncateString
                          inputString={product.product_name}
                          maxLength={20}
                        />
                        ..
                      </Link>
                    </h1>
                    <p className="mt-1  nunito-sans-uniquifier text-[#010101] text-[15px]  font-[500]  mx-auto ">
                      <TruncateString
                        inputString={product.description}
                        maxLength={120}
                      />
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </div>
        </Swiper>
      </AppLayout>
    </div>
  );
};

export default ProductCard;
