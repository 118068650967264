import React, { useState } from "react";
import AppLayout from "./AppLayout";
import axios from "axios";
import mail from "./assits/Mail.png";
import phone from "./assits/Phone.png";
import nots from "./assits/Note.png";
import "./TempCss/styles8.css";
const Busapp = ({ business }) => {
  function convertTo12HourFormat(time) {
    let [hours, minutes] = time.split(":");
    hours = parseInt(hours, 10);
    let period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert '0' hours to '12'
    return `${hours}:${minutes} ${period}`;
  }
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    feedback: "",
    business_id: business.id,
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://admin.bizzata.in/api/business_feedback",
        formData
      );
      alert(response.data.message);
    } catch (error) {
      console.error("There was an error!", error);
    }
    setFormData({
      name: "",
      email: "",
      mobile: "",
      feedback: "",
      business_id: business.id,
    });
  };

  return (
    <div className="mb-24" id="enq">
      <AppLayout>
        <div className="flex flex-col lg:flex-row justify-center items-center lg:items-start my-4 gap-8 lg:gap-16 mx-4 lg:mx-[10%]">
          <div className="lg:mt-[150px] w-full lg:w-1/2">
            <h2 className="text-center text-[32px] enriqueta-semibold font-bold mb-4 text-black">
              BUSINESS HOURS
            </h2>
            <div className="bg-[#FFF] border-2 border-[#224F34] text-[#224F34] p-6 rounded-lg shadow-lg w-full sm:w-[400px] sm:h-[100%] gap-2 mx-auto">
              <table className="w-full">
                <tbody>
                  {(business.business_hours || []).map((dayHour, index) => (
                    <tr
                      key={index}
                      className="border-b border-gray-700 last:border-none"
                    >
                      <td className="py-1 sm:pb-2 enriqueta-semibold font-bold text-[18px]">
                        {dayHour.day}
                      </td>
                      <td className="py-1 sm:pb-2 enriqueta-semibold font-bold text-[18px]">
                        {convertTo12HourFormat(dayHour.open_time)}
                      </td>
                      <td className="py-1 sm:pb-2 enriqueta-semibold font-bold text-[18px]">
                        {convertTo12HourFormat(dayHour.close_time)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="w-full lg:w-1/2 h-[400px]">
            <h2 className="text-center text-[32px] enriqueta-semibold font-bold mb-4 text-black">
              Make an Enquiry
            </h2>
            <div className="bg-[#224F34] border border-[#FBBB17] text-white p-6 rounded-lg shadow-lg w-full sm:w-[400px] sm:h-[500px] mx-auto">
              <form onSubmit={handleSubmit}>
                <div className="flex items-center border border-[#FFF] rounded-lg px-2 py-2 mb-8 mt-8">
                  <svg
                    className="h-6 w-6 text-white mr-3"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 12c2.209 0 4-1.791 4-4s-1.791-4-4-4-4 1.791-4 4 1.791 4 4 4z"></path>
                    <path d="M12 14c-4.418 0-8 2.239-8 5v3h16v-3c0-2.761-3.582-5-8-5z"></path>
                  </svg>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    placeholder="Enter Your Name"
                    className="bg-transparent flex-1 outline-none text-white"
                  />
                </div>
                <div className="flex items-center border border-[#FFF] rounded-lg px-2 py-2 mb-8 mt-8">
                  <img
                    src={mail}
                    alt=""
                    className="h-6 w-6 text-yellow-500 mr-3 bg-yellow"
                  />
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    placeholder="Enter Your Mail"
                    className="bg-transparent flex-1 outline-none text-white"
                  />
                </div>
                <div className="flex items-center border border-[#FFF] rounded-lg px-2 py-2 mb-8 mt-8">
                  <img
                    src={phone}
                    alt=""
                    className="h-6 w-6 text-yellow-500 mr-3 bg-yellow"
                  />
                  <input
                    type="tel"
                    id="mobile"
                    name="mobile"
                    value={formData.mobile}
                    onChange={handleChange}
                    required
                    placeholder="Enter Your Contact"
                    className="bg-transparent flex-1 outline-none text-white"
                  />
                </div>
                <div className="flex items-center border border-[#FFF] rounded-lg px-2 py-2 mb-2 mt-8">
                  <img
                    src={nots}
                    alt=""
                    className="h-6 w-6 text-yellow-500 mr-3 bg-yellow"
                  />
                  <textarea
                    id="feedback"
                    name="feedback"
                    value={formData.feedback}
                    onChange={handleChange}
                    placeholder="Some Text"
                    className="bg-transparent flex-1 outline-none text-white"
                  >
                    {formData.feedback}
                  </textarea>
                </div>

                <button
                  type="submit"
                  className="w-full bg-[#6BC785] text-black font-bold py-2 rounded-lg mt-4 enriqueta-medium"
                >
                  MAKE AN APPOINTMENT
                </button>
              </form>
            </div>
          </div>
        </div>
      </AppLayout>
    </div>
  );
};

export default Busapp;
