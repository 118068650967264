import React, { useState } from "react";
import AppLayout from "./AppLayout";
import "./CssFold/style5.css"
const BussinessHours = ({ business }) => {
  function convertTo12HourFormat(time) {
    let [hours, minutes] = time.split(":");
    hours = parseInt(hours, 10);
    let period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert '0' hours to '12'
    return `${hours}:${minutes} ${period}`;
  }
  return (
    <div className="">
      <AppLayout>
        <h6 className="text-center text-[40px] font-bold text-white roboto my-4">
          Business Hours
        </h6>
      </AppLayout>

      <div className="flex justify-center items-center py-6">
        <div className="w-full flex items-center">
          <hr className="flex-grow h-0.5 sm:h-1 bg-white my-4" />
          <div className="sm:p-4 rounded-[12px] mx-2 sm:mx-4">
            <div className="bg-brown-500 text-white sm:p-4 rounded-lg text-center w-[280px] sm:w-[400px] lg:w-[600px] bg-[#AA6141] pb-4">
              <table className="w-full">
                <tbody>
                  {(business.business_hours || []).map((dayHour, index) => (
                    <tr key={index} className="my-1 w-full text-lg font-bold">
                      <td className="py-1 roboto">{dayHour.day}</td>
                      <td className="py-1 roboto">
                        {dayHour.open_time && dayHour.close_time ? (
                          <>
                            {convertTo12HourFormat(dayHour.open_time)}{" "}
                            &nbsp;&nbsp;
                            {convertTo12HourFormat(dayHour.close_time)}
                          </>
                        ) : (
                          "CLOSED"
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <hr className="flex-grow h-0.5 sm:h-1 bg-white my-4" />
        </div>
      </div>
    </div>
  );
};

export default BussinessHours;
