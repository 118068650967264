import React from "react";
import { useSwiper } from "swiper/react";

import "./CSSTemp6/styles.css";
const SwiperButon = () => {
  const swiper = useSwiper();
  return (
    <div className="swiper-btns">
      <button onClick={() => swiper.slidePrev()}>Prev</button>
      <button onClick={() => swiper.slideNext()}>Next</button>
    </div>
  );
};

export default SwiperButon;
