import React from "react";
import bus from "./assets/busnes.png"
const Busness = ({ business }) => {
  function convertTo12HourFormat(time) {
    let [hours, minutes] = time.split(":");
    hours = parseInt(hours, 10);
    let period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert '0' hours to '12'
    return `${hours}:${minutes} ${period}`;
  }
  return (
    <div className=" mt-10">
      <h2 className=" text-[40px] font-bold text-[#9F7161] text-center mb-5">
        Business Hours
      </h2>
      <div className="text-white md:p-10">
        <div className="flex py-4 sm:gap-2 lg:gap-6 justify-center md:justify-space flex-wrap md:mx-[10%] ">
          {(business.business_hours || []).map((dayHour, index) => (
            <div
              key={index}
              className="bg-[#B89C85] rounded-full p-6 flex items-center space-x-4 my-2 md:my-0 border-2 border-[#FFF] h-[80px] w-[350px] sm:w-[300px] lg:w-[450px]
               "
            >
              <div className="flex-shrink-0">
                <img src={bus} alt="" className="h-6 w-6 text-white" />
              </div>
              <div>
                <div className="font-medium"> {dayHour.day}</div>
                <div className="text-lg font-bold flex">
                  {convertTo12HourFormat(dayHour.open_time)}{" "}
                  {convertTo12HourFormat(dayHour.close_time)}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Busness;
