import Fredany from "./template2/index";
import Temp1 from "./templete1/index";
import Temp3 from "./template3/index";
import Temp5 from "./templete5/index";
import Temp6 from "./templete6/index";
import Temp7 from "./templete7/index";
import Temp4 from "./templete4/index";
import Temp8 from "./templete8/index";
import Temp9 from "./templete9/index";
import Temp10 from "./templete10/index";

const templateMap = {
  template1: Temp3,
  template2: Fredany,
  template3: Temp4,
  template4: Temp5,
  template5: Temp1,
  template6: Temp6,
  template7: Temp7,
  template8: Temp8,
  template9: Temp9,
  template10: Temp10,
};

export default templateMap;
