import React, { useState } from "react";
import AppLayout from "./AppLayou";
import axios from "axios";
 import "./TempCss/Style.css";
const Appoinment = ({ business }) => {
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    date: "",
    time: "",
    business_id: business.id,
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://admin.bizzata.in/api/business_appointment",
        formData
      );
      alert(response.data.message);
      setFormData({
        name: "",
        mobile: "",
        date: "",
        time: "",
        business_id: business.id,
      });
    } catch (error) {
      console.error("There was an error!", error);
    }
  };
  return (
    <div className="bg-[#A5A8C1] py-5">
      <div className="">
        <AppLayout>
          <p className="text-center abhaya-libre-semibold font-bold text-[35px] items-center pt-[20px]  text-[#F1F1F1] mt-2">
            Appointment{" "}
            <span className="text-[38px] font-normal italic">Section</span>
          </p>
          <hr className="w-16 sm:w-32 mx-auto h-1 lg:h-1 bg-[#F0F0F0] mt-1" />
          <form onSubmit={handleSubmit}>
            <div className="bg-[#353956] mb-5 rounded pt-4 mt-4">
              <div className="w-[90%] sm:w-[85%] lg:w-[80%]  h-full  items-center rounded-[10px]  lg:flex gap-2 justify-around mx-auto mt-[30px]  pb-20">
                <div className="">
                  <p className="font-bold text-[10px]  sm:text-[20px]  text-[#fff] abhaya-libre-semibold ">
                    Name
                  </p>
                  <input
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    type="text"
                    placeholder="name"
                    className="font-bold text-[13px]  sm:text-[18px] w-[100%]  h-[30px]  sm:h-[40px] lg:h-[40px] bg-[#fff] rounded-[5px] px-3 text-black"
                  />
                </div>
                <div className=" pt-4 lg:pt-0">
                  <p className="font-bold text-[10px] sm:text-[20px]  text-[#fff]  abhaya-libre-semibold">
                    Contact No.
                  </p>
                  <input
                    type="Text"
                    placeholder="Mobile no."
                    name="mobile"
                    value={formData.mobile}
                    onChange={handleChange}
                    required
                    className="font-bold text-[13px]  sm:text-[18px]  w-[100%]  h-[30px] sm:h-[40px] lg:h-[40px] bg-[#fff] rounded-[5px] px-3 text-black"
                  />
                </div>
                <div className=" pt-4 lg:pt-0">
                  <p className="font-bold text-[10px] sm:text-[20px]  text-[#fff] abhaya-libre-semibold ">
                    Date
                  </p>
                  <input
                    type="date"
                    placeholder="Date"
                    name="date"
                    value={formData.date}
                    onChange={handleChange}
                    required
                    className="font-bold text-[13px]  sm:text-[18px]  w-[100%]  h-[30px] sm:h-[40px] lg:h-[40px] bg-[#fff] rounded-[5px] px-3 text-black"
                  />
                </div>
                <div className="pt-4 lg:pt-0">
                  <p className="font-bold text-[10px] sm:text-[20px] text-white abhaya-libre-semibold">
                    Time
                  </p>
                  <input
                    type="time"
                    className="font-bold text-[13px] sm:text-[18px] w-full h-[30px] sm:h-[40px] lg:h-[40px] bg-white rounded-[5px] px-3 text-black"
                    name="time"
                    value={formData.time}
                    onChange={handleChange}
                    required
                  />
                </div>
                <button
                  type="submit"
                  className="bg-[#F5F0F0] sm:w-[30%] font-bold text-black rounded-md py-2 px-3 mt-7 "
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </AppLayout>
      </div>
    </div>
  );
};

export default Appoinment;
