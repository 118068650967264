import React from "react";
import AppLayout from "./AppLayout";
import loc from "./assets/1.png";
import mb from "./assets/4.png";
import Em from "./assets/5.png";
import ctg from "./assets/6.png";
import "./CssTemp/styles.css";
const Contact = ({ business }) => {
  return (
    <div
      className="w-auto my-4 bg-[#D9D9D9] mt-1"
      id="Contact"
    >
      <AppLayout>
        <h2 className="font-bold text-[32px]  text-[#000] text-center poppins-bold pt-[20px] lg:pt-[50px]">
          Contact
        </h2>

        <div className="w-[85%] mx-auto mt-[30px]  flex flex-col lg:flex-row flex-wrap gap-y-[12px] lg:gap-x-[40px] py-4">
          <div className="w-320px rounded-[24px] lg:rounded-[70px] bg-[#3B3C41] min-h-[52px] lg:min-h-[111px] lg:w-[46%]">
            <div className="min-h-[52px] lg:min-h-[111px]  flex lg:items-center">
              <img
                src={loc}
                className="w-[30px] lg:w-[75px] h-[30px] lg:h-[75px] rounded-[50%] ml-[15px] my-auto"
              />
              <div className="ml-[11px] lg:ml-[25px] mt-[2px] ">
                <p className="text-[#FFFFFF] font-[700] text-[16px] lg:text-[24px] p-0 poppins-bold">
                  Location
                </p>
                <p className="font-[400] text-[14px]  text-[#929090] p-0 poppins-regular">
                  <a href="">{business.address}</a>
                </p>
              </div>
            </div>
          </div>

          <div className="w-320px rounded-[24px] lg:rounded-[70px] bg-[#3B3C41] min-h-[52px] lg:min-h-[111px] lg:w-[46%]">
            <div className="min-h-[52px] lg:min-h-[111px]  flex lg:items-center">
              <img
                src={mb}
                className="w-[30px] lg:w-[75px] h-[30px] lg:h-[75px] rounded-[50%] ml-[15px] my-auto"
              />
              <div className="ml-[11px] lg:ml-[25px] mt-[2px] ">
                <p className="text-[#FFFFFF] font-[700] text-[16px] lg:text-[24px] p-0 poppins-bold">
                  Mobile No.
                </p>
                <p className="font-[400] text-[14px]  text-[#929090] p-0 poppins-regular">
                  {business.mobile}
                </p>
              </div>
            </div>
          </div>

          <div className="w-320px rounded-[24px] lg:rounded-[70px] bg-[#3B3C41] min-h-[52px] lg:min-h-[111px] lg:w-[46%]">
            <div className="min-h-[52px] lg:min-h-[111px]  flex lg:items-center">
              <img
                src={Em}
                className="w-[30px] lg:w-[75px] h-[30px] lg:h-[75px] rounded-[50%] ml-[15px] my-auto"
              />
              <div className="ml-[11px] lg:ml-[25px] mt-[2px] ">
                <p className="text-[#FFFFFF] font-[700] text-[16px] lg:text-[24px] p-0 poppins-bold">
                  Email
                </p>
                <p className="font-[400] text-[14px] text-[#929090] p-0 poppins-regular">
                  {" "}
                  <a href="">
                    {business.email}
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div className="w-320px rounded-[24px] lg:rounded-[70px] bg-[#3B3C41] min-h-[52px] lg:min-h-[111px] lg:w-[46%]">
            <div className="min-h-[52px] lg:min-h-[111px]  flex lg:items-center">
              <img
                src={ctg}
                className="w-[30px] lg:w-[75px] h-[30px] lg:h-[75px] rounded-[50%] ml-[15px] my-auto"
              />
              <div className="ml-[11px] lg:ml-[25px] mt-[2px] ">
                <p className="text-[#FFFFFF] font-[700] text-[18px] lg:text-[24px] p-0 poppins-bold">
                  Category
                </p>
                <p className="font-[400] text-[14px]  text-[#929090] p-0 poppins-regular">
                  <a href="/">
                    <a href="">
                      {" "}
                      {(business.categories || []).map(
                        (category, index) => category.category_name + ", "
                      )}
                    </a>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </AppLayout>
    </div>
  );
};

export default Contact;
