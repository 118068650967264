import AppLayout from "./AppLayout";
import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./FredCss/styles.css"; 

// import required modules
import SwiperButon from "./SwiperButon";

const Gallery = ({ business }) => {
  const [isPopOpen, setIsPopupOpen] = useState(false);
  const [image, setImage] = useState("");

  const openPopup = (imageSrc) => {
    setIsPopupOpen(true);
    setImage(imageSrc);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setImage("");
  };

  return (
    <div>
      <div className="bg-[#7D3C13] py-10" id="gallery">
        <AppLayout>
          <p className="bellota-text-bold text-center font-bold text-[32px] items-start text-[#FFF] pb-4">
            Gallery
          </p>

          <Swiper
            slidesPerView={1}
            spaceBetween={30}
            loop={true}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              340: {
                slidesPerView: 1,
                spaceBetween: 40,
              },
              700: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
            }}
            className="mySwiper"
          >
            {(business.gallery || []).map((gallery, index) => (
        <SwiperSlide key={index}>
          <div className="flex items-center justify-center  gap-5">
            <img
              src={"https://admin.bizzata.in/storage/" + gallery.image}
              className="w-[350px] transition-transform transform duration-100 hover:scale-110 h-[220px]  rounded-[12px] lg:h-[191px] lg:w-[318px] lg:rounded-[12px] mx-auto "
              alt={`gallery ${index + 1}`}
              onClick={() =>
                openPopup(
                  "https://admin.bizzata.in/storage/" + gallery.image
                )
              }
            />
          </div>
        </SwiperSlide>
      ))}
       <SwiperButon />
    </Swiper>
        </AppLayout>
      </div>
      {isPopOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <div className="relative">
            <img src={image} alt="Popup" className="sm:h-[400px] h-[100%]" />
            <button
              className="absolute top-2 right-2 bg-black rounded-full p-2"
              onClick={closePopup}
            >
              &times;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Gallery;
