import AppLayout from "./AppLayout";

import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./style1.css";

import { Keyboard, Pagination } from "swiper/modules";
import { Link, useParams } from "react-router-dom";
import createSlug from "../../Components/createSlug";
import TruncateString from "../../Components/TruncateString ";

function Product({ business }) {
  const { city, slug } = useParams();
  const citySlug = createSlug(city);
  return (
    <div className="my-5" id="services">
      <AppLayout>
        <div className="">
          <h2 className="text-center text-[30px] font-bold text-[#FAFF03] philosopher-regular ">
            OUR SERVICES
          </h2>

          <Swiper
            slidesPerView={1}
            spaceBetween={30}
            keyboard={{
              enabled: true,
            }}
            breakpoints={{
              340: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              500: {
                slidesPerView: 2,
                spaceBetween: 0,
              },
              700: {
                slidesPerView: 3,
                spaceBetween: 15,
              },
              1000: {
                slidesPerView: 4,
                spaceBetween: 15,
              },
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Keyboard, Pagination]}
            className="mySwiper"
          >
            {(business.products || []).map((product, index) => (
              <SwiperSlide key={index}>
                <div
                  className={`flex justify-center items-center text-white p-4
                ${index % 2 === 0 ? "sm:mt-0" : " sm:mt-[50px]"}`}
                >
                  <div className="bg-black rounded-lg overflow-hidden shadow-lg h-[350px] max-w-xs w-[95%] sm:w-[100%]  p-4">
                    <Link to={`/${citySlug}/${slug}/servicepg1/${product.id}`}>
                      <img
                        src={`https://admin.bizzata.in/storage/${product.image}`}
                        alt={product.product_name}
                        className="w-full h-48 object-cover"
                      />
                    </Link>
                    <div className="p-2 mt-2 text-center">
                      <h3 className="text-xl font-bold mb-4 philosopher-regular">
                        <Link
                          to={`/${citySlug}/${slug}/servicepg1/${product.id}`}
                        >
                          <TruncateString
                            inputString={product.product_name}
                            maxLength={15}
                          />
                          ..
                        </Link>
                      </h3>
                      <Link
                        to={`/${citySlug}/${slug}/servicepg1/${product.id}`}
                      >
                        <button className="py-2 px-4 bg-yellow-500 hover:bg-yellow-600 text-black font-bold rounded-lg">
                          Tap Here
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </AppLayout>
    </div>
  );
}

export default Product;
