import React from "react";
import AppLayout from "./AppLayout";
import "./css/styles4.css";
const Footer = ({ business }) => {
  const year = new Date().getFullYear();
  return (
    <div>
      <footer className="w-full bg-[#BA9E88] text-white h-auto">
        <AppLayout>
          <div class="grid grid-cols-1 sm:grid-cols-2  gap-4">
            <div className="lg:mb-0 mt-10 lg:mt-10 mx-5 sm:mx-5 lg:ml-[25%]">
              <p className="mb-6 text-xl font-bold unito-sans-bold">Contaact Details :-</p>
              <ul className="flex flex-col space-y-2   ">
                <h1 className=" font-bold text-xl">Address</h1>
                <li className=" hover:text-black text-lg roboto-regular">
                  {business.address}
                </li>
                <h1 className=" font-bold text-xl">phone</h1>
                <li className="hover:text-black text-lg roboto-regular">
                  +91{business.mobile}
                </li>
                <h1 className=" font-bold text-xl">Email.</h1>
                <li className=" hover:text-black text-lg leading-3 roboto-regular">
                  {business.email}
                </li>
              </ul>
            </div>

            <div className="mb-2 sm:mb-8 lg:mb-0 lg:mt-10 mx-5 sm:mx-0 lg:ml-[10%]">
              <p className="mb-4 text-lg font-semibold pt-12">Like & Follow</p>
              <ul className="flex flex-col justify-around text-[14px] font-medium">
                <span className=" my-[2%] rounded-full flex flex-wrap px-4 py-4 gap-4 justify-center">
                  {(business.business_social_media_links || []).map(
                    (linkData, index) => (
                      <img
                        key={index}
                        src={`https://admin.bizzata.in/storage/${linkData.social_media_platform.logo}`}
                        alt={`${linkData.social_media_platform.platform_name} logo`}
                        className="w-[25px] lg:w-[32px] sm:w-[25px] sm:h-[25px] h-[25px] lg:h-[32px] cursor-pointer"
                      />
                    )
                  )}
                </span>
                <li className="text-2xl font-bold">PAY NOW</li>
                <li>
                  <div>
                    <img
                      src={`https://admin.bizzata.in/storage/${business.qr_code}`}
                      alt="QR"
                      className="m-2 h-[120px] w-[#130px] pb-5 cursor-pointer"
                    />
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <hr className="my-1 bg-black text-black" />
          <div className="mx-auto max-w-6xl items-center justify-between px-4 md:flex lg:px-0 mb-1">
            <div className="mt-4 md:mt-0">
              <p className="text-sm font-medium ">
                © {year} {business.business_name}. All rights reserved.
              </p>
            </div>
          </div>
        </AppLayout>
      </footer>
    </div>
  );
};

export default Footer;
