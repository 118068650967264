import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";

import { Navigation } from "swiper/modules";

import AppLayout from "./AppLayou";
import TruncateString from "../../Components/TruncateString ";
 import "./TempCss/Style.css";
const Review = ({ business }) => {
  return (
    <div className="bg-[#A5A8C1] py-10">
      <AppLayout>
        <h2 className="text-center abhaya-libre-semibold font-bold text-[35px] items-center pt-[20px]   text-[#F1F1F1] ">
          Our Clients Says
        </h2>
        <div>
          {" "}
          <Swiper
            breakpoints={{
              340: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              540: {
                slidesPerView: 2,
                spaceBetween: 5,
              },
              750: {
                slidesPerView: 2,
                spaceBetween: 15,
              },
              1000: {
                slidesPerView: 3,
                spaceBetween: 15,
              },
            }}
            modules={[Navigation]}
            navigation
            className="mySwiper"
          >
            {(business.testimonials || []).map((testimonial, index) => (
              <SwiperSlide key={index}>
                <div className="mt-2 my-2 h-auto max-w-[90%] sm:w-[370px] lg:w-[380px] bg-[#353956] text-[#F1F1F1] rounded-xl mx-auto p-4">
                  <p className="text-[14px] font-sans abhaya-libre-semibold">
                    "
                    <TruncateString
                      inputString={testimonial.content}
                      maxLength={150}
                    />
                    "
                  </p>
                  <div className="pt-4 text-right">
                    <h1 className="text-[18px] font-semibold abhaya-libre-semibold">
                      - {testimonial.name}
                    </h1>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </AppLayout>
    </div>
  );
};

export default Review;
