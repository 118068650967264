import React from "react";
import Header from "./Header";
import Hero from "./Hero";
import Contact from "./Contact";
import Product from "./Product";
import Services from "./Services";
import Gallery from "./Gallery";
import Enquiry from "./Enquiry";
import Business from "./Business";
import Certificates from "./Certificates";
import Offers from "./Offers";
import Blogs from "./Blogs";
import Appoinment from "./Appoinment";

import Reviews from "./Reviews";
import { Helmet } from "react-helmet";

import { BusinessProvider } from "../../../src/context/BusinessContext";
import Footer from "./Footer";
import Navbar from "./Navbar";
const Temp6 = ({ business }) => {
  if (!business) {
    return <div>Loading...</div>;
  } // Added missing semicolon here

  return (
    <BusinessProvider business={business}>
      <Helmet>
        <title>{business.meta_title}</title>
        <link rel="icon" href="./logo.ico" />
         <meta name="robots" content="index, follow"></meta>
        <meta name="description" content={business.meta_description} />{" "}
        {/* Corrected */}
        <meta name="keywords" content={business.meta_keyword} />{" "}
      
      </Helmet>
      <div className="">
        <Navbar business={business} />
        <Header business={business} className="mb-10" />
        <Hero business={business} className="mt-14" />
        <Contact business={business} />
        <Product business={business} />
        <Services business={business} />
        <Offers business={business} />
        <Blogs business={business} />
        <Gallery business={business} />

        <Business business={business} />
        <Certificates business={business} />

        <Reviews business={business} />
        <Appoinment business={business} />
        <Enquiry business={business} />
        <Footer business={business} />
      </div>
    </BusinessProvider>
  );
};

export default Temp6;
