import { Swiper, SwiperSlide } from "swiper/react";
import "./style3.css"
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Navigation } from "swiper/modules";
import AppLayout from "./AppLayout";
import createSlug from "../../Components/createSlug";
import { useParams, Link } from "react-router-dom";
import TruncateString from "../../Components/TruncateString ";
import "./style3.css"
const Service = ({ business }) => {
  const { city, slug } = useParams();
  const citySlug = createSlug(city);
  return (
    <div>
      <AppLayout>
        <h2
          className="text-[30px] text-center text-white piazzolla-uniquifier font-semibold"
          id="service"
        >
          Service
        </h2>
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          breakpoints={{
            340: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            500: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            900: {
              slidesPerView: 3,
              spaceBetween: 15,
            },
          }}
          navigation={true}
          modules={[Autoplay, Navigation]}
          className="mySwiper"
        >
          <div className="flex flex-wrap justify-center gap-6 mx-auto">
            {(business.services || []).map((service, index) => (
              <SwiperSlide key={index} className="flex justify-center">
                <div className="w-[90%] sm:w-[90%] md:w-[90%] lg:w-[90%] xl:w-[90%] h-[450px] bg-[#5E1523] rounded-lg border border-black shadow-xl transition transform hover:scale-105 hover:shadow-2xl mx-2 my-4 overflow-hidden">
                  <Link to={`/${citySlug}/${slug}/servicepg3/${service.id}`}>
                    <img
                      src={"https://admin.bizzata.in/storage/" + service.image}
                      alt={service.service_name}
                      className="w-full h-56 object-cover rounded-t-lg p-4 rounded-2xl"
                    />
                  </Link>
                  <div className="p-4">
                    <h1 className="text-lg mt-2 font-bold tracking-tight text-white text-start">
                      <Link
                        to={`/${citySlug}/${slug}/servicepg3/${service.id}`}
                      >
                        <TruncateString
                          inputString={service.service_name}
                          maxLength={70}
                        />
                      </Link>
                    </h1>
                    <p className="pt-4 text-[#F1f1f1] text-[18px] font-light tracking-tight text-start pb-4 piazzolla-uniquifier">
                      <Link
                        to={`/${citySlug}/${slug}/servicepg3/${service.id}`}
                      >
                        <TruncateString
                          inputString={service.description}
                          maxLength={135}
                        />
                      </Link>
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </div>
        </Swiper>
      </AppLayout>
    </div>
  );
};

export default Service;
