import React from "react";
import AppLayout from "./AppLayou";
import "./TempCss/Style.css";


const Business = ({ business }) => {
  function convertTo12HourFormat(time) {
    let [hours, minutes] = time.split(":");
    hours = parseInt(hours, 10);
    let period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert '0' hours to '12'
    return `${hours}:${minutes} ${period}`;
  }
  return (
    <div className=" mt-0.5 sm:mt-1 bg-[#FFFFFF] shadow-md pb-1">
      <AppLayout>
        <h2 className=" text-[35px] font-bold text-[#000] abhaya-libre-semibold  text-center">
          Business <span className="text-[38px] font-normal italic">Hours</span>
        </h2>
        <hr className="w-16 sm:w-32 mx-auto h-1 lg:h-1 bg-[#000] mt-1" />
        <div className="w-[90%] sm:w-[295px] h-auto lg:w-[672px] lg:min-h-[450px] mx-auto rounded-[30px] mt-[20px] bg-[#353956] shadowbox lg:mt-[40px] my-10 shadow-2xl p-4 lg:p-8">
          <div className="text-[#FFF]">
            <table className="w-full">
              <tbody>
                {(business.business_hours || []).map((dayHour, index) => (
                  <tr key={index} className="p-2 md:py-4">
                    <td className="text-[15px] sm:text-xl md:text-[25px] md:py-4 font-medium">
                      {dayHour.day}
                    </td>
                    <td className="text-[15px] sm:text-xl md:text-[25px] md:py-4 font-medium pt-1">
                      {convertTo12HourFormat(dayHour.open_time)}
                    </td>
                    <td className="text-[15px] sm:text-xl md:text-[25px] md:py-4 font-medium">
                      {convertTo12HourFormat(dayHour.close_time)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </AppLayout>
    </div>
  );
};

export default Business;
