import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import AppLayout from "./AppLayout";
import TruncateString from "../../Components/TruncateString ";
const Reviews = ({ business }) => {
  return (
    <div className="py-10">
    <AppLayout>
    <div>
      {" "}
      <Swiper
        breakpoints={{
          340: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          540: {
            slidesPerView: 2,
            spaceBetween: 5,
          },
          750: {
            slidesPerView: 2,
            spaceBetween: 15,
          },
          770: {
            slidesPerView: 3,
            spaceBetween: 15,
          },
        }}
        modules={[Navigation]}
        navigation
        className="mySwiper"
      >
         {(business.testimonials || []).map((testimonial, index) => (
          <SwiperSlide key={index}>
            <div className="mt-2 my-2 w-[90%]  h-[100%] sm:w-[300px] bg-[#BA9E88] text-[#FFF] rounded-xl mx-auto">
              <h1 className="pt-2 px-3 text-[15px] font-sans text-[#9F7161]">
               <TruncateString
                      inputString={testimonial.content}
                      maxLength={100}
                    />
              </h1>
              <div className="sm:pt-4 pt-[-8px] sm:pl-[170px] pl-[120px]">
                <h1 className="text-[16px] font-semibold sm:pl-10 pl-6 pb-2">
                  -{testimonial.name}
                </h1>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
    </AppLayout>
    </div>
  );
};

export default Reviews;
