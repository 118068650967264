import React from "react";
import Hader from "./Hader";
import ContactUs from "./ContactUs";
import Product from "./Product";
import Blogs from "./Blogs";
import Gallery from "./Gallery";
import Reviews from "./Reviews";
import Busapp from "./Busapp";
import Service from "./Service";
import Certificates from "./Certificates";
import Offers from "./Offers";
import Feedbackform from "./Feedbackform";
import Appoinment from "./Appoinment";
import { Helmet } from "react-helmet";

import { BusinessProvider } from "../../context/BusinessContext";
import Navbar from "./Navbar";
import Footer from "./Footer";
const Temp8 = ({ business }) => {
  if (!business) {
    return <div>Loading...</div>;
  }
  return (
    <BusinessProvider business={business}>
      <Helmet>
        <title>{business.meta_title}</title>
        <meta name="robots" content="index, follow"></meta>
        <link rel="icon" href="./logo.ico" />
        <meta name="description" content={business.meta_keyword} />
        <meta name="keywords" content={business.meta_description} />
         
      </Helmet>
      <div className="bg-[#ECECEC]">
        <Navbar business={business} />
        <Hader business={business} />
        <ContactUs business={business} />

        <Product business={business} />

        <Service business={business} />
        <Offers business={business} />

        <Blogs business={business} />
        <Gallery business={business} />
        <Busapp business={business} />
        <Certificates business={business} />

        <Feedbackform business={business} />
        <Reviews business={business} />

        <Appoinment business={business} />
      
        <Footer business={business} />
      </div>
    </BusinessProvider>
  );
};

export default Temp8;
