import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import AppLayout from "./AppLayout";
import { Helmet } from "react-helmet";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { BiSolidLike } from "react-icons/bi";
import { IoCall } from "react-icons/io5";
import { FaStar, FaWhatsapp, FaMapMarkerAlt } from "react-icons/fa";
import createSlug from "./createSlug";
import { Link } from "react-router-dom";
import lov from "./assites/love.png";

import "./style.css";
const Category = () => {
  const { category_id, category_name } = useParams(); // Get params from URL
  const [businesses, setBusinesses] = useState([]);
  const [seo, setSeo] = useState({
    meta_title: "",
    meta_keywords: "",
    meta_description: "",
  }); // State to store SEO data

  useEffect(() => {
    if (category_id && category_name) {
      axios
        .get(`https://admin.bizzata.in/api/category/${category_id}/businesses`)
        .then((response) => {
          setBusinesses(response.data.businesses);
          setSeo(response.data.seo); // Set SEO data
        })
        .catch((error) => {
          console.error("Error fetching businesses:", error);
        });
    }
  }, [category_id, category_name]);

  // Handle case where category_id or category_name is null or undefined
  if (!category_id || !category_name) {
    return (
      <div className="my-2 mb-8">
        <AppLayout>
          <h6 className="text-center font-bold text-4xl text-[#000] tracking-1 sm:tracking-2 font-serif mb-8">
            Category Not Found
          </h6>
          <p className="text-center">Category details not provided.</p>
        </AppLayout>
      </div>
    );
  }

  return (
    <div className="my-2 mb-8">
      <Helmet>
        <title>{seo.meta_title}</title>
        <meta name="robots" content="index, follow"></meta>
        <meta name="description" content={seo.meta_description} />
        <meta name="keywords" content={seo.meta_keywords} />
      </Helmet>

      <Navbar />
      <AppLayout>
        <div className=" mt-4 bg-[#DC2626]  mx-[10%] sm:mx-[15%] lg:mx-[30%] rounded-[10px]">
          <h1 className="  p-4 text-white text-center  text-4xl  tracking-1 sm:tracking-2 font-serif mb-8 open-sans-special">
            Best {category_name}
          </h1>
        </div>

        <div className="grid md:grid-cols-1 gap-6">
          {businesses.map((business, index) => (
            <div
              key={index}
              className="sm:border-2 border-gray-300 rounded-xl shadow-md p-2 sm:p-4 mb-4 flex flex-col sm:flex-row gap-4"
            >
              <div className="flex-shrink-0 flex gap-2">
                <Link
                  to={`/${createSlug(business.city.name)}/${business.slug}`}
                  target="_blank"
                  className="hover:text-blue-500"
                >
                  <img
                    src={"https://admin.bizzata.in/storage/" + business.profile}
                    alt="Loading..."
                    className="rounded w-[110px] sm:w-48 h-[150px] object-cover"
                  />
                </Link>
                {/* phone view */}
                <div className="sm:hidden block">
                  <div className="flex">
                    <h2 className="font-semibold text-xl mb-1 flex gap-2 ">
                      <Link
                        to={`/${createSlug(business.city.name)}/${
                          business.slug
                        }`}
                        target="_blank"
                        className="hover:text-[#DC2626]"
                      >
                        {business.business_name}
                      </Link>
                    </h2>

                    <img
                      src={lov}
                      alt=""
                      className="absolute top-0 right-0 h-6 w-6"
                    />
                  </div>

                  <div className="flex items-center mb-3">
                    <div className="flex items-center text-yellow-500 mr-2 gap-2">
                      <h3 className="bg-[#22C55E] px-2 text-center text-white font-bold rounded">
                        4.6
                      </h3>
                      <FaStar className="w-4 h-4" />
                      <FaStar className="w-4 h-4" />
                      <FaStar className="w-4 h-4" />
                      <FaStar className="w-4 h-4" />
                      <span>{business.rating}</span>
                    </div>
                    <span className="text-gray-600 text-sm hover:text-[#3B82F6]">
                      {business.rating_count}
                    </span>
                  </div>
                  <p className="text-gray-800 text-sm mb-2 flex hover:text-[#DC2626]">
                    <FaMapMarkerAlt className="text-red-500 w-5 h-5 mr-1" />
                    {business.address}
                  </p>
                </div>
              </div>
              <div className="flex flex-col relative flex-grow">
                <div className="hidden sm:block">
                  <div className="flex ">
                    <h2 className="font-semibold text-xl mb-1 flex gap-2 ">
                      <Link
                        to={`/${createSlug(business.city.name)}/${
                          business.slug
                        }`}
                        target="_blank"
                        className="hover:text-[#DC2626]"
                      >
                        {business.business_name}
                      </Link>
                    </h2>

                    <img
                      src={lov}
                      alt=""
                      className="absolute top-0 right-0 h-6 w-6"
                    />
                  </div>

                  <div className="flex items-center mb-3">
                    <div className="flex items-center text-yellow-500 mr-2 gap-2">
                      <h3 className="bg-[#22C55E] px-2 text-center text-white font-bold rounded">
                        4.2
                      </h3>
                      <FaStar className="w-4 h-4" />
                      <FaStar className="w-4 h-4" />
                      <FaStar className="w-4 h-4" />
                      <FaStar className="w-4 h-4" />
                      <span>{business.rating}</span>
                    </div>
                    <span className="text-gray-600 text-sm hover:text-[#3B82F6]">
                      {business.rating_count}
                    </span>
                  </div>
                  <p className="text-gray-800 text-sm mb-2 flex hover:text-[#DC2626]">
                    <FaMapMarkerAlt className="text-red-500 w-5 h-5 mr-1" />
                    {business.address}
                  </p>
                </div>
                {/* Social icon */}
                <div className="mt-[30px] sm:mt-4 flex">
                  <div className="flex gap-2 sm:gap-4 absolute bottom-0 left-0">
                    <button className="bg-green-500 hover:bg-green-700 text-white text-[12px] font-bold py-2 px-[10px] sm:px-4 rounded flex items-center gap-1 sm:gap-2 h-[40px] sm:h-auto">
                      <IoCall className="w-4 h-4" />
                      Show Number
                    </button>
                    <button className="h-[40px] sm:h-auto bg-blue-500 hover:bg-blue-700 text-white text-[12px] px-[10px] font-bold sm:py-2 sm:px-4 rounded flex items-center gap-2">
                      Send Enquiry
                    </button>
                    <button className="h-[40px] sm:h-auto text-[12px] px-[10px] bg-green-500 hover:bg-green-700 text-white font-bold sm:py-2 sm:px-4 rounded flex items-center gap-2">
                      <FaWhatsapp className="w-4 h-4" />
                      Chat
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </AppLayout>
      <Footer />
    </div>
  );
};

export default Category;
