import React from "react";
import Hader from "./Hader";
import Herro from "./Herro";
import Contact from "./Contact";
import Product from "./Product";
import Services from "./Services";
import Galleary from "./Galleary";
import Busness from "./Busness";
import Enqform from "./Enqform";
import Bloges from "./Bloges";
import Feedbackform from "./Feedbackform";
import Reviews from "./Reviews";
import Certificates from "./Certificates";
import Offers from "./Offers";
import Navbar from "./Navbar";

import Footer from "./Footer";

import { Helmet } from "react-helmet";

import { BusinessProvider } from "../../../src/context/BusinessContext";
import Appoinment from "./Appoinment";

const Temp4 = ({ business }) => {
  if (!business) {
    return <div>Loading...</div>;
  } // Added missing semicolon here

  return (
    <BusinessProvider business={business}>
      <Helmet>
        <title>{business.meta_title}</title>
         <meta name="robots" content="index, follow"></meta>
         <link rel="icon" href="./logo.ico" />
        <meta name="description" content={business.meta_description} />{" "}
        {/* Corrected */}
        <meta name="keywords" content={business.meta_keyword} />{" "}
        {/* Corrected */}
       
      </Helmet>
      <div className="bg-[#F7EDE4] ">
        <Navbar business={business} />
        <Hader business={business} />
        <Herro business={business} />
        <Contact business={business} />

        <Product business={business} />
        <Services business={business} />
        <Galleary business={business} />

        <Bloges business={business} />
        <Certificates business={business} />
        <Busness business={business} />
        <Offers business={business} />
        <Feedbackform business={business} />
        <Reviews business={business} />
        <Enqform business={business} />
        <Appoinment business={business} />
        <Footer business={business} />
      </div>
    </BusinessProvider>
  );
};

export default Temp4;
