import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./CssFold/style5.css";
// import required modules
import { Autoplay, Pagination } from "swiper/modules";
import AppLayout from "./AppLayout";
import createSlug from "../../Components/createSlug";
import { useParams, Link } from "react-router-dom";
import TruncateString from "../../Components/TruncateString ";

const Service = ({ business }) => {
  const { city, slug } = useParams();
  const citySlug = createSlug(city);
  return (
    <div>
      <AppLayout>
        <h4
          className=" text-center text-[40px] font-bold text-white roboto mt-10"
          id="services"
        >
          Service
        </h4>
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 4500,
            disableOnInteraction: false,
          }}
          breakpoints={{
            340: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            500: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            900: {
              slidesPerView: 4,
              spaceBetween: 15,
            },
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay, Pagination]}
          className="mySwiper"
        >
          <div className="flex flex-wrap justify-center gap-4 mx-auto">
            {(business.services || []).map((service, index) => (
              <SwiperSlide key={index} className="flex justify-center">
                <div className="w-[90%] sm:w-[100%] md:w-[100%] lg:w-[100%] xl:w-[100%] h-[440px] flex-col mt-8 mb-10 pb-16 mx-auto bg-gray-800 rounded-lg shadow-lg overflow-hidden">
                  <div className="relative">
                    <Link to={`/${citySlug}/${slug}/servicepg5/${service.id}`}>
                      <img
                        src={
                          "https://admin.bizzata.in/storage/" + service.image
                        }
                        alt={service.service_name}
                        className="w-full h-48 object-cover rounded-t-lg"
                      />
                    </Link>
                  </div>
                  <div className="p-4">
                    <Link to={`/${citySlug}/${slug}/servicepg5/${service.id}`}>
                      <h4 className="text-[28px] font-semibold text-white mb-2 roboto ">
                        <TruncateString
                          inputString={service.service_name}
                          maxLength={90}
                        />
                      </h4>
                    </Link>
                    <h4 className="text-[16px] text-gray-300 roboto-serif-unique">
                      <TruncateString
                        inputString={service.description}
                        maxLength={160}
                      />
                    </h4>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </div>
        </Swiper>
      </AppLayout>
    </div>
  );
};

export default Service;
