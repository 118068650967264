import React, { useState } from "react";
import axios from "axios";
import AppLayout from "./AppLayou";
 import "./TempCss/Style.css";
function Form({ business }) {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    feedback: "",
    mobile: "",
    business_id: business.id,
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleClearForm = () => {
    setFormData({
      name: "",
      email: "",
      feedback: "",
      mobile: "",
      business_id: business.id,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://admin.bizzata.in/api/business_feedback",
        formData
      );
      alert(response.data.message);
      handleClearForm();
    } catch (error) {
      console.error("There was an error!", error);
      alert("There was an error submitting your feedback. Please try again.");
    }
  };

  return (
    <section id="inquiryform">
      <div className="bg-[#353956] text-[#FFF] h-auto mb-5">
        <div className="text-center font-semibold leading-5 mx-auto pt-10">
          <h1 className="text-center abhaya-libre-semibold font-bold text-[35px] items-center pt-[20px]">
            Enquiry<span className="text-[38px] font-normal italic pl-2">Form</span>
          </h1>
          <hr className="w-16 sm:w-32 mx-auto h-1 lg:h-1 bg-[#F0F0F0] mt-1" />
        </div>

        <div className="mt-14">
          <form onSubmit={handleSubmit}>
            <AppLayout>
              <div className="mb-4 bg-white w-[95%] sm:w-[95%] mx-auto h-auto my-15 rounded-2xl sm:mx-5 sm:px-5">
                <div className="py-2">
                  <label
                    htmlFor="name"
                    className="block font-semibold text-black mx-4 sm:mx-10 lg:text-2xl abhaya-libre-semibold text-lg"
                  >
                    Name:<span className="text-red-500 text-2xl">&nbsp;*</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    placeholder="Type your Name :-"
                    className="w-[90%] sm:w-[90%] lg:w-[400px] mt-2 border-none rounded-md focus:outline-none focus:border-black-500 mx-4 sm:mx-10 text-black"
                  />
                  <hr className="w-[90%] sm:w-[75%] lg:w-[400px] mb-2 mx-4 sm:mx-10 h-[1px] lg:h-[2px] bg-[#D9D9D9]" />
                </div>
              </div>
              <div className="mb-4 bg-white w-[95%] sm:w-[95%] mx-auto h-auto my-15 rounded-2xl sm:mx-5 sm:px-5">
                <div className="py-2">
                  <label
                    htmlFor="email"
                    className="block font-semibold text-black mx-4 sm:mx-10 lg:text-2xl text-lg abhaya-libre-semibold"
                  >
                    Email:<span className="text-red-500 text-2xl">&nbsp;*</span>
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    placeholder="Type your Email :-"
                    className="w-[90%] sm:w-[90%] lg:w-[400px] mt-2 border-none rounded-md focus:outline-none focus:border-black-500 mx-4 sm:mx-10 text-black"
                  />
                  <hr className="w-[90%] sm:w-[75%] lg:w-[400px] mb-2 mx-4 sm:mx-10 h-[1px] lg:h-[2px] bg-[#D9D9D9]" />
                </div>
              </div>
              <div className="mb-4 bg-white w-[95%] sm:w-[95%] mx-auto h-auto my-15 rounded-2xl sm:mx-5 sm:px-5">
                <div className="py-2">
                  <label
                    htmlFor="mobile"
                    className="block font-semibold text-black mx-4 sm:mx-10 lg:text-2xl text-lg abhaya-libre-semibold"
                  >
                    Mobile:
                    <span className="text-red-500 text-2xl">&nbsp;*</span>
                  </label>
                  <input
                    type="tel"
                    id="mobile"
                    name="mobile"
                    value={formData.mobile}
                    onChange={handleChange}
                    className="w-[90%] sm:w-[90%] lg:w-[400px] mt-2 border-none rounded-md focus:outline-none focus:border-black-500 mx-4 sm:mx-10 text-black"
                    placeholder="Enter your Mobile No."
                    required
                  />
                  <hr className="w-[90%] sm:w-[75%] lg:w-[400px] mb-2 mx-4 sm:mx-10 h-[1px] lg:h-[2px] bg-[#D9D9D9]" />
                </div>
              </div>
              <div className="mb-4 bg-white w-[95%] sm:w-[95%] mx-auto h-auto my-15 rounded-2xl sm:mx-5 sm:px-5">
                <div className="py-2">
                  <label
                    htmlFor="feedback"
                    className="block font-semibold text-black mx-4 sm:mx-10 lg:text-2xl text-lg abhaya-libre-semibold"
                  >
                    Message:
                    <span className="text-red-500 text-2xl">&nbsp;*</span>
                  </label>
                  <textarea
                    value={formData.feedback}
                    onChange={handleChange}
                    name="feedback"
                    required
                    placeholder="Any Message for Us :-"
                    className="w-[90%] sm:w-[90%] lg:w-[400px] mt-2 border-none rounded-md focus:outline-none focus:border-black-500 mx-4 sm:mx-10 text-black"
                  />
                  <hr className="w-[90%] sm:w-[75%] lg:w-[400px] mb-2 mx-4 sm:mx-10 h-[1px] lg:h-[2px] bg-[#D9D9D9]" />
                </div>
              </div>
              <div className="flex items-center justify-center sm:justify-start sm:ml-[27px] mt-4">
                <input
                  type="checkbox"
                  id="terms"
                  name="terms"
                  className="mr-2 w-[20px] mb-2 p-[5px] h-[20px]"
                />
                <label
                  htmlFor="terms"
                  className="text-[14px] sm:text-[20px] text-black lg:text-[30px] lg:py-6 sm:py-4 abhaya-libre-semibold"
                >
                  I agree to the{" "}
                  <a href="/terms" className="underline">
                    terms and conditions
                  </a>
                  .
                </label>
              </div>
            </AppLayout>
            <div className="bg-white">
              <AppLayout>
                <div className="grid grid-cols-2 gap-2 sm:gap-1">
                  <div className="p-4 rounded-md">
                    <div className="sm:ml-10">
                      <button
                        type="submit"
                        className="bg-[#354956] text-white px-4 py-2 abhaya-libre-semibold rounded-md hover:bg-[#1e2a31] focus:outline-none focus:shadow-outline-blue w-28 h-10"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                  <div className="p-4 rounded-md justify-items-end lg:ml-72 sm:ml-10">
                    <div className="lg:mx-20 sm:mx-5">
                      <button
                        type="button"
                        onClick={handleClearForm}
                        className="font-bold px-4 py-2 rounded-md abhaya-libre-semibold bg-red-500 text-white hover:bg-red-700 hover:text-white focus:outline-none focus:shadow-outline-blue"
                      >
                        Clear form
                      </button>
                    </div>
                  </div>
                </div>
              </AppLayout>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

export default Form;
