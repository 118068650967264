import React from "react";
import AppLayout from "./AppLayout";
import map from "./assets/Ringer Volume.png";
import mal from "./assets/Location.png";
import cal from "./assets/Email.png";
import ctg from "./assets/ctg.png";
 import "./style3.css"
const ContactUs = ({ business }) => {
  return (
    <AppLayout>
      <div className="text-white py-10 mx-[10%]">
        <h2 className="text-center text-3xl font-semibold mb-8 piazzolla-uniquifier font-semibold">Contact us</h2>
        <div className="flex py-4 sm:gap-2 lg:gap-6 justify-center sm:justify-space flex-wrap mx-auto">
          <div className="flex items-center bg-[#321319] p-3 sm:p-4 rounded-[15px] shadow-md w-[100%] md:w-[370px] border-2 border-[#920C26] h-[70px]">
            <div className="text-blue-400 mr-4">
              <img src={map} alt="" className="h-[30px] sm:h-16" />
            </div>
            <div>
              <h3 className="text-[16px] font-[800px] sm:text-lg sm:font-semibold">
                Contact Number
              </h3>
              <p className="text-[12px] font-mono sm:text-[12px]">
                {" "}
                {business.mobile}
              </p>
            </div>
          </div>

          <div className="my-2 md:my-0 flex items-center bg-[#321319] p-3 sm:p-4 rounded-[15px] shadow-md w-[100%] md:w-[370px] border-2 border-[#920C26] h-[70px]">
            <div className="text-blue-400 mr-4">
              <img src={mal} alt="" className="w-[80px] sm:h-16" />
            </div>
            <div>
              <h3 className="text-[16px] font-[800px] sm:text-lg sm:font-semibold">
                Location
              </h3>
              <p className="text-[12px] font-mono sm:text-[12px]">
                {" "}
                {business.address}
              </p>
            </div>
          </div>

          <div className="flex items-center bg-[#321319] py-3 sm:p-4 p-1 rounded-[15px] shadow-md w-[100%] mb-2 border-2 border-[#920C26] md:w-[370px] h-[70px]">
            <div className="text-blue-400 mr-4">
              <img src={cal} alt="" className="w-[60px] sm:h-16" />
            </div>
            <div>
              <h3 className="text-[16px] font-[800px] sm:text-lg sm:font-semibold">
                E-mail address
              </h3>
              <p className="text-[12px] font-mono sm:text-[12px]">
                {business.email}
              </p>
            </div>
          </div>
          <div className="flex items-center bg-[#321319] p-3 sm:p-4 rounded-[15px] shadow-md w-[100%] border-2 border-[#920C26] md:w-[370px] h-[70px]">
            <div className="text-blue-400 mr-4">
              <img src={ctg} alt="" className="h-[40px] sm:h-16" />
            </div>
            <div>
              <h3 className="text-[16px] font-[800px] sm:text-lg sm:font-semibold">
                Categories
              </h3>
              <p className="text-[12px] font-mono sm:text-[12px]">
                <a href="">
                  {" "}
                  {(business.categories || []).map(
                    (category, index) => category.category_name + ", "
                  )}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

export default ContactUs;
