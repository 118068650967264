import React, { useState } from "react";
import AppLayout from "./AppLayout";
import Add from "./assites/Address.png";
import Phon from "./assites/Phon.png";
import mel from "./assites/Email.png";
import axios from "axios";
import loc from "./assites/ctg.png";
import "./CssFold/style5.css";
function ContactUs({ business }) {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    feedback: "",
    mobile: "",
    business_id: business.id,
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://admin.bizzata.in/api/business_feedback",
        formData
      );
      alert(response.data.message);

      setFormData({
        name: "",
        email: "",
        feedback: "",
        mobile: "",

        business_id: business.id,
      });
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  return (
    <AppLayout>
      <section id="contact">
        <div className=" text-[#FFF] h-auto mb-5 rounded-md">
          <div className="text-start font-semibold leading-5 mx-auto pt-10">
            <div className="italic  text-[30px]  text-center roboto">
              Enquiry
            </div>
          </div>

          <div class="container mx-auto mt-8">
            <div class="grid grid-cols-1 sm:grid-cols-2  lg:grid-cols-2 gap-2 lg:gap-0">
              <div class="bg-[#AA6141] p-4 shadow rounded-xl  lg:ml-[10%] my-2 mx-[2%] sm:my-[5%] sm:h-[350px] ">
                <div className="lg:mx-[10%]">
                  <h6 className="text-center text-[18px] font-medium my-[10px] roboto-serif-unique">
                    CONTACT US
                  </h6>
                  <div className="flex">
                    <img src={Add} alt="" className=" h-[50px] p-2" />
                    <p className="pt-4 text-start text-[15px] roboto">
                      {business.address}
                    </p>
                  </div>
                  <div className="flex mt-2">
                    <img src={Phon} alt="" className=" h-[50px] p-2" />
                    <p className="pt-4 text-start text-[15px] roboto">
                      {business.mobile}
                    </p>
                  </div>
                  <div className="flex mt-2">
                    <img src={mel} alt="" className=" h-[50px] p-2" />
                    <p className="pt-4 text-start text-[15px] roboto">
                      {business.email}
                    </p>
                  </div>
                  <div className="flex mt-2">
                    <img
                      src={loc}
                      alt=""
                      className=" h-[50px] p-2 text-white"
                    />
                    <p className="pt-4 text-start text-[15px] roboto">
                      <a href="">
                        {" "}
                        {(business.categories || []).map(
                          (category, index) => category.category_name + ", "
                        )}
                      </a>
                    </p>
                  </div>
                </div>
              </div>

              <div className="bg-[#031A25] p-4 border-2 border-[#45342C] shadow rounded mb-4">
                <h6 className="text-2xl font-bold mb-6 text-center text-white roboto-serif-unique">
                  Contact Us
                </h6>
                <div className="mx-2 sm:mx-[5%]">
                  <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                      <label
                        htmlFor="name"
                        className="block text-white font-bold mb-2 roboto "
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border border-[#AA6141] rounded bg-[#202628] text-white"
                        required
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        htmlFor="mobile"
                        className="block text-white font-bold mb-2 roboto"
                      >
                        Mobile
                      </label>
                      <input
                        type="tel"
                        id="mobile"
                        name="mobile"
                        value={formData.mobile}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border border-[#AA6141] rounded bg-[#202628] text-white"
                        placeholder="Enter your Mobile No."
                        required
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        htmlFor="email"
                        className="block text-white font-bold mb-2 roboto"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border border-[#AA6141] bg-[#202628] rounded text-white"
                        required
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        htmlFor="message"
                        className="block text-white font-bold mb-2 roboto"
                      >
                        Message
                      </label>
                      <textarea
                        id="message"
                        name="feedback"
                        value={formData.feedback}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border border-[#AA6141] rounded bg-[#202628] text-white"
                        rows="4"
                        required
                      />
                    </div>
                    <button
                      type="submit"
                      className="bg-[#AA6141] text-white px-4 py-2 rounded roboto"
                    >
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </AppLayout>
  );
}

export default ContactUs;
