import React, { useState } from "react";
import AppLayout from "./AppLayout";
import axios from "axios";
import "./CssFold/style5.css";
const Appoinment = ({ business }) => {
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    date: "",
    time:"",
    business_id: business.id,
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://admin.bizzata.in/api/business_appointment",
        formData
      );
      alert(response.data.message);
      setFormData({
        name: "",
        date: "",
        mobile: "",
        time:"",
        business_id: business.id,
      });
    } catch (error) {
      console.error("There was an error!", error);
    }
  };
  return (
    <div className=" mb-4">
      <AppLayout>
        <h6 className="text-center text-[40px] font-bold text-white roboto pt-2 pb-6">
          Appointment
        </h6>
        <form onSubmit={handleSubmit}>
          <div className=" pb-10 rounded  bg-[#031A25] pt-6">
            <div className="w-[90%] sm:w-[85%] lg:w-[80%]   sm:h-[100%]  items-center rounded-[10px]  lg:flex gap-2 justify-around mx-auto mt-[30px]  pb-20">
              <div className="">
                <p className="font-bold text-[10px]  sm:text-[20px]  text-[#FFF] roboto-serif-unique-class-name ">
                  Name
                </p>
                <input
                  type="text"
                  placeholder="name"
                  className="font-bold text-[13px]  sm:text-[18px] w-[100%]  h-[30px]  sm:h-[40px] lg:h-[40px] bg-[#000] rounded-[5px] px-3 text-white"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className=" pt-4 lg:pt-0">
                <p className="font-bold text-[10px] sm:text-[20px]  text-[#FFF] roboto-serif-unique-class-name">
                  Contact No.
                </p>
                <input
                  type="Text"
                  placeholder="Mobile no."
                  className="font-bold text-[13px]  sm:text-[18px]  w-[100%]  h-[30px] sm:h-[40px] lg:h-[40px] bg-[#000] rounded-[5px] px-3 text-white"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className=" pt-4 lg:pt-0">
                <p className="font-bold text-[10px] sm:text-[20px]  text-[#FFF] roboto-serif-unique-class-name ">
                  Date
                </p>
                <input
                  type="date"
                  placeholder="Date"
                  className="font-bold text-[13px]  sm:text-[18px]  w-[100%]  h-[30px] sm:h-[40px] lg:h-[40px] bg-[#000] rounded-[5px] px-3 text-white"
                  name="date"
                  value={formData.date}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="pt-4 lg:pt-0">
                <p className="font-bold text-[10px] sm:text-[20px] text-white roboto-serif-unique-class-name">
                  Time
                </p>
                <input
                  type="time"
                  className="font-bold text-[13px] sm:text-[18px] w-full h-[30px] sm:h-[40px] lg:h-[40px] bg-black rounded-[5px] px-3 text-white"
                  name="time"
                  value={formData.time}
                  onChange={handleChange}
                  required
                />
              </div>

              <button
                type="submit"
                className="bg-[#AA6141] sm:w-[30%]  text-white rounded-md py-2 px-3 mt-7 font-medium roboto"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </AppLayout>
    </div>
  );
};

export default Appoinment;
