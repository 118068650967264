import React from "react";
import AppLayout from "./AppLayout";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "./css/styles4.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation } from "swiper/modules";
import { Link, useParams } from "react-router-dom";
import createSlug from "../../Components/createSlug";
import TruncateString from "../../Components/TruncateString ";

const Bloges = ({ business }) => {
  const { city, slug } = useParams();
  const citySlug = createSlug(city);
  return (
    <div className="" id="blogs">
      <AppLayout>
        <div className=" ">
          <h2 className=" text-[48px] font-bold text-[#9F7161] text-center mb-5 odor-mean-chey-regular">
            Blogs
          </h2>
          <Swiper
            breakpoints={{
              340: {
                slidesPerView: 1,
                spaceBetween: 11,
              },
              650: {
                slidesPerView: 2,
                spaceBetween: 15,
              },
              850: {
                slidesPerView: 3,
                spaceBetween: 15,
              },
            }}
            loop={true}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Pagination, Navigation]}
            className="mySwiper"
          >
            <div className="flex px-2 mx-2 sm:mx-[20px]">
              {(business.blogs || []).map((blog, index) => (
                <SwiperSlide key={index}>
                  <div className="w-[90%] sm:w-[100%] lg:w-[100%] shadow-lg hover:shadow-xl transition-shadow duration-300 h-[450px] mx-auto bg-[#E3D1C3] p-1 flex flex-col items-center mb-4 border-2 border-[#B89C85] overflow-hidden">
                    <div className="w-full">
                      <Link to={`/${citySlug}/${slug}/blogpg4/${blog.id}`}>
                        <img
                          src={"https://admin.bizzata.in/storage/" + blog.image}
                          alt={blog.title}
                          className="h-[200px] w-full object-cover"
                        />
                      </Link>
                    </div>

                    <h2 className="px-2 my-2 text-[#FFF] cursor-pointer tracking-[0.96px] text-[14px] sm:text-[24px] text-start font-bold odor-mean-chey-regular">
                      <Link to={`/${citySlug}/${slug}/blogpg4/${blog.id}`}>
                        <TruncateString
                          inputString={blog.title}
                          maxLength={40}
                        />
                      </Link>
                    </h2>
                    <p className="mt-2 text-sm sm:text-base md:text-lg font-medium tracking-0.5  leading-4 text-[#9F7161] text-start odor-mean-chey-regular">
                      <Link to={`/${citySlug}/${slug}/blogpg4/${blog.id}`}>
                        <TruncateString
                          inputString={blog.content}
                          maxLength={150}
                        />
                        ...
                      </Link>
                    </p>
                  </div>
                </SwiperSlide>
              ))}
            </div>
          </Swiper>
        </div>
      </AppLayout>
    </div>
  );
};

export default Bloges;
