import AppLayout from "./AppLayout";
import "./CssTemp/styles.css";
import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Keyboard, Pagination } from "swiper/modules";
import { Link, useParams } from "react-router-dom";
import createSlug from "../../Components/createSlug";
import TruncateString from "../../Components/TruncateString ";

const Product = ({ business }) => {
  const { city, slug } = useParams();
  const citySlug = createSlug(city);
  return (
    <div className="mx-6" id="products">
      <AppLayout>
        <h2 className=" font-bold text-[32px] text-center poppins-bold">
          Products
        </h2>
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          keyboard={{
            enabled: true,
          }}
          breakpoints={{
            340: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            500: {
              slidesPerView: 2,
              spaceBetween: 0,
            },
            950: {
              slidesPerView: 3,
              spaceBetween: 15,
            },
            1000: {
              slidesPerView: 4,
              spaceBetween: 15,
            },
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Keyboard, Pagination]}
          className="mySwiper"
        >
          {(business.products || []).map((product, index) => (
            <SwiperSlide key={index}>
              <div className="w-[95%] sm:w-[250px] h-auto sm:h-[400px] flex-col mt-8 mb-10 group mx-auto border bg-[#F1F1F1]">
                <div className="relative">
                  <Link to={`/${citySlug}/${slug}/productpg10/${product.id}`}>
                    <img
                      src={"https://admin.bizzata.in/storage/" + product.image}
                      alt={product.product_name}
                      className="w-full h-[200px] sm:h-[250px] object-cover"
                    />
                  </Link>
                  {/* <div className="mt-[-40px] mx-10">
                    <button className="bg-[#000] py-2 rounded text-white px-8 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                     Shop now
                    </button>
                  </div> */}
                </div>
                <p className="pt-2 text-center text-[16px] sm:text-[18px] font-bold poppins-bold">
                  <Link to={`/${citySlug}/${slug}/productpg10/${product.id}`}>
                    <TruncateString
                      inputString={product.product_name}
                      maxLength={20}
                    />
                  </Link>
                </p>
                <h2 className="text-[14px]  pt-2 sm:pt-4 mx-2 poppins-regular">
                  <TruncateString
                    inputString={product.description}
                    maxLength={60}
                  />
                </h2>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </AppLayout>
    </div>
  );
};

export default Product;
