import AppLayout from "./AppLayout";
import "./CssFold/style5.css"
import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Keyboard, Pagination, Navigation } from "swiper/modules";
import { useParams, Link } from "react-router-dom";
import createSlug from "../../Components/createSlug";
import TruncateString from "../../Components/TruncateString ";

function Product({ business }) {
  const { city, slug } = useParams();
  const citySlug = createSlug(city);
  return (
    <div className="my-5 bg-[#031A25] py-8" id="products">
      <AppLayout>
        <div className="">
          <h3 className="text-center text-[40px] font-bold text-white roboto">
            Products
          </h3>

          <Swiper
            slidesPerView={1}
            spaceBetween={30}
            keyboard={{
              enabled: true,
            }}
            breakpoints={{
              340: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              500: {
                slidesPerView: 2,
                spaceBetween: 0,
              },
              700: {
                slidesPerView: 3,
                spaceBetween: 15,
              },
              1000: {
                slidesPerView: 4,
                spaceBetween: 15,
              },
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Keyboard, Pagination]}
            className="mySwiper"
          >
            {(business.products || []).map((product, index) => (
              <SwiperSlide key={index}>
                <div className=" w-[140px] sm:w-[250px] h-[180px] sm:h-[250px] gap-2 flex flex-col justify-center items-center mt-8 mb-10 mx-auto bg-[#1C2428] rounded-md">
                  <Link to={`/${citySlug}/${slug}/productpg5/${product.id}`}>
                    <img
                      src={"https://admin.bizzata.in/storage/" + product.image}
                      alt={product.product_name}
                      className=" h-[80px] sm:h-[120px] text-center"
                    />
                  </Link>
                  <h3 className="text-white text-center font-semibold pt-4 text-[15px] sm:text-[28px] roboto">
                    <Link to={`/${citySlug}/${slug}/productpg5/${product.id}`}>
                      <TruncateString
                        inputString={product.product_name}
                        maxLength={15}
                      />
                    </Link>
                  </h3>
                  <h3 className="text-white text-center font-normal text-[15px] roboto ">
                    <Link to={`/${citySlug}/${slug}/productpg5/${product.id}`}>
                      <TruncateString
                        inputString={product.description}
                        maxLength={10}
                      />
                    </Link>
                  </h3>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </AppLayout>
    </div>
  );
}

export default Product;
