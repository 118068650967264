import React, { useState } from "react";
import AppLayout from "./AppLayout";
import axios from "axios";
import "./style1.css";

const FeedbackForm = ({ business }) => {
  const [formData, setFormData] = useState({
    name: "",
    feedback: "",
    business_id: business.id,
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://admin.bizzata.in/api/business_feedback",
        formData
      );
      alert(response.data.message);
      setFormData({
        name: "",
        feedback: "",
        business_id: business.id,
      });
    } catch (error) {
      console.error("There was an error!", error);
      alert(
        "There was an error submitting your feedback. Please try again later."
      );
    }
  };

  return (
    <AppLayout>
      <div className="pt-[15px]">
        <h2 className="text-center text-[30px] font-bold text-[#FAFF03] philosopher-regular">
          Feedback Form
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="p-4 mx-auto sm:mx-20 w-[80%] bg-black rounded-xl">
            <p className="text-white">Name</p>
            <input
              type="text"
              placeholder="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              className="w-full rounded-md h-8 py-1 px-2 mt-1"
            />
            <p className="text-white mt-5">Feedback</p>
            <textarea
              name="feedback"
              value={formData.feedback}
              onChange={handleChange}
              required
              placeholder="Type your message..."
              className="w-full rounded-md h-24 px-2 mt-1 resize-none"
            />
            <button
              type="submit"
              className="bg-yellow-500 hover:bg-yellow-600 sm:w-[30%] text-black rounded-md py-2 px-3 mt-4 font-medium"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </AppLayout>
  );
};

export default FeedbackForm;
