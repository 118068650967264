import React, { useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
 import "./TempCss/Style.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination } from "swiper/modules";
import AppLayout from "./AppLayou";

const Certificates = ({ business }) => {
  const [isPopOpen, setIsPopupOpen] = useState(false);
  const [image, setImage] = useState("");

  const openPopup = (imageSrc) => {
    setIsPopupOpen(true);
    setImage(imageSrc);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setImage("");
  };

  return (
    <>
      <div className="" id="gallery">
        <AppLayout>
          <div id="gallery" className="w-[100%] mx-auto pb-10 ">
            <h1 className="text-center abhaya-libre-semibold font-bold text-[35px] items-center pt-[20px]  text-[#000]">
              Certificates
            </h1>

            <Swiper
              slidesPerView={1}
              spaceBetween={30}
              loop={true}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                340: {
                  slidesPerView: 1,
                  spaceBetween: 30,
                },
                700: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
              }}
              modules={[Pagination]}
              className="mySwiper"
            >
              {(business.certificates || []).map((certificate, index) => (
                <SwiperSlide key={index}>
                  <div className="flex items-center justify-center  gap-5">
                    <img
                      src={
                        "https://admin.bizzata.in/storage/" + certificate.image
                      }
                      className="w-[350px] transition-transform transform duration-100 hover:scale-110 h-[220px]  rounded-[12px] lg:h-[191px] lg:w-[318px] lg:rounded-[12px] mx-auto "
                      alt={`gallery ${index + 1}`}
                      onClick={() =>
                        openPopup(
                          " https://admin.bizzata.in/storage/" +
                            certificate.image
                        )
                      }
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          {isPopOpen && (
            <div className="fixed inset-0 bg-white bg-opacity-75 flex items-center justify-center z-50 pb-2">
              <div className="relative">
                <img
                  src={image}
                  alt="Popup"
                  className="sm:h-[400px] h-[100%]"
                />
                <button
                  className="absolute top-2 right-2 bg-white rounded-full p-2"
                  onClick={closePopup}
                >
                  &times;
                </button>
              </div>
            </div>
          )}
        </AppLayout>
      </div>
    </>
  );
};

export default Certificates;
