import React from "react";
import AppLayout from "./AppLayout";
 import "./FredCss/styles.css";
const BussinessHours = ({ business }) => {
  function convertTo12HourFormat(time) {
    let [hours, minutes] = time.split(":");
    hours = parseInt(hours, 10);
    let period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert '0' hours to '12'
    return `${hours}:${minutes} ${period}`;
  }
  return (
    <div className="bg-[#d6763b]">
      <div className="pt-10 pb-14">
        <AppLayout>
          <h1 className="my-4 bellota-text-bold text-[32px] font-bold text-white text-center">
            Business Hours
          </h1>
        </AppLayout>
        <div className="flex flex-col lg:flex-row items-center justify-between">
          <div className="hidden lg:block lg:flex-1">
            <hr className="border-t border-white" />
          </div>
          <div className="flex justify-center w-full lg:w-auto mx-[2%]">
            <div className="border-2 border-white rounded-2xl bg-[#d2ac8c] w-full max-w-[600px] px-4 sm:px-8 py-5">
              <table className="text-white w-full">
                <thead>
                  <tr className="py-2 border-b border-white ">
                    <th className="capitalize bellota text-lg sm:text-xl font-bold text-left">
                      Day&nbsp; 
                    </th>
                    <th className="capitalize bellota text-lg sm:text-xl font-bold text-left">
                      Opening &nbsp; 
                    </th>
                    <th className="capitalize text-lg bellota sm:text-xl font-bold text-left">
                      Close&nbsp; 
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {(business.business_hours || []).map((dayHour, index) => (
                    <tr key={index} className="py-2 text-[#000]">
                      <td className="text-lg sm:text-xl bellota font-medium capitalize">
                        {dayHour.day}&nbsp; 
                      </td>
                      <td className="text-lg sm:text-xl bellota  font-medium capitalize pt-1">
                        &nbsp; {convertTo12HourFormat(dayHour.open_time)}&nbsp; &nbsp; 
                      </td>
                      <td className="text-lg sm:text-xl font-medium bellota capitalize">
                        {convertTo12HourFormat(dayHour.close_time)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="hidden lg:block lg:flex-1">
            <hr className="border-t border-white" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BussinessHours;
