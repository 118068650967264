import React from "react";
import AppLayout from "./AppLayout";

const Hero = ({ business }) => {
  return (
    <AppLayout>
      <div className="flex justify-center items-center text-white my-4 md:my-10">
        <h1 className="hidden">{business.meta_title}</h1>
        <div className="p-6 rounded-lg flex flex-col md:flex-row items-center">
          <img
            src={`https://admin.bizzata.in/storage/${business.profile}`}
            alt="Restaurant Logo"
            className="w-32 h-32 md:w-48 md:h-48 rounded-full mb-4 md:mb-0 md:mr-6 object-cover"
          />
          <div className="flex-grow">
            <h2 className="text-xl font-bold text-center md:text-start">
              {business.business_name}
            </h2>
            <p className="text-gray-200 text-center md:text-start text-[20px]">
              {business.name}
            </p>
            <blockquote className="mt-4 text-lg italic">
              “ {business.description} “
            </blockquote>
            <div className="rounded-full flex flex-wrap px-4 py-4 gap-4 justify-center mt-2">
              {(business.business_social_media_links || []).map(
                (linkData, index) => (
                  <a
                    key={index}
                    href={linkData.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={`https://admin.bizzata.in/storage/${linkData.social_media_platform.logo}`}
                      alt={`${linkData.social_media_platform.platform_name} logo`}
                      className="h-8 w-8 rounded-full object-cover"
                    />
                  </a>
                )
              )}
            </div>
            <button className="mt-6 py-2 px-4 border-2 border-[#FFF] rounded-lg hover:bg-yellow-600">
              <a href="#Enquiry">Explore Now</a>
            </button>
          </div>
        </div>
      </div>
    </AppLayout>
  );
};
export default Hero;
