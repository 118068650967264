import React, { useState } from "react";
import AppLayout from "./AppLayout";
import axios from "axios";
import "./CssFold/style5.css";
const Feedbackform = ({ business }) => {
  const [formData, setFormData] = useState({
    name: "",
    feedback: "",
    business_id: business.id,
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://admin.bizzata.in/api/business_feedback",
        formData
      );
      alert(response.data.message);
      setFormData({
        name: "",
        feedback: "",

        business_id: business.id,
      });
    } catch (error) {
      console.error("There was an error!", error);
    }
  };
  return (
    <div className="  py-[15px] my-8  ">
      <AppLayout>
        <h6 className="text-center text-[40px] font-bold text-white roboto">
          Feedback form
        </h6>
        <form onSubmit={handleSubmit}>
          <div className="p-4 mx-auto sm:mx-20 w-[80%] bg-[#031A25] rounded-xl ">
            <p className="text-[#FFF] roboto-serif-unique">Name</p>
            <input
              type="text"
              placeholder="Name"
              className=" w-[100%] rounded-md h-8 py-1 px-2 mt-1 bg-black text-white"
              value={formData.name}
              name="name"
              onChange={handleChange}
              required
            />
            <p className="text-[#FFF] mt-5 roboto-serif-unique">Feedback</p>
            <input
              type="text"
              placeholder="Type your message..."
              className=" w-[100%]  rounded-md h-14 lg:h-24 px-2 mt-1 bg-black text-white "
              value={formData.feedback}
              name="feedback"
              onChange={handleChange}
              required
            />
            <br />
            <button
              type="submit"
              className="bg-[#AA6141] sm:w-[30%]  text-white rounded-md py-2 px-3 mt-4 font-medium roboto"
            >
              Submit
            </button>
          </div>
        </form>
      </AppLayout>
    </div>
  );
};

export default Feedbackform;
