import React from "react";
import AppLayout from "./AppLayout";
import "./CssTemp/styles.css";
const Hero = ({ business }) => {
  return (
    <AppLayout>
      <h1 className="hidden">{business.meta_title}</h1>
      <div className="my-[5%] bg-[#D9D9D9] rounded-md drop-shadow-xl h-full">
        <div className="grid sm:grid-cols-3 gap-2" id="about">
          <div className="grid-cols-1">
            <div className="p-1">
              <div className="flex justify-center items-center sm:mt-[8%]">
                <img
                  src={`https://admin.bizzata.in/storage/${business.profile}`}
                  className="rounded-full h-[110px] w-[110px] sm:h-52 sm:w-52 object-cover border-2 border-[#38CB89]"
                  alt="Description of image"
                />
              </div>
            </div>
          </div>
          <div className="grid-cols-2">
            <div className="sm:col-span-2 p-4 sm:mt-[30px] lg:ml-[-100px] ">
              <h2 className="font-bold text-[25px] text-center sm:text-start pb-2 poppins-regular">
                {business.business_name}
              </h2>
              <p className="font-bold text-[20px] text-center sm:text-start pb-2 poppins-regular">
                {business.name}
              </p>
              <p className="text-[17px] font-serif poppins-regular">
                “ {business.description} “
              </p>
            </div>
          </div>
        </div>
        <div className="rounded-full flex flex-wrap px-4 pb-6 gap-4 justify-center">
          {(business.business_social_media_links || []).map(
            (linkData, index) => (
              <a
                key={index}
                href={linkData.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={`https://admin.bizzata.in/storage/${linkData.social_media_platform.logo}`}
                  alt={`${linkData.social_media_platform.platform_name} logo`}
                  className="h-[34px] w-[34px]"
                />
              </a>
            )
          )}
        </div>
      </div>
    </AppLayout>
  );
};

export default Hero;
