import React from "react";
import AppLayout from "./AppLayou";
import loc from "./assites/1.png";
import mb from "./assites/4.png";
import Em from "./assites/5.png";
import Ctg from "./assites/6.png";
import "./TempCss/Style.css";
const ContactUs = ({ business }) => {
  return (
    <div
      className="w-auto min-h-[368px] lg:min-h-[500px] bg-[#14151B] mt-1"
      id="Contact"
    >
      <AppLayout>
        <div className="abhaya-libre-semibold font-[700] text-[20px] lg:text-[48px] text-[rgb(255,255,255)] ml-8 lg:ml-24 pt-[20px] lg:pt-[50px]">
          Contact <span className="font-[900]">Us</span>
        </div>
        <hr className="w-[80px] ml-[40px] lg:ml-[144px] h-[1px] lg:h-[3px] bg-[#FFFFFF] lg:w-[164px]" />
        <div className="w-[85%] mx-auto mt-[30px] lg:mt-[70px] flex flex-col lg:flex-row flex-wrap gap-y-[12px] lg:gap-x-[40px]">
          <div className="w-320px rounded-[24px] lg:rounded-[70px] bg-[#3B3C41] min-h-[52px] lg:min-h-[111px] lg:w-[46%]">
            <div className="min-h-[52px] lg:min-h-[111px]  flex lg:items-center">
              <img
                src={loc}
                className="w-[30px] lg:w-[75px] h-[30px] lg:h-[75px] rounded-[50%] ml-[15px] my-auto"
              />
              <div className="ml-[11px] lg:ml-[25px] mt-[2px] ">
                <p className="text-[#FFFFFF] font-[800] text-[14px] lg:text-[24px] p-0 nunito-sans-uniquifier">
                  Location
                </p>
                <p className="font-[400] text-[16px]  text-[#929090] p-1 nunito-sans-uniquifier">
                  <a href="">{business.address}</a>
                </p>
              </div>
            </div>
          </div>

          <div className="w-320px rounded-[24px] lg:rounded-[70px] bg-[#3B3C41] min-h-[52px] lg:min-h-[111px] lg:w-[46%]">
            <div className="min-h-[52px] lg:min-h-[111px]  flex lg:items-center">
              <img
                src={mb}
                className="w-[30px] lg:w-[75px] h-[30px] lg:h-[75px] rounded-[50%] ml-[15px] my-auto"
              />
              <div className="ml-[11px] lg:ml-[25px] mt-[2px] ">
                <p className="text-[#FFFFFF] font-[800] text-[14px] lg:text-[24px] p-0 nunito-sans-uniquifier">
                  Mobile No.
                </p>
                <p className="font-[400] text-[16px]  text-[#929090] p-1 nunito-sans-uniquifier">
                  {business.mobile}
                </p>
              </div>
            </div>
          </div>

          <div className="w-320px rounded-[24px] lg:rounded-[70px] bg-[#3B3C41] min-h-[52px] lg:min-h-[111px] lg:w-[46%]">
            <div className="min-h-[52px] lg:min-h-[111px]  flex lg:items-center">
              <img
                src={Em}
                className="w-[30px] lg:w-[75px] h-[30px] lg:h-[75px] rounded-[50%] ml-[15px] my-auto"
              />
              <div className="ml-[11px] lg:ml-[25px] mt-[2px] ">
                <p className="text-[#FFFFFF] font-[800] text-[14px] lg:text-[24px] p-0 nunito-sans-uniquifier">
                  Email
                </p>
                <p className="font-[400] text-[16px]  text-[#929090] p-1 nunito-sans-uniquifier">
                  {" "}
                  <a href="/">{business.email}</a>
                </p>
              </div>
            </div>
          </div>

          <div className="w-320px rounded-[24px] lg:rounded-[70px] bg-[#3B3C41] min-h-[52px] lg:min-h-[111px] lg:w-[46%]">
            <div className="min-h-[52px] lg:min-h-[111px]  flex lg:items-center">
              <img
                src={Ctg}
                className="w-[30px] lg:w-[75px] h-[30px] lg:h-[75px] rounded-[50%] ml-[15px] my-auto"
              />
              <div className="ml-[11px] lg:ml-[25px] mt-[2px] ">
                <p className="text-[#FFFFFF] font-[800] text-[14px] lg:text-[24px] p-0 nunito-sans-uniquifier">
                  Category
                </p>
                <p className="font-[400] text-[16px]  text-[#929090] p-1 nunito-sans-uniquifier">
                  <a href="/">
                    <a href="">
                      {" "}
                      {(business.categories || []).map(
                        (category, index) => category.category_name + ", "
                      )}
                    </a>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </AppLayout>
    </div>
  );
};

export default ContactUs;
