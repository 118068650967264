import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Autoplay, FreeMode, Navigation, Pagination } from "swiper/modules";
import AppLayout from "./AppLayout";
import { Link, useParams } from "react-router-dom";
import createSlug from "../../Components/createSlug";
import TruncateString from "../../Components/TruncateString ";
import "./CssFold/style5.css"
const Bloge = ({ business }) => {
  const { city, slug } = useParams();
  const citySlug = createSlug(city);
  return (
    <div className="mt-10 mx-auto mb-8" id="blogs">
      <AppLayout>
        <h5 className="text-center text-[40px] font-bold text-white roboto mb-6">
          Our Blogs
        </h5>

        <Swiper
          breakpoints={{
            340: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            700: {
              slidesPerView: 3,
              spaceBetween: 15,
            },
          }}
          autoplay={{
            delay: 4500,
            disableOnInteraction: false,
          }}
          freeMode={true}
          pagination={{
            clickable: true,
          }}
          modules={[FreeMode, Autoplay, Pagination, Navigation]}
          className="max-w-[90%] lg:max-w-[80%]"
        >
          <div className="flex flex-wrap justify-center gap-4 mx-auto">
            {(business.blogs || []).map((blog, index) => (
              <SwiperSlide key={index} className="flex justify-center">
                <div className="w-[90%] sm:w-[100%] md:w-[100%] lg:w-[100%] xl:w-[100%] h-[450px] mx-auto rounded-lg border border-gray-700 bg-[#552e1d] shadow-lg transition-transform transform hover:scale-105 overflow-hidden">
                  <Link to={`/${citySlug}/${slug}/blogpg5/${blog.id}`}>
                    <img
                      src={"https://admin.bizzata.in/storage/" + blog.image}
                      alt={blog.title}
                      className="w-full h-[200px] md:h-[250px] lg:h-[210px] rounded-t-lg object-cover"
                    />
                  </Link>
                  <div className="p-2">
                    <h5 className="mt-4 text-[22px] font-bold text-start text-white hover:underline roboto-serif-unique-class-name leading-7">
                      <Link to={`/${citySlug}/${slug}/blogpg5/${blog.id}`}>
                        <TruncateString
                          inputString={blog.title}
                          maxLength={70}
                        />
                      
                      </Link>
                    </h5>
                    <h5 className="pt-2   text-[16px] text-bold text-gray-300 tracking-wide roboto leading-6">
                      <Link to={`/${citySlug}/${slug}/blogpg5/${blog.id}`}>
                        <TruncateString
                          inputString={blog.content}
                          maxLength={165}
                        />
                      
                      </Link>
                    </h5>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </div>
        </Swiper>
      </AppLayout>
    </div>
  );
};

export default Bloge;
