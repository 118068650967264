import AppLayout from "./AppLayout";
import "./cssFold/style7.css";
import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Keyboard, Pagination } from "swiper/modules";
import { useParams, Link } from "react-router-dom";
import createSlug from "../../Components/createSlug";
import TruncateString from "../../Components/TruncateString ";

const Product = ({ business }) => {
  const { city, slug } = useParams();
  const citySlug = createSlug(city);
  return (
    <div className="my-2 mx-8" id="products">
      <AppLayout>
        <h2 className=" font-bold text-[30px] text-center text-white  enriqueta-medium">
          Products
        </h2>
        <div className=" ">
          <Swiper
            slidesPerView={1}
            spaceBetween={30}
            keyboard={{
              enabled: true,
            }}
            breakpoints={{
              340: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
              500: {
                slidesPerView: 2,
                spaceBetween: 0,
              },
              700: {
                slidesPerView: 3,
                spaceBetween: 15,
              },
              1000: {
                slidesPerView: 4,
                spaceBetween: 15,
              },
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Keyboard, Pagination]}
            className="mySwiper"
          >
            {(business.products || []).map((product, index) => (
              <SwiperSlide key={index}>
                <div className="px-2">
                  <div className="w-[100%] sm:w-[100%] md:w-[100%] lg:w-[100%] xl:w-[100%] h-[350px] mx-auto mt-8 sm:mb-10 group bg-[#35322C] border-2 border-[#FBBB17] rounded-md">
                    <div className="p-4 relative">
                      <div className="">
                        <Link
                          to={`/${citySlug}/${slug}/productpg7/${product.id}`}
                        >
                          <img
                            src={`https://admin.bizzata.in/storage/${product.image}`}
                            alt={product.product_name}
                            className="h-[200px] w-full object-cover"
                          />
                        </Link>
                      </div>
                      <p className="pt-2 text-center text-[20px] font-medium text-white enriqueta-medium">
                        <Link
                          to={`/${citySlug}/${slug}/productpg7/${product.id}`}
                        >
                          {}
                          <TruncateString
                            inputString={product.product_name}
                            maxLength={25}
                          />
                        </Link>
                      </p>
                      <div className="absolute inset-x-0 bottom-0  top-[310px]  flex justify-center items-center">
                        <button className="bg-yellow-500 hover:bg-black text-black hover:text-white font-extrabold py-2 px-4 rounded enriqueta-medium">
                          <Link
                            to={`/${citySlug}/${slug}/productpg7/${product.id}`}
                          >
                            Shop Now
                          </Link>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </AppLayout>
    </div>
  );
};

export default Product;
