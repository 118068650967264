import React from "react";
import "./FredCss/styles.css"
const CompanyInfoBox = ({business}) => {
  return (

    <div className="bg-[#7D3C13] pb-6 " id="about">
       <h1 className="hidden">{business.meta_title}</h1>
      <div className="w-[90vw] pt-[12px] sm:pt-[25px] lg:pt-[43px] mx-auto h-[100%] flex flex-col  items-center ">
        <img
          src={`https://admin.bizzata.in/storage/${business.profile}`}
          className="w-[105px] h-[100px] rounded-[100%] sm:w-[120px] sm:h-[120px] lg:w-[200px]  lg:h-[200px] bg-[#00000046] mt-[-13%] lg:mt-[-8%] border-2 "
        />
        <h2 className="text-[28px]  text-white  mt-[7px] sm:mt-[20px] lg:mt-[25px] text-center bellota-text-bold">
          {business.business_name}
        </h2>
        <p className="text-[22px] font-semibold text-white  mt-[8px] lg:mt-[10px] bellota-text-bold">
        {business.name}
        </p>
        <p className=" text-[20px] bellota font-medium text-center  text-white mt-[8px] sm:mt-[20px] lg:mt-[34px] sm:pb-8">
          “ {business.description} “
        </p>
        <div className=" rounded-full flex flex-wrap px-4 py-4 gap-4 justify-center bg-[#D4B294] mt-2">
          {/* <div className="flex gap-2"> */}
          {(business.business_social_media_links || []).map((linkData, index) => (
              <a
                key={index}
                href={linkData.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={`https://admin.bizzata.in/storage/${linkData.social_media_platform.logo}`}
                  alt={`${linkData.social_media_platform.platform_name} logo`}
                  className="h-[34px] w-[34px]"
                />
              </a>
            ))}
        </div>
      </div>
 </div>
  );
};

export default CompanyInfoBox;
