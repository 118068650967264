import React from "react";
import Home from "./Home";
import Hero from "./Hero";
import ContactUs from "./ContactUs";
import Navbar from "./Navbar";
import { Helmet } from "react-helmet";
import Product from "./Product";
import Bloge from "./Bloge";
import Footer from "./Footer";
import Gallery from "./Gallery";
import { BusinessProvider } from "../../context/BusinessContext";
import Feedbackform from "./Feedbackform";
import BussinessHours from "./BussinessHours";
import Review from "./Review";
import Appoinment from "./Appoinment";
import Certificates from "./Certificates";
import Offer from "./Offer";
const Temp1 = ({ business }) => {
  if (!business) {
    return <div>Loading...</div>;
  }
  return (
    <BusinessProvider business={business}>
      <Helmet>
        <title>{business.meta_title}</title>
         <meta name="robots" content="index, follow"></meta>
         <link rel="icon" href="./logo.ico" />
        <meta name="description" content={business.meta_keyword} />
        <meta name="keywords" content={business.meta_description} />
        
      </Helmet>
      <div className="bg-[#1E1E1E]">
        <Navbar business={business} />
        <Home business={business} />
        <Hero business={business} />
        <ContactUs business={business} />
        <Product business={business} />

        <Bloge business={business} />
        <Gallery business={business} />
        <Feedbackform business={business} />
        <Review business={business} />
        <Offer business={business} />
        <BussinessHours business={business} />

        <Certificates business={business} />
        <Appoinment business={business} />
        <Footer business={business} />
      </div>
    </BusinessProvider>
  );
};

export default Temp1;
