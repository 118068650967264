import React from "react";
import Home from "./Home";
import Hero from "./Hero";
import ContactUs from "./ContactUs";
import Product from "./Product";
import Service from "./Service";
import Gallery from "./Gallery"; // Corrected
import Offer from "./Offer";
import Blog from "./Bloge"; // Corrected
import Review from "./Review";
import Certificates from "./Certificates";
import Feedbackform from "./Feedbackform";
import BusinessHours from "./BussinessHours"; // Corrected
import EnqForm from "./EnqForm";
import Navbar from "../template3/Navbar";
import Footer from "../template3/Footer";

import { Helmet } from "react-helmet";

import { BusinessProvider } from "../../../src/context/BusinessContext";

const Temp3 = ({ business }) => {
  if (!business) {
    return <div>Loading...</div>;
  } // Added missing semicolon here

  return (
    <BusinessProvider business={business}>
      <Helmet>
        <title>{business.meta_title}</title>
        <link rel="icon" href="./logo.ico" />
           <meta name="robots" content="index, follow"></meta>
        <meta name="description" content={business.meta_description} />{" "}
        {/* Corrected */}
        <meta name="keywords" content={business.meta_keyword} />{" "}
        {/* Corrected */}
      
      </Helmet>
      <div className="bg-[#421921]">
        <Navbar business={business} />
        <Home business={business} />
        <Hero business={business} />
        <ContactUs business={business} />
        <Product business={business} />
        <Service business={business} />
        <Offer business={business} />
        <Blog business={business} />
        <Gallery business={business} />
        <BusinessHours business={business} />
        <Certificates business={business} />
        <Feedbackform business={business} />
        <Review business={business} />

        <EnqForm business={business} />
        <Footer business={business} />
      </div>
    </BusinessProvider>
  );
};

export default Temp3;
