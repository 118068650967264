import "./App.css";
import React, { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import HowItWorks from "./Components/HowItWorks";
import Category from "./Components/Category";
import BusinessDetails from "./Components/BusinessDetails";
import AllCategories from "./Components/AllCategories";

const Layout = lazy(() => import("./Components/Layout"));
const Aboutus = lazy(() => import("./Components/Pages/Aboutus"));
const Contactus = lazy(() => import("./Components/Pages/Contactus"));
const Affilatepg = lazy(() => import("./Components/Pages/Affilatepg"));
const Careers = lazy(() => import("./Components/Pages/Careers"));
const SearchPage = lazy(() => import("./Components/SearchPage"));
const Testimonials = lazy(() => import("./Components/Pages/Testimonials"));
const Faq = lazy(() => import("./Components/Pages/Faq"));
const Privacypolicy = lazy(() => import("./Components/Pages/Privacypolicy"));
const Returnsrefunds = lazy(() => import("./Components/Pages/Returnsrefunds"));
const Cookieguidelines = lazy(() =>
  import("./Components/Pages/Cookieguidelines")
);
const Termsconditions = lazy(() =>
  import("./Components/Pages/Termsconditions")
);
const Blogspg = lazy(() => import("./Components/Pages/Blogspg"));
const BlogPage1 = lazy(() => import("./Templates/templete1/Blogpage"));
const ProductPage1 = lazy(() => import("./Templates/templete1/ProductDetails"));
const ServicePage1 = lazy(() => import("./Templates/templete1/ServicePage"));
const BlogPage2 = lazy(() => import("./Templates/template2/BlogPage2"));
const ProductPage2 = lazy(() => import("./Templates/template2/ProductPage2"));
const ServicePage2 = lazy(() => import("./Templates/template2/ServicePage2"));
const BlogPage3 = lazy(() => import("./Templates/template3/Blogpage3"));
const ProductPage3 = lazy(() => import("./Templates/template3/ProductDetails3"));
const ServicePage3 = lazy(() => import("./Templates/template3/ServicePage3"));
const BlogPage4 = lazy(() => import("./Templates/templete4/Blogpg"));
const ProductPage4 = lazy(() => import("./Templates/templete4/Productpage"));
const ServicePage4 = lazy(() => import("./Templates/templete4/ServicePage"));
const BlogPage5 = lazy(() => import("./Templates/templete5/Blogpage"));
const ProductPage5 = lazy(() => import("./Templates/templete5/ProductDetails"));
const ServicePage5 = lazy(() => import("./Templates/templete5/ServicePage"));
const BlogPage6 = lazy(() => import("./Templates/templete6/Blogpg"));
const ProductPage6 = lazy(() => import("./Templates/templete6/Productpage"));
const ServicePage6 = lazy(() => import("./Templates/templete6/ServicePage"));
const BlogPage7 = lazy(() => import("./Templates/templete7/Blogpage"));
const ProductDetails7 = lazy(() =>
  import("./Templates/templete7/ProductDetails")
);
const ServicePage7 = lazy(() => import("./Templates/templete7/ServicePage"));
const BlogPage8 = lazy(() => import("./Templates/templete8/Blogepg"));
const Productsingle8 = lazy(() =>
  import("./Templates/templete8/Productsingle")
);
const ServicePage8 = lazy(() => import("./Templates/templete8/ServicePage"));
const BlogPage9 = lazy(() => import("./Templates/templete9/Blogespag"));
const Productsingle9 = lazy(() => import("./Templates/templete9/Probuctpage"));
const ServicePage9 = lazy(() => import("./Templates/templete9/ServicePage"));
const BlogPage10 = lazy(() => import("./Templates/templete10/Articlespage"));
const Productsingle10 = lazy(() =>
  import("./Templates/templete10/Probuctpage")
);
const ServicePage10 = lazy(() => import("./Templates/templete10/ServicePage"));

function App() {
  return (
    <div>
      <HelmetProvider>
        <BrowserRouter>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              {/* Temp1 */}
              <Route
                path="/:city/:slug/blogpg1/:blogId"
                element={<BlogPage1 />}
              />
              <Route
                path="/:city/:slug/productpg1/:productId"
                element={<ProductPage1 />}
              />
              <Route
                path="/:city/:slug/servicepg1/:serviceId"
                element={<ServicePage1 />}
              />

              {/* temp 2 */}
              <Route
                path="/:city/:slug/blogespage2/:blogId"
                element={<BlogPage2 />}
              />
              <Route
                path="/:city/:slug/productpage2/:productId"
                element={<ProductPage2 />}
              />
              <Route
                path="/:city/:slug/servicepage2/:serviceId"
                element={<ServicePage2 />}
              />
              {/* temp3 */}
              <Route
                path="/:city/:slug/blogpg3/:blogId"
                element={<BlogPage3 />}
              />
              <Route
                path="/:city/:slug/productpg3/:productId"
                element={<ProductPage3 />}
              />
              <Route
                path="/:city/:slug/servicepg3/:serviceId"
                element={<ServicePage3 />}
              />

              {/* temp 4 */}
              <Route
                path="/:city/:slug/blogpg4/:blogId"
                element={<BlogPage4 />}
              />
              <Route
                path="/:city/:slug/productpg4/:productId"
                element={<ProductPage4 />}
              />
              <Route
                path="/:city/:slug/servicepg4/:serviceId"
                element={<ServicePage4 />}
              />

              {/* temp 5 */}
              <Route
                path="/:city/:slug/blogpg5/:blogId"
                element={<BlogPage5 />}
              />
              <Route
                path="/:city/:slug/productpg5/:productId"
                element={<ProductPage5 />}
              />
              <Route
                path="/:city/:slug/servicepg5/:serviceId"
                element={<ServicePage5 />}
              />

              {/* temp 6 */}
              <Route
                path="/:city/:slug/blogpg6/:blogId"
                element={<BlogPage6 />}
              />
              <Route
                path="/:city/:slug/productpg6/:productId"
                element={<ProductPage6 />}
              />
              <Route
                path="/:city/:slug/servicepg6/:serviceId"
                element={<ServicePage6 />}
              />

              {/* temp7 */}
              <Route
                path="/:city/:slug/blogpg7/:blogId"
                element={<BlogPage7 />}
              />
              <Route
                path="/:city/:slug/productpg7/:productId"
                element={<ProductDetails7 />}
              />
              <Route
                path="/:city/:slug/ServicePage7/:productId"
                element={<ServicePage7 />}
              />

              {/* temp8 */}
              <Route
                path="/:city/:slug/blogpg8/:blogId"
                element={<BlogPage8 />}
              />
              <Route
                path="/:city/:slug/productpg8/:productId"
                element={<Productsingle8 />}
              />
              <Route
                path="/:city/:slug/servicepage8/:serviceId"
                element={<ServicePage8 />}
              />

              {/* Temp9 */}
              <Route
                path="/:city/:slug/blogpg9/:blogId"
                element={<BlogPage9 />}
              />
              <Route
                path="/:city/:slug/productpg9/:productId"
                element={<Productsingle9 />}
              />
              <Route
                path="/:city/:slug/servicepage9/:serviceId"
                element={<ServicePage9 />}
              />

              {/* Temp10 */}
              <Route
                path="/:city/:slug/blogpg10/:blogId"
                element={<BlogPage10 />}
              />
              <Route
                path="/:city/:slug/productpg10/:productId"
                element={<Productsingle10 />}
              />
              <Route
                path="/:city/:slug/servicepage10/:serviceId"
                element={<ServicePage10 />}
              />

              <Route path="/" exact element={<Layout />} />
              <Route path="/:city/:slug/" exact element={<BusinessDetails />} />
              <Route path="/contactus" exact element={<Contactus />} />
              <Route path="/aboutus" exact element={<Aboutus />} />
              <Route path="/affilatepg" exact element={<Affilatepg />} />
              <Route path="/careers" exact element={<Careers />} />
              <Route path="/testimonials" exact element={<Testimonials />} />
              <Route path="/faq" exact element={<Faq />} />
              <Route path="/privacypolicy" exact element={<Privacypolicy />} />
              <Route
                path="/returnsrefunds"
                exact
                element={<Returnsrefunds />}
              />
              <Route
                path="/cookieguidelines"
                exact
                element={<Cookieguidelines />}
              />
              <Route
                path="/termsconditions"
                exact
                element={<Termsconditions />}
              />

              <Route path="/howItWorks" exact element={<HowItWorks />} />

              <Route
                path="/:cityName/:categoryName/:categoryId/search"
                element={<SearchPage />}
              />
              <Route path="/category" element={<AllCategories />} />
              <Route
                path="/category/:category_name/:category_id"
                element={<Category />}
              />
            </Routes>
          </Suspense>
        </BrowserRouter>
      </HelmetProvider>
    </div>
  );
}

export default App;
