import React from "react";
import AppLayout from "./AppLayout";
import ProductMock from "./mOCK/ProductMock";
import what from "./assets/whatsapp.png";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation } from "swiper/modules";
import { Link, useParams } from "react-router-dom";
import TruncateString from "../../Components/TruncateString ";
import createSlug from "../../Components/createSlug";
import "./css/styles4.css"
const Product = ({ business }) => {
  const { city, slug } = useParams();
  const citySlug = createSlug(city);
  return (
    <div className="">
      <AppLayout>
        <div className="sm:my-10 my-5 " id="products">
          <h2 className=" text-[48px] font-bold text-[#9F7161] text-center mb-5 odor-mean-chey-regular">
            Products
          </h2>
          <Swiper
            breakpoints={{
              340: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              650: {
                slidesPerView: 2,
                spaceBetween: 15,
              },
              850: {
                slidesPerView: 3,
                spaceBetween: 15,
              },
            }}
            loop={true}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Pagination, Navigation]}
            className="mySwiper"
          >
            <div className="flex px-2 mx-2 sm:mx-[20px]">
              {(business.products || []).map((product, index) => (
                <SwiperSlide key={index}>
                  <div className="w-[90%] sm:w-[100%] lg:w-[100%] shadow-xs h-[500px] mx-auto bg-[#E3D1C3] rounded-[40px] flex flex-col items-center p-4 sm:p-6 mb-4 overflow-hidden">
                    <div>
                      <Link
                        to={`/${citySlug}/${slug}/productpg4/${product.id}`}
                      >
                        <img
                          src={
                            "https://admin.bizzata.in/storage/" + product.image
                          }
                          alt={product.product_name}
                          className="h-[200px] w-full rounded-tr-[40px] rounded-tl-[40px] object-cover"
                        />
                      </Link>
                      <h2 className="tracking-[0.96px] text-[14px] sm:text-[24px] text-start font-bold text-white my-4 odor-mean-chey-regular">
                        <Link
                          to={`/${citySlug}/${slug}/productpg4/${product.id}`}
                        >
                          <TruncateString
                            inputString={product.product_name}
                            maxLength={17}
                          />
                        </Link>
                      </h2>
                      <p className="pt-2 text-start text-[16px]  text-[#9F7161] nunito-sans-bold">
                        <Link
                          to={`/${citySlug}/${slug}/productpg4/${product.id}`}
                        >
                          <TruncateString
                            inputString={product.description}
                            maxLength={200}
                          />
                        </Link>
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </div>
          </Swiper>
        </div>
      </AppLayout>
    </div>
  );
};

export default Product;
