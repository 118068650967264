import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./CssFold/style5.css"
// import required modules
import { Pagination } from "swiper/modules";

import AppLayout from "./AppLayout";
const Galleary = ({ business }) => {
  const [isPopOpen, setIsPopupOpen] = useState(false);
  const [image, setImage] = useState("");

  const openPopup = (imageSrc) => {
    setIsPopupOpen(true);
    setImage(imageSrc);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setImage("");
  };

  return (
    <div>
      {" "}
      <div className="" id="gallery">
        <AppLayout>
          <h6 className="text-center text-[40px] font-bold text-white roboto  mb-6 ">
            Gallery
          </h6>

          <Swiper
            slidesPerView={1}
            spaceBetween={30}
            loop={true}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              340: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              700: {
                slidesPerView: 3,
                spaceBetween: 15,
              },
            }}
            modules={[Pagination]}
            className="mySwiper"
          >
            {(business.gallery || []).map((gallery, index) => (
              <SwiperSlide key={index}>
                <div className="pb-10">
                  <div className="flex items-center justify-center">
                    <img
                      src={"https://admin.bizzata.in/storage/" + gallery.image}
                      className="w-[95%] h-[100%] mx-auto rounded-[12px] lg:h-[100%] lg:w-[350px] lg:rounded-[12px]"
                      alt={`offer ${index + 1}`}
                      onClick={() =>
                        openPopup(
                          "https://admin.bizzata.in/storage/" + gallery.image
                        )
                      }
                    />
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </AppLayout>
        {isPopOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
            <div className="relative">
              <img src={image} alt="Popup" className="sm:h-[400px] h-[100%]" />
              <button
                className="absolute top-2 right-2 bg-white rounded-full p-2"
                onClick={closePopup}
              >
                &times;
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Galleary;
